import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Modal } from 'react-bootstrap';
import { useContext } from 'react';
import { BreadcrumbContext } from '../../nav/BreadcrumbContext';
import { useHistory } from 'react-router';
import ReactEndPoint from '../../../ENDPOINT/react-endpoints/endPoint'

import './Company.css';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteSingleCompanyAction, FetchCompanyAction } from '../../../redux/action/CompanyAction';
import LoadingSpinner from '../../loadSpinner/loadSpinner';
import ProductInfoUtil from '../../../util/ProductInfoUtil';

import CompanyUtil from '../../../util/CompanyUtil';
import { CompanyType } from '../../../redux/constants/CompanyType';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import CopyRight from '../../CopyRight/CopyRight';
import axios from 'axios';

const Company = () => {
    const history = useHistory();
    localStorage.setItem("companyName", "Companies");
    localStorage.setItem("companyId", "-1");
    localStorage.setItem("breadCrumbFlag", "true");

    const { setLeftNav } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setLeftNav(false);
        // reset leftNav to true when the component unmounts
        return () => setLeftNav(true);
    }, []);

    const dispatch = useDispatch();
    const { loading, success, data, filterData } = useSelector((state) => state.FetchCompanyReducer)

    let accessToken = localStorage.getItem("accessToken") || null;
    let companyId = localStorage.getItem("companyId") || null;
    const isDeleted = useSelector((state) => state.DeleteSingleCompanyReducer.success)
    const [show, setShow] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [projectConfirmation, setprojectConfirmation] = useState(null);
    const [pricingModalShow, setPricingModalShow] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [priceReportsLoading, setPriceReportsLoading] = useState(false);
    const [newPriceReportLoading, setNewPriceReportLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [priceReports, setPriceReports] = useState([]);

    useEffect(() => {
        if (isDeleted) {
            dispatch(FetchCompanyAction(accessToken, companyId))
            dispatch({ type: CompanyType.DELETE_SINGLE_COMPANY_RESET_SUCCESS })
        } else {
            dispatch(FetchCompanyAction(accessToken, companyId))
        }
    }, [isDeleted])
    let firstLoad = true;
    useEffect(() => {
        if (firstLoad = true) {
            //set state.UpdateSingleCompanyReducer success: false on load
            dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_RESET_SUCCESS })
            firstLoad = false;
        }
    }, [])
    const onhandleAddCard = () => {
        history.push(ReactEndPoint.DASHBOARD_ADD_COMPANY + "/" + localStorage.getItem("companyId"))
    }
    const onhandleSearch = (event) => {
        let value = event.target.value;
        let result = [];
        result = data?.filter((data) => {
            return data.name.toLowerCase().search(value.toLowerCase()) != -1;
        });
        dispatch({ type: CompanyType.FETCH_COMPANY_REQUEST_FILTER_DATA, payload: result })
    }
    const onhandleSwithToProjectRoute = (e, id, name) => {
        localStorage.setItem("companyName", name);
        localStorage.setItem("companyId", id);
        history.push(ReactEndPoint.DASHBOARD_HOME_URL + id);
    }
    const onhandleSwitchEditToRoute = (e, id) => {
        e.stopPropagation();
        localStorage.setItem("companyId", id);
        history.push(ReactEndPoint.DASHBOARD_EDIT_COMPANY + id)
    }
    const onhandleDeleteCompany = (e, rowId) => {
        e.stopPropagation();
        dispatch(DeleteSingleCompanyAction(accessToken, localStorage.getItem("companyId"), rowId))
        setShow(false);
    }
    const onhandleClose = (e) => {
        e.stopPropagation();
        setShow(false);
    }
    const onhandleShow = (e, rowId, name) => {
        e.stopPropagation();
        setShow(true)
        setRowId(rowId);
        setprojectConfirmation(name);

    };
    const groupReportsByMonth = (reports) => {
        return reports.reduce((acc, report) => {
            const monthKey = new Date(report.pricing_for_month).toLocaleDateString('en-US', { timeZone: 'UTC', month: 'long', year: 'numeric' });
            if (!acc[monthKey]) {
                acc[monthKey] = [];
            }
            acc[monthKey].push(report);
            return acc;
        }, {});
    };      
    const MonthReports = ({ month, reports, onFetchProductReports }) => {
        const [visible, setVisible] = useState(false);
        
        const date = new Date(Date.parse(month));
        const formattedMonth = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
        return (
            <div className="pricing-report">
                <h3 onClick={() => setVisible(!visible)} className="pricing-report__toggle">{formattedMonth}</h3>
                {visible && reports?.map(report => (
                    <PricingReport 
                        key={report.id} 
                        report={report}
                        onFetchProductReports={onFetchProductReports} 
                    />
                ))}
            </div>
        );
    };    
    
    const PricingReport = ({ report, onFetchProductReports }) => {
        const [productReports, setProductReports] = useState([]);
    
        useEffect(() => {
            const fetchProductReports = async () => {
                const fetchedProductReports = await onFetchProductReports(report.id);
                setProductReports(fetchedProductReports);
            };
            
            fetchProductReports();
        }, [report.id, onFetchProductReports]);
    
        return (
            <div>
                <div className={`pricing-report company-pricing-report ${report.is_partial ? 'partial-report' : 'full-report'}`}>
                    <aside>
                        <b>Ran:</b> {new Date(report.created_at).toLocaleDateString()} {new Date(report.created_at).toLocaleTimeString()} - {report.is_partial ? 'Partial' : 'Full'} Month <br />
                    </aside>
                    {Array.isArray(productReports) && productReports.map(productReport => (
                        <ProductReport key={productReport.id} productReport={productReport} />
                    ))}
                </div>
            </div>
        );
    }    
    const ProductReport = ({ productReport }) => {
        const [detailsVisible, setDetailsVisible] = useState(false);
    
        return (
            <div className="pricing-report product-pricing-report">
                <h4 onClick={() => setDetailsVisible(!detailsVisible)} className="product-report__toggle"> {detailsVisible ? 
                    <FontAwesomeIcon icon={faMinus} /> : 
                    <FontAwesomeIcon icon={faPlus} />
                } {productReport.product_name}{productReport.cap_broken ? '*' : ''}</h4>
                {detailsVisible && 
                    <div>
                        {/* Table 1: Devices */}
                        <div className="product-report__section">
                            <div className="table-scroll">
                                <table className="table warranty-list-table">
                                    <thead className="warranty-list-table--heading">
                                        <tr>
                                            <th className="name-column">Devices</th>
                                            <th className="aligned-column">New</th>
                                            <th className="aligned-column">Cap</th>
                                            <th className="aligned-column">Total (All)</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="warranty-list-table--body">
                                        <tr className="row-hover">
                                            <td className="name-column"></td>
                                            <td className="aligned-column">{productReport.new_unit_count.toLocaleString()}</td>
                                            <td className="aligned-column">{productReport.unit_cap.toLocaleString()}</td>
                                            <td className="aligned-column">{productReport.unit_count.toLocaleString()}{productReport.unit_count > productReport.unit_cap ? '*' : ''}</td>                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {productReport.first_device &&
                                <aside className='footnote'>
                                    First device recorded on&nbsp;
                                    {ProductInfoUtil.convertDate(productReport.first_device)}
                                </aside>
                            }
                        </div>
    
                        <div className="product-report__section">
                            {/* Table 2: Interactions */}
                            <div className="table-scroll">
                                <table className="table warranty-list-table">
                                    <thead className="warranty-list-table--heading">
                                        <tr>
                                            <th className="name-column">Interactions</th>
                                            <th className="aligned-column">New</th>
                                            <th className="aligned-column">Total ({productReport.data_window_in_months}mo)</th>
                                            <th className="aligned-column">Total (All)</th>
                                            <th className="unimportant-header">Total (12mo)</th>
                                            <th className="unimportant-header">Total (24mo)</th>
                                            <th className="unimportant-header">Total (36mo)</th>
                                            <th className="unimportant-header"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="warranty-list-table--body">
                                        <tr className="row-hover">
                                            <td className="name-column"></td>
                                            <td className="aligned-column">{productReport.new_interactions_this_month.toLocaleString()}</td>
                                            <td className="aligned-column">{productReport.data_window_interactions.toLocaleString()}</td>
                                            <td className="aligned-column">{productReport.interactions_forever.toLocaleString()}</td>
                                            <td className="unimportant-data">{productReport.interactions_12.toLocaleString()}</td>
                                            <td className="unimportant-data">{productReport.interactions_24.toLocaleString()}</td>
                                            <td className="unimportant-data">{productReport.interactions_36.toLocaleString()}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {productReport.first_interaction &&
                                <aside className='footnote'>
                                    First interaction recorded in&nbsp;
                                    {ProductInfoUtil.convertDatetoMMYYYY(productReport.first_interaction)}
                                </aside>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }    
    const handleMonthChange = (e) => {
        const [year, month] = e.target.value.split('-').map(num => parseInt(num, 10));
        
        // Create the Date object using the parsed year and month (adjusted for 0-indexing)
        const startOfMonth = new Date(year, month - 1, 1);
        const endOfMonth = new Date(year, month, 0); // Using day 0 of the next month gives the last day of the current month
    
        setSelectedMonth(e.target.value);
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
    };
    const showPricingModal = (show, val) => {
        getPricingReports(val);
        setPricingModalShow(show);
    };
    const getPricingReports = async (companyObj, reportId = "") => {
        if (reportId == "") { 
            setPriceReportsLoading(true);
        }
        const url = `/api/v1/get-pricing-reports/${companyObj.id}`;
        let data = {
            reportId: reportId
        }
        try {
            let apiResponse = await axios.post(url, data, { headers: { Authorization: `Bearer ${accessToken}` } });
            if (reportId == "") {
                setPriceReports(apiResponse?.data?.data);
                setPriceReportsLoading(false);
            }
            return apiResponse?.data?.data;
        } catch (error) {
            console.log(error);
            setPriceReportsLoading(false);
            return {};
        }
    };
    const generatePricingReport = async (companyObj) => {
        if (!startDate || !endDate) {
            return;
        } else if (startDate > new Date()) {
            return;
        }
        setNewPriceReportLoading(true);
        const url = `/api/v1/generate-pricing-report/${companyObj.id}`;
        let data = {
            startDate: startDate ? new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0)).toISOString() : "",
            endDate: endDate ? new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0)).toISOString() : ""
        };
        try {
            let apiResponse = await axios.post(url, data, { 
                headers: { Authorization: `Bearer ${accessToken}` },
                timeout: 180000 // 3 minutes
            });
            setNewPriceReportLoading(false);
            getPricingReports(companyObj);
            return apiResponse?.data;
        } catch (error) {
            console.log(error);
            setNewPriceReportLoading(false);
            return {};
        }
    };
    const PricingModal = ({ show, onHide, companyObj }) => {   
        // Fetch product reports and return them
        const handleFetchProductReports = async (reportId) => {
          return getPricingReports(companyObj, reportId);
        };

        return (
            <Modal show={show} onHide={onHide} className="wide-modal">
                <Modal.Header closeButton>
                    <Modal.Title>BILLING FOR: {companyObj.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input 
                        type="month" 
                        value={selectedMonth || ''}
                        onChange={handleMonthChange}
                        className="month-input"
                    />
                    <Button variant="primary" onClick={() => generatePricingReport(companyObj)}>
                        {newPriceReportLoading ? <LoadingSpinner></LoadingSpinner> : <>Generate report</>}
                    </Button>
                    {/* <p>Report history:</p> */}
                    {priceReportsLoading ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (
                        Object.entries(groupReportsByMonth(priceReports))?.map(([month, reports]) => (
                        <MonthReports 
                            key={month} 
                            month={month} 
                            reports={reports}
                            onFetchProductReports={handleFetchProductReports} 
                        />
                        ))
                    )}
                    <aside className='footnote'>
                        <i>* = Cap broken</i>
                    </aside>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    const onSwitchToWarrantyRoute = () => {
        history.push(ReactEndPoint.DASHBOARD_WARRANTY_LIST)
    }
    return (
        <div className="mainContent">

            {loading ?
                <LoadingSpinner />

                :
                <div className="company-container">
                    <Modal show={show} onHide={onhandleClose}>
                        <Modal.Body style={{ fontSize: "1rem" }}>Are you sure you want to delete {projectConfirmation}? This action cannot be undone</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onhandleClose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={(e) => onhandleDeleteCompany(e, rowId)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <PricingModal 
                        show={pricingModalShow} 
                        onHide={() => setPricingModalShow(false)} 
                        companyObj={selectedCompany} 
                    />
                    {/* <button 
                        className='
                        btn btn-info company-warranty-list--button float-end'
                        onClick={onSwitchToWarrantyRoute}
                        >
                        View warranty list</button> */}
                    <div className="company-container--section">
                        <i className="company-container--section-bi-search bi bi-search" ></i>
                        <input
                            className="company-container--input-search"
                            type="search"
                            onChange={(event) => onhandleSearch(event)}
                            placeholder="Search for Companies" />
                    </div>
                    <div className="company-container--card">
                        {
                            filterData?.length > 0 &&
                            filterData.map((val, index) => {
                                /**
                                 * @Image_url exist, there is either cropped image, or orignal image
                                 * else display Initial of company name
                                 */
                                let initial = "";
                                if (!val?.image_url) {
                                    // val.image_url = 'https://sp3-data-vis.s3.amazonaws.com/blue_city_transparent_bf_colors.png';
                                    val.image_url = '';
                                }
                                // if(!val?.image_url){
                                //     initial = CompanyUtil.getIntial(val.name);
                                // }
                                return (
                                    <Card
                                        style={{ width: '14rem', height: '20rem' }}
                                        className="company-container--card-container"
                                        key={index}
                                        onClick={(e) => onhandleSwithToProjectRoute(e, val.id, val.name)}
                                    >

                                        <div className="d-flex justify-content-center company-container--card--title">
                                            {val?.image_url ?
                                                <Card.Img className="company-container--card-container-img text-center"
                                                    variant="top"
                                                    src={val?.image_url}
                                                />
                                                :
                                                <h1>
                                                    {/* <Button variant="primary"> {initial}</Button> */}
                                                </h1>
                                            }
                                        </div>
                                        <Card.Body className="company-container--card--body">
                                            <Card.Title className="card-title text-center">{val.name}</Card.Title>
                                            <Card.Text className="text-center company-container--card--text-muted">
                                                Last updated {ProductInfoUtil.getCurrentDate(val.created_at)}
                                            </Card.Text>
                                            <Card.Text className="text-center company-container--card--text-muted">
                                                {val.total_project} Products
                                            </Card.Text>
                                        </Card.Body>
                                        <FontAwesomeIcon className="trash-icon" icon={faTrash} size="lg" onClick={(e) => onhandleShow(e, val.id, val.name)} />

                                        <div className="company-card-buttons">
                                            <Button
                                                onClick={(e) => onhandleSwitchEditToRoute(e, val.id)}
                                                className="company-container--card--edit-button"
                                                variant="primary">Edit</Button>
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedCompany(val);
                                                    showPricingModal(true, val);
                                                }}
                                                className="company-container--card--edit-button"
                                                variant="primary"
                                            >
                                                Billing
                                            </Button>
                                        </div>
                                    </Card>
                                )
                            })}
                        <Card style={{height: '20rem' }} className="company-container--add-card" onClick={onhandleAddCard}>
                            <Card.Body>
                                <div
                                    className="company-container--add-card-container">
                                    <div className="company-container--add-card-plus"></div>
                                </div>
                                <Card.Title className="card-title text-center company-container--title">
                                    ADD NEW
                                    <br />
                                    COMPANY </Card.Title>
                            </Card.Body>
                        </Card>
                    </div>
                <div className='fixed-bottom text-center mb-2'><CopyRight /></div>
                </div>
            }


        </div>
    );
};

export default Company;
