import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ReactCrop from 'react-image-crop'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css'
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';

import './EditCompany.css';
import ConsumerContentHeading from '../../Consumer/ConsumerContentHeading/ConsumerContentHeading';
import EditCompanyInputLabel from './EditCompanyInputLabel';
import UploadFile from '../../UploadFile/UploadFile';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import CompanyStateDate from '../StateData.json'
import HelperUtil from '../../../../util/HelperUtil'
import CompanyUtil from '../../../../util/CompanyUtil';
import CopyRight from '../../../CopyRight/CopyRight';

import {
    FetchSingleCompanyAction,
    UpdateSingleCompanyAction

} from '../../../../redux/action/CompanyAction';
import { CompanyType } from '../../../../redux/constants/CompanyType';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Constant = {
    UPDATE: "UPDATE",
    DELETE: "DELETE"
}


const EditCompany = () => {
    let accessToken = localStorage.getItem("accessToken") || null;
    const history = useHistory();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.FetchSingleCompanyReducer);
    const { loading, success } = useSelector((state) => state.UpdateSingleCompanyReducer);
    const param = useParams();

    const { setLeftNav } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setLeftNav(false);
        // reset leftNav to true when the component unmounts
        return () => setLeftNav(true);
    }, []);

    //save the image that used to be crop
    const [srcImg, setSrcImg] = useState(null);
    const [image, setImage] = useState(null);//change the aspect ratio of crop tool as you preferred
    const [crop, setCrop] = useState({ aspect: 16 / 9 });//save the resulted image
    const [result, setResult] = useState(null);


    const [types, setType] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [stateList, setStateList] = useState(CompanyStateDate);

    const [validateCompanyInput, setValidateCompanyInput] = useState(false);
    const [validateFirstNameInput, setValidateFirstNameInput] = useState(false);
    const [validateLastNameInput, setValidateLastNameInput] = useState(false);
    const [validateEmailInput, setValidateEmailInput] = useState(false);

    const [search, setSearch] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);  
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [removedUsers, setRemovedUsers] = useState([]);

    const stateRef = useRef();
    const mobileRef = useRef();


    useEffect(() => {
        if (success) {
            setResult(null); // set result to null, to avoid sending data the same crop file
            setCrop({ aspect: 16 / 9 })//reset it to default ratio
            history.goBack();
        } else {
            dispatch(FetchSingleCompanyAction(accessToken, localStorage.getItem("companyId"), param?.companyId))
        }
    }, [success])

    useEffect(() => {
        async function fetchUsers(searchString = '') {
            const url = "/api/v1/limited-user-list";
            const searchPayload = {
                "search": searchString
            };
            try {
                const apiResponse = await axios.post(url, searchPayload, { headers: { "authorization": `Bearer ${accessToken}` } });
                const users = apiResponse?.data?.data || [];
                
                // Filter users where company_id matches param?.companyId and add to selectedUsers
                const matchingUsers = users.filter(user => user.company_id === param?.companyId);
                setSelectedUsers(matchingUsers);
    
                // Update searched users
                setSearchedUsers(users);
            } catch (error) {
                console.log(error);
            }
        }
    
        fetchUsers();
    }, [param?.companyId, accessToken]);

    const onhandleChangeCompany = (e) => {
        data.name = e.target.value
        if (!data.name) {
            setValidateCompanyInput(true)
        } else {
            setValidateCompanyInput(false)
            dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_NAME, payload: data.name })
        }
    };
    const onhandleChangeFirstName = (e) => {
        data.first_name = e.target.value
        if (!data.first_name) {
            setValidateFirstNameInput(true)
        } else {
            setValidateFirstNameInput(false)
            dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_FIRST_NAME, payload: data.first_name })
        }
    };
    const onhandleChangeLastName = (e) => {
        data.last_name = e.target.value;
        if (!data.last_name) {
            setValidateLastNameInput(true)
        } else {
            setValidateLastNameInput(false)
            dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_LAST_NAME, payload: data.last_name })
        }
    };
    const onhandleChangeEmail = (e) => {
        data.email = e.target.value
        if (!data.email) {
            setValidateEmailInput(true)
        } else {
            setValidateEmailInput(false)
            dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_EMAIL, payload: data.email })
        }
    };
    const onhandleChangeStreetAddress = (e) => {
        data.street_address = e.target.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_STREET_ADDRESS, payload: data.street_address })
    };
    const onhandleChangesetBlogUnits = (e) => {
        data.other_address_details = e.target.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_OTHER_ADDRESS_DETAILS, payload: data.other_address_details })
    };
    const onhandleChangesetCity = (e) => {
        data.city = e.target.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CITY, payload: data.city })
    }
    const onhandleChangeState = (e) => {
        data.state = e.target.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_STATE, payload: data.state })
    }
    const onhandleChangeZipCode = (e) => {
        data.postal_code = e.target.value
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_POSTAL_CODE, payload: data.postal_code })
    };

    const onhandleChangePhoneNumber = (values, sourceInfo) => {
        data.phone_number = values.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_PHONE_NUMBER, payload: data.phone_number })
    }
    const onhandleChangeTypeOfPhone = (e) => {
        data.type_of_phone = e.target.value;
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_TYPE_PHONE, payload: data.type_of_phone })
    };
    const onhandleChangeApiKey = (e) => {
        data.warranty_api_key = e.target.value;
        dispatch({ type: "UPDATE_SINGLE_COMPANY_WARRANTY_API_KEY", payload: data.warranty_api_key })
    }
    const generateNewKey = (e) => {
        e.preventDefault();
        let key = '';
        while (key.length < 80) {
            key += Math.random().toString(36).substring(2);
        }
        key = key.substring(0, 80);
        data.warranty_api_key = key;
        dispatch({ type: "UPDATE_SINGLE_COMPANY_WARRANTY_API_KEY", payload: data.warranty_api_key })
    }
    const onhandleDropDownState = () => {
        stateRef.current.style.color = "black";
    }
    const onhandleDropDownMobile = () => {
        mobileRef.current.style.color = "black";
    }
    const onDrop = useCallback(acceptedFiles => {
        const createImageURL = URL.createObjectURL(acceptedFiles[0])
        setSrcImg(createImageURL);
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_IMAGE_FILE_NAME, payload: acceptedFiles[0]?.name })
        setSelectedFile(acceptedFiles)
        setType(Constant.UPDATE)
        setShow(true)

    }, []) //if user upload file without fill in any input, it 
    // not reset the
    const getCroppedImg = async () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            console.log(crop.width);
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");

            // New lines to be added
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
            var base64Image = canvas.toDataURL("image/jpeg", 1);
            var blobData = CompanyUtil.dataURItoBlob(base64Image);

            const isNotCrop = base64Image.length <= 6;
            if (isNotCrop) {
                alert("Please crop the logo or close to keep the original logo.")
            } else {
                setShow(false);
                setResult(blobData);
            }
        } catch (e) {
            console.log("crop the image");
        }
    };
    const handleClose = () => {
        setShow(false)
    };
    const onhandleCancel = () => {
        history.goBack();
    }
    const handleSearch = async () => {
        const url = "/api/v1/limited-user-list";
        const searchPayload = {
            "search": search
        };
        try {
            const apiResponse = await axios.post(url, searchPayload, { headers: { "authorization": `Bearer ${accessToken}` } });
            setSearchedUsers(apiResponse?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };
    const handleSelect = (user) => {
        if (!selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
    
            // If the user is in removedUsers, remove them
            if (removedUsers.some(removedUser => removedUser.id === user.id)) {
                setRemovedUsers(prevRemoved => prevRemoved.filter(u => u.id !== user.id));
            }
        }
    };
    const handleDeselect = (user) => {
        setSelectedUsers(selectedUsers.filter(u => u.id !== user.id));
    
        // If the user's company_id matches param?.companyId, add to removedUsers
        if (user.company_id === param?.companyId) {
            setRemovedUsers([...removedUsers, user]);
        }
    };
    const populateData = () => {
        let formData = new FormData();
        if (types === Constant.UPDATE) {
            if (result) {
                //if user crop the logo, then we append crop image
                formData.append("product-info", result, selectedFile[0]?.path);
            } else {
                //add original logo
                formData.append("product-info", selectedFile[0])
            }
        }
        formData.append("s3AccessKey", data?.s3_access_key);
        formData.append("type", types);
        formData.append("name", data?.name);
        formData.append("streetAddress", data?.street_address);
        formData.append("city", data?.city);
        formData.append("state", data?.state);
        formData.append("otherAddress", data?.other_address_details);
        formData.append("postalCode", data?.postal_code);
        formData.append("firstName", data?.first_name);
        formData.append("lastName", data?.last_name)
        formData.append("email", data?.email);
        formData.append("phoneNumber", data?.phone_number);
        formData.append("typeOfPhone", data?.type_of_phone)
        formData.append("removedUsers", JSON.stringify(removedUsers));
        const newUsersList = selectedUsers.filter(user => user.company_id !== param?.companyId);
        formData.append("newUsers", JSON.stringify(newUsersList));
        formData.append("warrantyApiKey", data?.warranty_api_key);
        return formData;
    }
    const onhandleSubmit = (e) => {
        e.preventDefault();
        if (
            data?.name &&
            data?.first_name &&
            data?.last_name &&
            data?.email
        ) {
            dispatch(UpdateSingleCompanyAction(populateData(), accessToken, localStorage.getItem("companyId"), param?.companyId))
            setType(null);// set type to null to avoid update the same image
        }

    }

    const onhandleDeleteImageFile = (e, id) => {
        setType(Constant.DELETE)
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_IMAGE_FILE_NAME, payload: "" })
    }

    return (
        <div className="mainContent">
            {loading ? <LoadingSpinner />
                :
                <div className="createPost-form-container offset-container company-createPost">
                    {
                        HelperUtil.isDataExist(data) &&

                        <form onSubmit={onhandleSubmit} className="createPost-form">
                            
                            { localStorage.getItem("breadCrumbFlag")
                                ? 
                                    <div className="admin-only">
                                        <label
                                            className="admin-only-label"
                                        >
                                            Admin Only
                                        </label>
                                        <input 
                                            type="text" 
                                            placeholder="Search all users..." 
                                            value={search} 
                                            onChange={e => setSearch(e.target.value)}
                                            onKeyUp={handleSearch}
                                            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                            className='admin-only-input'
                                        />
                                        {/* <button>
                                            Revert access changes
                                        </button> */}
                                        <div className='horizontal-group'>
                                            <div className="vertical-group">
                                                <label className="add-company-container-input-label--label user-label">ALL USERS</label>
                                                <div className="userSelectBox">
                                                    {searchedUsers.map(user => (
                                                        !selectedUsers.some(selectedUser => selectedUser.id === user.id) && (
                                                            <div key={user.id} onClick={() => handleSelect(user)} className="user-selector">
                                                                {user.name} ({user.email})
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            </div>

                                            <FontAwesomeIcon icon={faArrowRight} />
                                            
                                            <div className="vertical-group">
                                                <label className="add-company-container-input-label--label user-label">COMPANY ACCESS</label>
                                                <div className="userSelectBox">
                                                    {selectedUsers.map(user => (
                                                        <div key={user.id} onClick={() => handleDeselect(user)} className="user-selector">
                                                            {user.name} ({user.email})
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {selectedUsers.map(user => (
                                                user.company_id !== null && user.company_id !== param?.companyId ? (
                                                    <label key={user.id} className="admin-warning">
                                                        {user.name} ({user.email}) belongs to a different company that will be overwritten
                                                    </label>
                                                ) : null
                                            ))}
                                        </div>

                                        <hr />

                                        <div className="vertical-group">
                                            <label className="add-company-container-input-label--label user-label">WARRANTY API KEY</label>
                                            <EditCompanyInputLabel
                                                onhandleChange={onhandleChangeApiKey}
                                                inputValue={HelperUtil.removeNullString(data?.warranty_api_key)}
                                            />
                                            <button
                                                id='generate-new-key'
                                                onClick={(e) => generateNewKey(e)}
                                            >
                                                Generate New
                                            </button>
                                        </div>
                                        
                                    </div>
                                :
                                    <></>
                            }
                            
                            <ConsumerContentHeading
                                text="Organization"
                            />
                            <EditCompanyInputLabel
                                labelText="COMPANY NAME "
                                star="*"
                                onhandleChange={onhandleChangeCompany}
                                inputValue={HelperUtil.removeNullString(data?.name)}
                                validateInput={validateCompanyInput}
                                validateInputText="Company name cannot be blank. "

                            />
                            <EditCompanyInputLabel
                                labelText="STREET ADDRESS"
                                onhandleChange={onhandleChangeStreetAddress}
                                inputValue={HelperUtil.removeNullString(data?.street_address)}
                            />
                            <EditCompanyInputLabel
                                labelText="BLDG, SUITE, UNIT"
                                onhandleChange={onhandleChangesetBlogUnits}
                                inputValue={HelperUtil.removeNullString(data?.other_address_details)}
                            />
                            <div className="vertical-group">
                                <label className="edit-company-container-city-state-zip--label">CITY, STATE, ZIP</label>
                                <div className="horizontal-group">
                                    <input
                                        type="text"
                                        placeholder="City"
                                        className="edit-company-container-city-state-zip--input"
                                        onChange={onhandleChangesetCity}
                                        value={HelperUtil.removeNullString(data?.city)}
                                    />
                                    <select
                                        className="edit-company-container-city-state-zip--dropdown"
                                        ref={stateRef}
                                        onClick={onhandleDropDownState}
                                        onChange={onhandleChangeState}
                                    >
                                        <option
                                            className="edit-company-container-city-state-zip--option-placeholder"
                                            value=""
                                            disabled selected
                                        >
                                            {HelperUtil.removeNullString(data?.state)}
                                        </option>
                                        {stateList.map((val, index) => {
                                            return (
                                                <option
                                                    className="edit-company-container-city-state-zip--option"
                                                    value={HelperUtil.removeNullString(val.state)}>
                                                    {val.State}
                                                </option>
                                            )

                                        })}
                                    </select>
                                    <input
                                        type="text"
                                        placeholder="Zip Code"
                                        className="add-company-container-city-state-zip--input"
                                        onChange={onhandleChangeZipCode}
                                        value={HelperUtil.removeNullString(data?.postal_code)}
                                    />
                                </div>
                            </div>
                            <div className='edit-company-contact-container'>
                                <ConsumerContentHeading
                                    text="Company Contact"
                                />
                                <div className="horizontal-group">
                                    <div className="vertical-group">
                                        <label className="edit-company-contact-container-label-input--label">FIRST NAME
                                            <i className="star"> *</i>
                                        </label>
                                        <input
                                            className="edit-company-contact-container-label-input--input edit-company-contact-first-name-input"
                                            type="text"
                                            onChange={onhandleChangeFirstName}
                                            placeholder="First Name"
                                            value={HelperUtil.removeNullString(data?.first_name)}
                                        />
                                    </div>

                                    <div className="vertical-group">
                                        <label className="edit-company-contact-container-label-input--label edit-company-contact-last-name">LAST NAME
                                            <i className="star"> *</i>
                                        </label>
                                        <input
                                            className="edit-company-contact-container-label-input--input"
                                            type="text"
                                            onChange={onhandleChangeLastName}
                                            placeholder="Last Name"
                                            value={HelperUtil.removeNullString(data?.last_name)}
                                        />
                                    </div>
                                </div>
                                {/**** FirstName validation Input */}
                                {validateFirstNameInput ?
                                    <div className="edit-company-container--input-validation">First Name cannot be blank</div>
                                    :
                                    <div> </div>
                                }
                                {/**** lastName validation Input */}
                                <label> </label>
                                {validateLastNameInput ?
                                    <div className="edit-company-container--input-validation">Last Name cannot be blank</div>
                                    :
                                    <div> </div>
                                }
                                <div className="horizontal-group">
                                    <div className='vertical-group'>
                                        <label className="edit-company-contact-container-label-input--label ">EMAIL
                                            <i className="star"> *</i>
                                        </label>
                                        <input
                                            className="edit-company-contact-container-label-input--input edit-company-contact-email-input"
                                            type="email"
                                            onChange={onhandleChangeEmail}
                                            placeholder="Email"
                                            value={HelperUtil.removeNullString(data?.email)}
                                        />
                                    </div>
                                    <div className='vertical-group'>
                                        <label className="edit-company-contact-container-label-input--label edit-company-contact-phone">PHONE</label>
                                        <div className="add-company-contact-container--email-mobile--drop-down">
                                            <select
                                                className="edit-company-container-label-input--dropdown"
                                                ref={mobileRef}
                                                onClick={onhandleDropDownMobile}

                                                onChange={onhandleChangeTypeOfPhone}
                                            >
                                                <option className="" value="" disabled selected>{HelperUtil.removeNullString(data?.type_of_phone)}</option>
                                                <option className="" value="Home" >Home</option>
                                                <option className="" value="Mobile" >Mobile</option>
                                                <option className="" value="Work" >Work</option>
                                            </select>
                                            <NumberFormat
                                                className="edit-company-contact-container-label-input--input"
                                                placeholder="+1 (000) 000-0000"
                                                format="+1 (###) ###-####"
                                                onValueChange={onhandleChangePhoneNumber}
                                                value={HelperUtil.removeNullString(data?.phone_number)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/**** Email validation Input */}
                                <label> </label>
                                {validateEmailInput ?
                                    <div className="edit-company-container--input-validation">Email cannot be blank</div>
                                    :
                                    <div> </div>
                                }
                                <div className="edit-company-contact-container-upload-images">

                                    <div>
                                        <UploadFile
                                            labelText="LOGO ATTACHMENT"
                                            onDrop={onDrop}
                                            isImage={true}
                                            fileName={HelperUtil.removeNullString(data?.file_name)}
                                            onhandleDelete={onhandleDeleteImageFile}
                                            isXcross={true}
                                            imageSource={data?.image_url && srcImg ? srcImg : data?.image_url}
                                        // imageId={data?.id}

                                        />
                                    </div>

                                    <div>
                                        <Modal
                                            show={show}
                                            onHide={handleClose}
                                            dialogClassName="edit-company-container-modal"
                                        >
                                            <Modal.Header className="edit-company-container-modal--header" closeButton>
                                                <Modal.Title>Edit Photo</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="edit-company-container-modal--body">
                                                {srcImg && (
                                                    <div>
                                                        <ReactCrop
                                                            style={{ maxWidth: "50%" }}
                                                            src={srcImg}
                                                            onImageLoaded={setImage}
                                                            crop={crop}

                                                            onChange={setCrop}
                                                        />
                                                    </div>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer className="edit-company-container-modal--footer">
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button className="primary" onClick={getCroppedImg}
                                                >
                                                    crop
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                                <div className="edit-company-contact-container-save-cancel-button">
                                    <SaveAndCancelButton
                                        text="Save"
                                        onhandleCancel={onhandleCancel}
                                    />
                                </div>
                                <div className='text-center'><CopyRight /></div>
                            </div>
                        </form>
                    }
                </div>
            }
        </div>
    )
}


export default EditCompany
