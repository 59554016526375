class MailChimpEndPoint{
    /**
     * /api/v1/mail-chimp/:companyId/:audienceId
     * /api/v1/mail-chimp/
     */
    MAIL_CHIMP = "/api/v1/mail-chimp/"
    TEST_MAIL_CHIMP_CONNECTION = '/api/v1/mail-chimp/connection/'
    
    
}
export default new MailChimpEndPoint();
