import React from 'react';
import './SaveAndCancelButton.css';
import { Link } from 'react-router-dom';
const SaveAndCancelButton = ({ 
    endPoint, 
    id, 
    text, 
    onhandleSubmit, 
    onhandleCancel 
}
    ) => {
  
    return (
        <div className="bottom-buttons-wrapper">
            <div onClick={onhandleCancel} className="btn btn-outline-light cancel-button" > Cancel </div>

            <button className="btn btn-primary save-button" onClick={onhandleSubmit}>{text}</button>
        </div>
)
};

export default SaveAndCancelButton;
