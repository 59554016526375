import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import axios from 'axios';
import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import BackEndPoint from '../../../../ENDPOINT/back-end-endpoint/endPoint';
import './ConsumerDetailRollup.css';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faPrint, faDownload, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    FetchProductionLogItem,
    FetchProductionLogItemByFilterstartDate,
    FetchProductionLogItemByFilterStartAndEndDate,
    setProductInfoData,
    setStartDate
} from '../../../../redux/action/ConsumerAction';
import { ConsumerMemoizedLog } from '../ConsumerLog/ConsumerLog';
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import Pagination from 'react-bootstrap/Pagination'
import InputMask from 'react-input-mask';
import ReactEndPoint from '../../../../ENDPOINT/react-endpoints/endPoint'
import { ConsumerTypes } from '../../../../redux/constants/ConsumerType';
import ConsumerDetailRollupHeaderBox from './ConsumerDetailRollupHeaderBox';
import ConsumerDetailRollupSectionBox from './ConsumerDetailRollupSectionBox';
import ConsumerDetailRollupLogList from './ConsumerDetailRollupLogList';
import ErrorHandling from '../../../errorHandling/error';
import ConsumerContentHeading from '../ConsumerContentHeading/ConsumerContentHeading';
import LineChart from './charts/LineChart';
import UPC_SKUBarChart from './charts/UPC_SKUBarChart';
import HMCUDoughnutChart from './charts/HMCUDoughnutChart';
import ACKDoughnutChart from './charts/ACKDoughnutChart';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ConsumerDetailRollup = ({upcList = []}) => {
    let query = useQuery();
    const param = useParams();
    const dsnParam = param?.dsnParam
    const PROJECT_OVERVIEW = dsnParam ? "< WARRANTLIST USER" : "< PROJECT OVERVIEW"

    let qsdate = ProductInfoUtil.convertDateToInputTypeFormat(query.get("start_date"));
    if (qsdate == null || qsdate === "" || qsdate === "Invalid Date") {
        qsdate = "";
    }
    const [startDate, setstartDate] = useState(qsdate);
    
    let qedate = ProductInfoUtil.convertDateToInputTypeFormat(query.get("end_date"));
    if (qedate == null || qedate === "" || qedate === "Invalid Date") {
        qedate = "";
    }
    const [endDate, setEndDate] = useState(qedate);

    const [showModal, setShowModal] = useState(false);
    const [currentChart, setCurrentChart] = useState(null);  

    const [dsn, setDsn] = useState(dsnParam ? dsnParam : "");
    // const [currentPage, setCurrentPage] = useState(null);
    // const [upcCodeQuery, setUpcCodeQuery] = useState(query.get('upcCodeQuery'));
    // const [filterUpcCode, setFilterUpcCode] = useState("");
    const [defaultProductInfoData, setDefaultProductInfoData] = useState("");
    const [defaultProductionLogItem, setdefaultProductionLogItem] = useState([]);
    const [upcItem, setUpcItem] = useState(query.get("upc"));

    let a;
    a = query.get("upc")?.split(',');
    const [selectedSkus, setSelectedSkus] = useState(a);
    const [selectedUpcs, setSelectedUpcs] = useState(a);
    const [error, setError] = useState(null);
    const [loadingSearchDate, setLoadingSearchDate] = useState(false);

    const formRef = useRef(null);

    const barRef = useRef(null);
    const lineRef = useRef(null);
    const HMCURef = useRef(null);
    const ACKRef = useRef(null);
    const expandedChartRef = useRef(null);

    const productInfoData = useSelector((state) => state.ConsumerReducer.productInfoData);
    const listOfSkus = useSelector((state) => state.FetchProductionLogItemReducer.skusList);

    const dispatch = useDispatch();
    const history = useHistory();

    let DEFAULT_URL_ROLL_UP = ReactEndPoint.DASHBOARD_DETAIL_URL + param?.companyId + "/" + param?.id


    // console.log(location?.state?.dsn);
    //roll/up/userid/dsn

    let token = localStorage.getItem("accessToken") || undefined
    let projectName = localStorage.getItem("projectName") || "";
    let startDateQuery = query.get("start_date");
    let endDateQuery = query.get("end_date");
    let upcCodeSecondQuery = query.get("upcCodeQuery");
    let upcItemQuery = query.get("upc");

    const isDateExist = (date) => {
        return date && ProductInfoUtil.isValidDate(date);
    }
    //route/:projectid/userid/dsn
    // ------> detail handler in controller
    //route/:porject/id ? query
    const fetchData = async () => {
        setLoadingSearchDate(true);
        let route = `${BackEndPoint.DASHBOARD_DETAIL_URL_ID}${param?.companyId}/${param?.id}?page=1`
        if (startDateQuery && endDateQuery) {
            route = route + `&start_date=${startDateQuery}&end_date=${endDateQuery}`;
        }
        if (upcItemQuery) {
            route = route + `&upc=${upcItemQuery}`;
        }
        try {
            //axios.post accept two parameters: (URL, Data);
            let apiResponse = await axios.get(route, { headers: { "authorization": `Bearer ${token}` } });
            if (apiResponse?.status === 200) {
                dispatch(setProductInfoData(apiResponse?.data))
                // setdefaultProductionLogItem(apiResponse?.data?.productionLogItems)
                dispatch({ type: ConsumerTypes.FETCH_SKU_LIST, payload: apiResponse?.data?.skus })
                setDefaultProductInfoData(apiResponse?.data);
                dispatch({ type: ConsumerTypes.FILTER_PRODUCT_INFO_DATA, payload: apiResponse?.data })
                dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: apiResponse?.data?.productionLogItems })
                dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: apiResponse?.data?.productionLogItems })
                setLoadingSearchDate(false);
            }
        } catch (error) {
            console.log(error);
            setError(error?.message);
        }

    }
    const fetchDataWithDsn = async () => {
        // let apiResponse = await ConsumerController.fetchDetailById(param?.id, accessToken);
        try {
            //axios.post accept two parameters: (URL, Data);
            let apiResponse = await axios.get(`${BackEndPoint.DASHBOARD_DETAIL_URL_ID}${param?.companyId}/${param?.id}/${dsnParam}?page=1`, { headers: { "authorization": `Bearer ${token}` } });
            if (apiResponse?.status === 200) {
                const projectNameApi = apiResponse?.data?.productInfo?.[0]?.project_name;
                localStorage.setItem("projectName", projectNameApi)
                dispatch(setProductInfoData(apiResponse?.data))
                // dispatch({ type: ConsumerTypes.FILTER_PRODUCT_INFO_DATA, payload: apiResponse?.data })
                dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: apiResponse?.data?.productionLogItems })
                dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: apiResponse?.data?.productionLogItems })
            }
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }

    }

    useEffect(() => {
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE });
        fetchData();
    }, [startDateQuery, endDateQuery, upcItemQuery])

    const totalOfAck = (ackVersionArray) => {
        let total = 0;
        for (let data of ackVersionArray) {
            total += parseInt(data?.count)
        }
        return total
    }
    const onhandleChangeSinglestartDate = (e) => {
        let input = e?.target?.value;
        setstartDate(input);
    }
    const onhandleChangeSingleEndDate = (e) => {
        let input = e?.target?.value;
        setEndDate(input);
    }

    const onhandleSearch = (e) => {
        e.preventDefault();

        //start_date does not exist yeet
        /**
         * 1. Startdate and endate
         * 2. Startdate
         * 3. default date - empty
         * @CheckInputStateOfReact @StartDate
         */
        //refresh the page to make it state with query
        let route = `${DEFAULT_URL_ROLL_UP}`;
        /**
         * Change the route then switch to use effect after push
         */
        //if start and end date exist, excute the scope
        //skip other condition.
        //start date, end date, and sku
        //sku
        let localStartDate;
        if (startDate) {
            localStartDate = ProductInfoUtil.convertDate(startDate);
        }
        let localEndDate;
        if (endDate) {
            localEndDate = ProductInfoUtil.convertDate(endDate);
        }

        let paramNumber = 0;

        if (isDateExist(localStartDate) && isDateExist(localEndDate)) {
            if (paramNumber == 0) {
                route = route + `?start_date=${localStartDate}&end_date=${localEndDate}`;
            } else {
                route = route + `&start_date=${localStartDate}&end_date=${localEndDate}`;
            }
            paramNumber++;
        }
        if (selectedUpcs && selectedUpcs.length > 0) {
            if (paramNumber == 0) {
                route = route + `?upc=${selectedUpcs.join(',')}`;
            } else {
                route = route + `&upc=${selectedUpcs.join(',')}`;
            }
            paramNumber++;
        }
        history.push(route)

    }

    const handleOpenModal = (chart) => {
        setCurrentChart(chart);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setCurrentChart(null);
        setShowModal(false);
    };

    const onhandleResetAllSearchBoxes = (e) => {
        if (startDate
            || endDate
            || selectedSkus !== []) {
                if (query.get("start_date") || query.get("end_date") || query.get("upc")) {
                    dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST })
                }
            e.preventDefault();
            formRef.current.reset();
            setstartDate("");
            setEndDate("");
            setSelectedSkus([]);
            setSelectedUpcs([]);
            history.push(DEFAULT_URL_ROLL_UP)
        }
    }
    const options = upcList?.map(item => ({ label: item?.skus?.toUpperCase(), value: item.upc_code }));
    const onMultiSelectChange = (e) => {
        setSelectedSkus(e.value.map(upc => upcList.find(item => item.upc_code === upc)?.skus?.toUpperCase()));
        setSelectedUpcs(e.value);
    }
    const onhandleSwitchRoute = (nextEndPoint) => {
        history.push(nextEndPoint);
    }
    const onFocusStartDate = () => { }
    const onBlurStartDate = () => {
        if ((!endDate || endDate === "") && startDate && startDate !== "") {
            setEndDate(startDate);
        }
    }
    
    const downloadImage = useCallback((ref) => {
        const link = document.createElement('a');
        link.download = 'chart.png';
        link.href = ref.current.toBase64Image();
        link.click();
    }, []);

    const copyImageToClipboard = useCallback((ref) => {
        fetch(ref.current.toBase64Image())
            .then(response => response.blob())
            .then(blob => {
                navigator.clipboard.write([
                    new ClipboardItem({ 
                        'image/png': blob 
                    })
                ]).then(() => console.log('Chart copied to clipboard!'))
                .catch(err => console.error(err));
            });
    }, []);

    const printImage = useCallback((ref) => {
        const printWindow = window.open('', '_blank');
        const image = new Image();
        image.onload = function() {
            printWindow.document.write('<html><head><title>Print</title></head><body>');
            printWindow.document.write('<img src="' + this.src + '"/>');
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        };
        image.src = ref.current.toBase64Image();
    }, []);    

    if (error) {
        return <ErrorHandling error={error} />;
    }

    const skuText = upcList.find((item) => item.upc_code === upcItem)?.skus?.toUpperCase() || "ALL SKUs";

    return (
        <div style={{height: '100%'}}>
            {loadingSearchDate ?
                <div className='d-flex justify-content-center consumer-detail-rollup--spinner'>
                    <div className="spinner-border " role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                productInfoData?.productInfo ?
                    <div className="detail-rollup-container">
                        <ConsumerContentHeading
                            text="Production Details"
                        />
                        <form
                            className='filter-date'
                            onSubmit={onhandleSearch}
                            ref={formRef}
                        >
                            <div className="left-input">
                                <div className='filter-date--start-date'>
                                    <div className='filter-date--start-date-text'>
                                        <div >Start Date:</div>
                                    </div>
                                    <input 
                                        className="input-field filter-date--input-mask-start-date"
                                        type="date"
                                        value={startDate}
                                        onChange={onhandleChangeSinglestartDate}
                                        onFocus={onFocusStartDate}
                                        onBlur={onBlurStartDate}
                                    />
                                </div>

                                <div className='filter-date--end-date'>
                                    <div className='filter-date--end-date-text'>
                                        <div >End Date:</div>
                                    </div>
                                    <input 
                                        className="input-field filter-date--input-mask-start-date"
                                        type="date"
                                        value={endDate}
                                        onChange={onhandleChangeSingleEndDate}
                                    />
                                </div>
                            </div>

                            {upcList && upcList?.length > 0 &&
                                <div className="mid-input">
                                    <div className='filter-skus-dropdown'>
                                        <div className='filter-skus-dropdown-text'>
                                            <div>SKUs:</div>
                                        </div>
                                        <MultiSelect 
                                            value={selectedUpcs} 
                                            options={options} 
                                            onChange={onMultiSelectChange} 
                                            placeholder="Select SKUs" 
                                            filter
                                            display="chip"
                                        />
                                    </div>
                                </div>
                            }
                            <div className="right-input">
                                <button className='Consumer-detail-roll-up--search-button btn'>
                                    <span className='button-text'>Search</span>
                                </button>
                                <button type="button" className='Consumer-detail-roll-up--reset-button btn' onClick={onhandleResetAllSearchBoxes}>
                                    <span className='button-text'>Reset</span>
                                </button>
                            </div>
                        </form>
                        <div className="product-info-grid-wrapper">
                            <div className="chart-container">
                                <div className="chartwidth" style={{ margin: "0" }}>
                                    <div className='production-detail-chart' style={{ backgroundColor: 'white', paddingBottom: '10px', height: '27rem'}} >
                                        <UPC_SKUBarChart ref={barRef} productInfoData={productInfoData}/>
                                        <div className='chartbuttons'>
                                            <button title="Click to expand chart" className='chartbutton' onClick={() => handleOpenModal(<div className='chartwidthmodal'><UPC_SKUBarChart ref={expandedChartRef} productInfoData={productInfoData} />
                                                <div className='chartbuttons'>
                                                    <button title="Click to print chart" className='chartbutton' onClick={() => printImage(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faPrint} />
                                                    </button>
                                                    <button title="Click to download chart as an image" className='chartbutton'>
                                                        <FontAwesomeIcon onClick={() => downloadImage(expandedChartRef)} icon={faDownload} />
                                                    </button>
                                                    <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faClipboard} />
                                                    </button>
                                                </div>
                                            </div>)}>
                                                <FontAwesomeIcon icon={faExpand} />
                                            </button>
                                            <button title="Click to print chart" className='chartbutton' onClick={() => printImage(barRef)}>
                                                <FontAwesomeIcon icon={faPrint} />
                                            </button>
                                            <button title="Click to download chart as an image" className='chartbutton'>
                                                <FontAwesomeIcon onClick={() => downloadImage(barRef)} icon={faDownload} />
                                            </button>
                                            <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(barRef)}>
                                                <FontAwesomeIcon icon={faClipboard} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chart-container">
                                <div className="chartwidth">
                                    <div className="devices-per-day-and-checkbox-wrapper" style={{ margin: "0" }}>
                                        <LineChart ref={lineRef} productInfoData={productInfoData}
                                            buttons={
                                                <div className='chartbuttons'>
                                                    <button title="Click to expand chart" className='chartbutton' onClick={() => handleOpenModal(<div className='chartwidthmodal'><LineChart ref={expandedChartRef} productInfoData={productInfoData} customStyle={{height: '100%', boxShadow: 'None', border: 'None'}} 
                                                    buttons={
                                                        <div className='chartbuttons'>
                                                            <button title="Click to print chart" className='chartbutton' onClick={() => printImage(expandedChartRef)}>
                                                                <FontAwesomeIcon icon={faPrint} />
                                                            </button>
                                                            <button title="Click to download chart as an image" className='chartbutton'>
                                                                <FontAwesomeIcon onClick={() => downloadImage(expandedChartRef)} icon={faDownload} />
                                                            </button>
                                                            <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(expandedChartRef)}>
                                                                <FontAwesomeIcon icon={faClipboard} />
                                                            </button>
                                                        </div>
                                                    } />
                                                        
                                                    </div>)}>
                                                        <FontAwesomeIcon icon={faExpand} />
                                                    </button>
                                                    <button title="Click to print chart" className='chartbutton' onClick={() => printImage(lineRef)}>
                                                        <FontAwesomeIcon icon={faPrint} />
                                                    </button>
                                                    <button title="Click to download chart as an image" className='chartbutton'>
                                                        <FontAwesomeIcon onClick={() => downloadImage(lineRef)} icon={faDownload} />
                                                    </button>
                                                    <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(lineRef)}>
                                                        <FontAwesomeIcon icon={faClipboard} />
                                                    </button>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Pie Chart to display Count per HMCU data*/}
                            <div className="chart-container">
                                <div className="chartwidth" style={{margin:0}}>
                                    <div className="production-detail-chart" style={{ backgroundColor: 'white', margin: '0', padding: '5px 50px 20px', height: 'inherit' }}>
                                        <HMCUDoughnutChart ref={HMCURef} productInfoData={productInfoData}/>
                                        <div className='chartbuttons'>
                                            <button title="Click to expand chart" className='chartbutton' onClick={() => handleOpenModal(<div className='chartwidthmodal' style={{width: '80vh', height: '80vh'}}><HMCUDoughnutChart ref={expandedChartRef} productInfoData={productInfoData} />
                                                <div className='chartbuttons'>
                                                    <button title="Click to print chart" className='chartbutton' onClick={() => printImage(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faPrint} />
                                                    </button>
                                                    <button title="Click to download chart as an image" className='chartbutton'>
                                                        <FontAwesomeIcon onClick={() => downloadImage(expandedChartRef)} icon={faDownload} />
                                                    </button>
                                                    <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faClipboard} />
                                                    </button>
                                                </div>
                                            </div>)}>
                                                <FontAwesomeIcon icon={faExpand} />
                                            </button>
                                            <button title="Click to print chart" className='chartbutton' onClick={() => printImage(HMCURef)}>
                                                <FontAwesomeIcon icon={faPrint} />
                                            </button>
                                            <button title="Click to download chart as an image" className='chartbutton'>
                                                <FontAwesomeIcon onClick={() => downloadImage(HMCURef)} icon={faDownload} />
                                            </button>
                                            <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(HMCURef)}>
                                                <FontAwesomeIcon icon={faClipboard} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Doughnut Chart to display Count per ACK data*/}
                            <div className="chart-container">
                                <div className="chartwidth" style={{margin:0}}>
                                    <div className='production-detail-chart' style={{ backgroundColor: 'white', margin: '0', padding: '5px 50px 20px', height: 'inherit' }}>
                                        <ACKDoughnutChart ref={ACKRef} productInfoData={productInfoData}/>
                                        <div className='chartbuttons'>
                                            <button title="Click to expand chart" className='chartbutton' onClick={() => handleOpenModal(<div className='chartwidthmodal' style={{width: '80vh', height: '80vh'}}><ACKDoughnutChart ref={expandedChartRef} productInfoData={productInfoData} />
                                                <div className='chartbuttons'>
                                                    <button title="Click to print chart" className='chartbutton' onClick={() => printImage(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faPrint} />
                                                    </button>
                                                    <button title="Click to download chart as an image" className='chartbutton'>
                                                        <FontAwesomeIcon onClick={() => downloadImage(expandedChartRef)} icon={faDownload} />
                                                    </button>
                                                    <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(expandedChartRef)}>
                                                        <FontAwesomeIcon icon={faClipboard} />
                                                    </button>
                                                </div>
                                            </div>)}>
                                                <FontAwesomeIcon icon={faExpand} />
                                            </button>
                                            <button title="Click to print chart" className='chartbutton' onClick={() => printImage(ACKRef)}>
                                                <FontAwesomeIcon icon={faPrint} />
                                            </button>
                                            <button title="Click to download chart as an image" className='chartbutton'>
                                                <FontAwesomeIcon onClick={() => downloadImage(ACKRef)} icon={faDownload} />
                                            </button>
                                            <button title="Click to copy this chart as an image" className='chartbutton' onClick={() => copyImageToClipboard(ACKRef)}>
                                                <FontAwesomeIcon icon={faClipboard} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                        <LoadingSpinner />
                    }

            <Modal className="wide-modal" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>View Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentChart}
                </Modal.Body>
            </Modal>
        </div>

    );
};

export default ConsumerDetailRollup;

