import React from 'react';
import Scroll from '../../../scroll/scroll';
import './ConsumerLog.css'
import ConsumerLogList from './ConsumerLogList';
import { useSelector } from 'react-redux';
export const ConsumerLog = ({ 
    productionLogItemData,
    loading }) => {
    const defaultProductionLogItem = useSelector((state) => state.FetchProductionLogItemReducer.filterData);
    const defaultLogItem = [
        {
            mfg_date:new Date(),
            upc_code: "",
            dsn: "",
            hmcu_version:"",
            ack_version: "",
            hardware_revision_numbers:""
        }
    ]
    if(productionLogItemData?.length > 0){
        return (
            <div className="consumer-production-log-container">
                <div className="consumer-production-log-items drop-shadow">
                    {productionLogItemData.length > 0 ?
                        <ConsumerLogList
                            productionLogItems={productionLogItemData}
                            loading={loading}
                                />
                        :
                        <ConsumerLogList
                            productionLogItems={defaultLogItem}
                            loading={loading}
                        />
                    }
                </div>
            </div>
        );
    }else{
        return (
            <div className="consumer-production-log-container">
                <div className="consumer-production-log-items drop-shadow">
                    {defaultProductionLogItem?.length > 0 ?
                        <ConsumerLogList
                            productionLogItems={defaultProductionLogItem} 
                            loading={loading}
                            />
                        :
                        <ConsumerLogList
                            productionLogItems={defaultLogItem}
                            loading={loading}
                        />
                    }
                </div>
            </div>
        );
    }
  
};
export const ConsumerMemoizedLog = React.memo(ConsumerLog);
