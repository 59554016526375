import React from 'react';
import './holiosinform.css';
const HoliosInForm = () =>{
    return(
        <img 
            src="/logo2-a.svg"
            alt="Transparent Holios Logo White" className="holios-header"
        />
    )
}
export default HoliosInForm;
