import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadSourceFile, UploadSourceFile } from '../../../../redux/action/ConsumerAction';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import UploadFile from '../../UploadFile/UploadFile';
import ConsumerContentHeading from '../ConsumerContentHeading/ConsumerContentHeading';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';
import ConsumerLabelAndInput from '../ConsumerLabelAndInput/ConsumerLabelAndInput';
import './sourceCodeFile.css'
import NumberFormat from 'react-number-format';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import { useParams, Redirect, useHistory, useLocation } from 'react-router';
import { Spinner } from 'react-bootstrap';
import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import InputDate from '../../../InputDate/InputDate';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';

const ConsumerSourceCodeFile = () => {
    const anchorTageRef = useRef();
    let accessToken = localStorage.getItem("accessToken");

    const param = useParams();
    const [logNameValidation, setLogNameValidation] = useState(false);
    const [fileValidation, setFileValdiation] = useState(false);
    const [dateValidation, setDateValidation] = useState(false);

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [date, setDate] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    let projectName = localStorage.getItem("projectname");
    const companyId = localStorage.getItem("companyId")

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const downloadData = useSelector((state) => state.DownloadSourceFileCodeReducer.data)
    const { success, loading } = useSelector((state) => state.UploadSourceFileCodeReducer)

    let myDate = "";
    
    const onhandleSubmit = (e) => {
        e.preventDefault();
        if (!name) {
            setLogNameValidation(true);
        }
        if (selectedFile.length === 0) {
            setFileValdiation(true);
        }
        if (!date) {
            setDateValidation(true);
        } else if (selectedFile.length > 0
            && name
            && date
        ) {
            dispatch(UploadSourceFile(
                accessToken,
                param?.id,
                name, description,
                date, selectedFile,
                companyId, location?.state)
            )
        }
    }
    // ${ endPoint.DASHBOARD_SOURCE_CODE_FILE }${ param?.id }
    // useEffect(() => {
        
    // }, [])

    const { setBreadcrumbs } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setBreadcrumbs([]);
    }, []);

    const onhandleChangeName = (e) => setName(e.target.value);
    const onhandleChangeDescription = (e) => setDescription(e.target.value);
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setSelectedFile(acceptedFiles);
    }, [])
    const onhandleDateChange = (e) => {
        setDateValidation(false)
        setDate(e.target.value)
    }
    const onHandleGoingBackToOriginalRoute = () => {
        history.goBack()
    }
    if (success) {
        history.push(`${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${param?.id}`);
    }
    
    return (
        <div className="mainContent">
            {loading ? 
                <div className='scf-container'>
                        <div className="d-flex justify-content-center ">
                            < div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div > 
                </div>
                :
                <div className="scf-container">
                    <div className="source-code-file--heading">
                        <ConsumerHeading
                            text={projectName}
                        />
                    </div>
                
                    <div className="source-code-file-summary">
                    
                        <ConsumerLabelAndInput
                            labelText="File Name "
                            validation={logNameValidation}
                            star="*"
                            validationMessage="File name cannot be blank"
                            onhandleChange={onhandleChangeName}
                        />
                        <ConsumerLabelAndInput
                            labelText="Description"
                            onhandleChange={onhandleChangeDescription}
                        />
                        <div className="source-code-file-section--date">
                            <label className="consumer-production-date-label">
                                Date&nbsp;
                                <i className="star">*</i>
                            </label>
                            <input
                                placeholder="MM/DD/YEAR"
                                type="date"
                                max="3000-01-01"
                                name="date"
                                onChange={onhandleDateChange}
                                className="consumer-production-log-input"
                                required
                            />
                            {dateValidation &&
                                <div className="validation-color-source-code">Date cannot be blank</div>
                            }
                        </div>
                        <div className="source-code-file-section">
                            <UploadFile
                                labelText="File"
                                onDrop={onDrop}
                                fileValidation={fileValidation}
                                fileName={selectedFile[0]?.name}
                            />
                        </div>
                        <SaveAndCancelButton
                            text="Save"
                            endPoint={`${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${param?.id}`}
                            onhandleSubmit={onhandleSubmit}
                            onhandleCancel={onHandleGoingBackToOriginalRoute}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default ConsumerSourceCodeFile;
