import React from 'react';
import './ConsumerHeading.css'
const ConsumerHeading = ({ text }) => {
    return (

        <h5 
            className="consumer-heading text-center"
      
            
            >{text}</h5>
    );
};

export default ConsumerHeading;
