
//darayuth hang account in aws develpoment

// export const fakeUserId = "a3c7b8c3-931a-460f-bde1-7e563a815762"
// Conor id in aws
// export const fakeUserId = "2b652a0082b649cc9561d45720238957"




//my account in locals
//this for barcode component

/**
 * /warranty/barcode/dsnId/userId
 * /warranty/UserId
 * /warranty/dsnid/userId
 */

/**
 * @local @Test
 */
//export const URL = 'http://localhost:3000/warranty/';
/**
 * @Dev test
 */
//export const URL = "http://dashboardfrontend-env.eba-fhqujmub.us-east-1.elasticbeanstalk.com/warranty/"
/**
 * @Production
 */
export const URL = "http://sp3.bluefintechpartners.com/warranty/";

