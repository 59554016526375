import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import endPoint from '../../ENDPOINT/react-endpoints/endPoint';
import ProductInfoUtil from '../../util/ProductInfoUtil';
import ProductInfoEndPoint from '../../ENDPOINT/back-end-endpoint/ProductInfoEndPoint'
import Table from './Table/Table';
import './dashBoard.css';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Logo from './Logo/Logo';
import ErrorHandling from '../errorHandling/error';
const DashBoard = () => {

    // let listItems = useSelector(state => state.dashBoardReducer.listOfProjectName);
    const [listItems, setItems] = useState([]);
    const [defaultListItem, setDefaultListItem] = useState([]);
    const [id, setId] = useState(null)
    const [projectConfirmation, setprojectConfirmation] = useState(null);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState(null);

    const [isSortDate, setIsSortDate] = useState(true);
    const [isSortDateCarat, setIsSortDateCarat] = useState(true);
    const [isSortDateCaratInReverse, setIsortDateCaratInReverse] = useState(true);


    const [isSortProjectName, setIsSortProjectName] = useState(true);
    const [isSortColorProjectNameCarat, setIsSortColorProjectNameCarat] = useState(true);
    const [isSortProjectNameCaratInReverse, setSortProjectNameCaratInReverse] = useState(true);
    const [isTotalUnit, setIsTotalUnit] = useState(true);

    const [isSortLastActivity, setIsSortLastActivity] = useState(true);
    const [isSortLastActivityCarat, setisSortLastActivityCarat] = useState(true);
    const [isSortLastActivityCaratInReverse, setSortLastActivityCaratInReverse] = useState(true);
    const [isSortTotalUnitCara, setIsSortTotalUnitCara] = useState(true);
    const [totalUnitExist, setTotalUnitExist] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    // const [isSortTotalUnit, setSortLastActivityCaratInReverse] = useState(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
    const [isLogo, setIsLogo] = useState(null);

    const [companyName, setCompanyName] = useState("");

    const [error, setError] = useState(null);

    //Company Id
    const param = useParams()
    let token = localStorage.getItem("accessToken") || undefined;
    let companyId = param?.companyId || undefined;

    // localStorage.setItem("companyId", param?.id)
    // const [isSuccess, setIsSuccess] = useState(false);
    const history = useHistory();

    const switchToCreatPost = () => {
        history.push(endPoint.DASHBOARD_CREATE_PRODUCT_INFO + param?.companyId)
    }
    const fetchData = async (token) => {
        setIsLoading(true);
        try {
            const url = ProductInfoEndPoint.DASHBOARD_PRODUCT_INFO_BY_ID + param?.companyId;
            let apiResponse = await axios.get(url, { headers: { "authorization": `Bearer ${token}` } });
            const dataExist = apiResponse?.data;
            if (dataExist.error) { // if the user is not yet associated with an account
                setError(dataExist.error);
                return;
            }
            setItems(dataExist?.productInfo);
            setDefaultListItem(dataExist?.productInfo);
            localStorage.setItem("companyName", dataExist?.name)
            setCompanyName(dataExist?.name)
            localStorage.setItem("Logo", dataExist?.image_url)
            setIsLogo(true);
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }

    }

    const onhandleCloseSuccces = () => setShowSuccess(false);
    const onhandleShowSuccess = () => setShowSuccess(true);
    /**
     * delete iSuccess = true
     * useEffect beging set is Success to false
     * whene isSuccess false it wont rerender use effect
     */
    useEffect(() => {

        if (isDeletedSuccess) {
            setIsDeletedSuccess(false) //set it back to false so that user can delete item again and again.
        }
        fetchData(token);
        return () => {
        };

    }, [isDeletedSuccess])

    const onhandleShow = (e, rowId, name) => {
        e.stopPropagation();
        setShow(true)
        setId(rowId);
        setprojectConfirmation(name);
    };
    const onhandleClose = () => {
        setShow(false);
    }
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    
    const onhandleSortDate = () => {
        setIsSortColorProjectNameCarat(false) // true cara project name turn to default
        // setisSortLastActivityCarat(true) //// true cara lastAcitivty turn to default
        // setTotalUnitExist(true)// reset total unit exist to true to remove the carat
        if (listItems.length > 0) {
            if (isSortDate) {
                //sort lowest to highest
                listItems.sort((a, b) => (a?.startdate < b?.startdate) ? 1 : -1)
                setIsSortDate(false)
                setIsortDateCaratInReverse(false)
                setIsSortDateCarat(false)
                setItems(listItems);
            } else {
                //sort highest to lowest
                listItems.sort((a, b) => (a?.startdate > b?.startdate) ? 1 : -1)
                setIsortDateCaratInReverse(true)
                setIsSortDate(true)
                setItems(listItems)
            }
        }

    }
    const onhandleSortLastActivty = () => {
     
        setIsSortColorProjectNameCarat(false) // true cara project name turn to default
        // setIsSortDateCarat(true)
        // setTotalUnitExist(true)// reset total unit exist to true to remove the carat
        const { getCurrentDate } = ProductInfoUtil;
        if (listItems.length > 0) {
            if (isSortLastActivity) {
                listItems.sort((a, b) => (getCurrentDate(a?.created_at) < getCurrentDate(b?.created_at)) ? 1 : -1)
                setSortLastActivityCaratInReverse(false)
                setisSortLastActivityCarat(false)
                setIsSortLastActivity(false)
                setItems(listItems);
            } else {
                listItems.sort((a, b) => (getCurrentDate(a?.created_at) > getCurrentDate(b?.created_at)) ? 1 : -1)
                setIsSortLastActivity(true)
                setSortLastActivityCaratInReverse(true)
                // setisSortLastActivityCarat(true)
                setItems(listItems);
            }
        }
    }

    const onhandleSortProjectName = () => {
        //setisSortLastActivityCarat(true) //reset last activity carat to default
        // setIsSortDateCarat(true)
        // setTotalUnitExist(true) // reset total unit exist to true to remove the carat
        if (listItems.length > 0) {
            if (isSortProjectName) {
                listItems.sort((a, b) => (a?.projectname < b?.projectname) ? 1 : -1)
                setIsSortColorProjectNameCarat(false)
                setSortProjectNameCaratInReverse(false) //false reverse
                setIsSortProjectName(false);
                setItems(listItems);
            } else {
                listItems.sort((a, b) => (a?.projectname > b?.projectname) ? 1 : -1)
                setIsSortProjectName(true);
                setSortProjectNameCaratInReverse(true) //true back to normal
                
                setItems(listItems);
            }
        }
    }
    const onhandleSortTotalUnit = () => {
 
        setisSortLastActivityCarat(true) //reset last activity carat to default
        setIsSortDateCarat(true)
        // setIsSortColorProjectNameCarat(true) // true cara project name turn to default
        setTotalUnitExist(false);
        if(isSortTotalUnitCara === false){
            //second sort
            listItems.sort((a, b) => (a?.count < b?.count) ? 1 : -1);
            setIsSortTotalUnitCara(true)
        }else{
            //first sort
            listItems.sort((a, b) => (a?.count > b?.count) ? 1 : -1);
            setIsSortTotalUnitCara(false)
            setItems(listItems);
        }
    }


    const handleId = (id) => {
        history.push({
            pathname: `${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${id}`,
        })
    }
    const onhandleDeleteProductInfo = async (rowId) => {
        setShow(false)
        try {
            await axios.delete(`${ProductInfoEndPoint.DASHBOARD_PRODUCT_INFO_BY_ID}${companyId}/${rowId}`, { headers: { "authorization": `Bearer ${token}` } });
            setShowSuccess(true)
            setIsDeletedSuccess(true);
        } catch (error) {
            console.log(error);
            //setShowSucces is false delete unsuccessfully
            setShowSuccess(true)
            setIsDeletedSuccess(false);
            setError(error?.message);
        }
    }
    const isDefaultSearchEmpty = (input) => {
        return input === "";
    }
    function searchItem(e) {
        const value = e.target.value;
        let result = []
        result = defaultListItem.filter(item => item?.project_name.toLowerCase().search(value.toLowerCase()) != -1);
        setItems(result);
    }

    const onSwitchToWarrantyRoute = () => {
        history.push(endPoint.DASHBOARD_WARRANTY_LIST + param?.companyId)
    }
    const onSwitchToEditPageRoute = (e, projectId) => {
        e.stopPropagation();
        history.push(endPoint.DASHBOARD_PROJECT_HOME_URL_EDIT + companyId + "/" + projectId)
    }
    if (error) {
        return <ErrorHandling error={error} />;
    }
    return (
        <div className="dashBoard-container">
            <div className="dashBoard--container dash-container">
                <div className="dashboard-heading-container">
                    {isLogo && <Logo className="logo" />}
                </div>
            </div>
            <div className="table-container">
                <div className="above-table-container">
                    <div className="dashBoard-container-input">
                        <i className="bi bi-search" ></i>
                        <input
                            className="dashboard-input-search"
                            type="search"
                            id="myInput"
                            onChange={searchItem}
                            placeholder="Search for Products" />
                    </div>
                    <button className="button-create-project btn" onClick={switchToCreatPost}>
                        <span className='button-create-project-text'>Create New Product</span>
                    </button>
                </div>
                <Table
                    listItems={listItems}
                    onhandleDeleteProductInfo={onhandleDeleteProductInfo}
                    id={id}
                    projectConfirmation={projectConfirmation}
                    show={show}
                    isSortColorProjectNameCarat={isSortColorProjectNameCarat}
                    isSortProjectNameCaratInReverse={isSortProjectNameCaratInReverse}

                    isSortLastActivityCaratInReverse={isSortLastActivityCaratInReverse}
                    isSortLastActivityCarat={isSortLastActivityCarat}

                    isSortDateCarat={isSortDateCarat}
                    isSortDateCaratInReverse={isSortDateCaratInReverse}
                    isSortTotalUnitCara={isSortTotalUnitCara}
                    totalUnitExist={totalUnitExist}
                    onhandleClose={onhandleClose}
                    onhandleShow={onhandleShow}
                    onhandleId={handleId}
                    onhandleSortDate={onhandleSortDate}
                    onhandleSortLastActivty={onhandleSortLastActivty}
                    onhandleSortProjectName={onhandleSortProjectName}
                    onSwitchToEditPageRoute={onSwitchToEditPageRoute}
                    onhandleSortTotalUnit={onhandleSortTotalUnit}
                    isLoading={isLoading}
                />
            </div>

            {/* <Modal show={showSuccess} onHide={onhandleShowSuccess} className="dashboard-modal-container">
                <Modal.Header className="dashboard-modal-header" closeButton>
                    <Modal.Title>
                        {!isDeletedSuccess ? " Delete successful." : "Delete unsucessfuly"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className="dashboard-modal-footer">
                    <Button variant="success" size="lg" onClick={onhandleCloseSuccces}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}

        </div>
    )
}

export default DashBoard;
