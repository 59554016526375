import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown, DropdownButton, Pagination } from 'react-bootstrap'

import { useContext } from 'react';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import { getAnalyticsRoot } from '../../../nav/AnalyticsLink';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { saveAs } from "file-saver";
import './ConsumerWarranty.css'
import axios from 'axios'
import ProductInfoUtil from '../../../../util/ProductInfoUtil'
import ConsumerDetailRollupLogList from '../ConsumerDetailRollup/ConsumerDetailRollupLogList';
import { URL } from '../../../../fakeUserId'
import ReactRoute from '../../../../ENDPOINT/react-endpoints/endPoint'
import WarrantAppEndPoint from '../../../../ENDPOINT/back-end-endpoint/WarrantAppEndPoint'
import MailChimpEndPoint from '../../../../ENDPOINT/back-end-endpoint/MailChimpEndPoint';
import BackEndPoint from '../../../../ENDPOINT/back-end-endpoint/endPoint';
import { useDispatch } from 'react-redux';
import { setProductInfoData } from '../../../../redux/action/ConsumerAction';
import { ConsumerTypes } from '../../../../redux/constants/ConsumerType';
import ErrorHandling from '../../../errorHandling/error';
import NumberInput from '../NumberInput';

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faChartColumn, faFilter, faCog, faX, faUpload } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import ConsumerWarrantyMailChimp from './ConsumerWarrantyMailChimp';


const ConsumerWarranty = () => {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');
    const PRODUCT = "Product",
        SKU = "SKU",
        STATE = "State"

    const [weeklyUsers, setWeeklyUsers] = useState(0);
    const [monthlyUsers, setMonthlyUsers] = useState(0);
    const [overAllUsers, setOverAllUsers] = useState(0);
    const [zipCode, setZipCode] = useState("");
    const [projectName, setProjectName] = useState(PRODUCT);
    const [projectId, setProjectId] = useState("");
    const [projectNameList, setProjectNameList] = useState([]);

    const [modalUserName, setModalUserName] = useState("");

    const [modalWarrantUserId, setModalWarrantUserId] = useState("");
    const [warrantyUserList, setWarrantyUserList] = useState([]);
    const [defaultWarrantyUserList, setDefaultWarrantyUserList] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showEllipseModal, setShowEllipseModal] = useState(false);
    const [skusItem, setSkusItem] = useState(query.get("skus") === null ? "SKU" : query.get("skus"));
    const [currentSerialNumber, setCurrentSerialNumber] = useState(null);
    const [enteredPage, setEnteredPage] = useState("");


    const [filterWarrantyUserList, setFilterWarrantyUserList] = useState([]);
    const [show, setShow] = useState(false);
    const [showAudienceId, setShowAudienceId] = useState(false);
    const [generateSerialNumber, setGenerateSerialNumber] = useState("");
    const [validateInput, setValidateInput] = useState(true);
    const [validateInputDsn, setValidDateInputDsn] = useState(true);
    // const [selectedFile, setSelectedFile] = useState([]);

    const [fileName, setFileName] = useState("Upload");
    const [stopIntervalFetching, setStopIntervalFetching] = useState(true);
    const [timeOutReact, setTimeOutReact] = useState(true);
    const [isfetchingApi, setIsFetchingApi] = useState(true);

    const [showGenerateUrl, setShowGenerateUrl] = useState(false);
    const [showGenerateUrlWithBarCode, setShowGenerateUrlWithBarCode] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [sku, setsku] = useState(SKU);
    const [skuList, setSkuList] = useState([]);
    const [serialNumber, setserialNumber] = useState("");
    const [state, setState] = useState(STATE);
    const [stateListData, setStateListData] = useState([]);

    const [mailChimpApiKey, setMailChimpApiKey] = useState("");
    const [mailChimpServer, setMailChimpServer] = useState("");
    const [mailChimpAudienceId, setMailChimpAudienceId] = useState("");

    const [mailChimpLoading, setMailChimpLoading] = useState(true);
    const [mailChimpList, setMailChimpList] = useState([{
        audience_id: "MailChimp Account"
    }]);
    const [mailChimpAccount, setMailChimpAccount] = useState("MailChimp Account");
    const [modalAudienceId, setModalAudienceId] = useState("");

    const [apiKeyErrorMessage, setApiKeyErrorMessage] = useState("");
    const [audienceIdErrorMessage, setAudienceIdErrorMessage] = useState("");
    const [serverErrorMessage, setServerErrorMessage] = useState("");
    const [error, setError] = useState(null);

    const [switchComponentBoolean, setSwitchComponentBoolean] = useState(false);

    const [loading, setLoading] = useState(true);
    const [uploadedLoading, setuploadedLoading] = useState(true);
    const [deletedAudienceIdLoading, setDeletedAudienceIdLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(true);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [pageNumber, setPageNumber] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isWarrantyUserLimit, setIsWarrantyUserLimit] = useState(false);

    const [showSetting, setShowSetting] = useState(false);
    const [pageSize, setPageSize] = useState(12);

    const [isDefaultGet, setIsDefaultGet] = useState(true);

    const [showFilters, setShowFilters] = useState(false);
    const [dateInputType, setDateInputType] = useState({ start: "text", end: "text" });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [chunks, setChunks] = useState([1]);

    // useEffect(() => {
    //     setIsFetchingApi(false);
    //     apiGetFilterItem(currentPage);
    // }, [sku, state, projectName, startDate, endDate, currentPage, name, email, serialNumber, zipCode, pageSize]);

    useEffect(() => {
        setChunks(Array.from({ length: Math.ceil(overAllUsers / 10000) }, (_, i) => i + 1));
    }, [overAllUsers]);

    useEffect(() => {
        setIsFetchingApi(false);
        apiGetFilterItem(currentPage);
    }, [currentPage, pageSize]);


    window.onload = function () {
        setIsFetchingApi(false);
        apiGetFilterItem(currentPage);
    }

    const param = useParams();
    const companyId = param?.companyId;

    const paramId = "ac5d7695-7c45-4e17-8db5-4a9a5aa728af"
    const FAKE_SERIAL_NUMBER = "GB21JU04125504J5"

    const URL_WITHOUT_serialNumber_WITH_USER_ADMIN = URL + companyId
    //const URL_WITH_BAR_CODE = URL + "barcode/" + FAKE_SERIAL_NUMBER + "/" + companyId;
    const URL_WITH_BAR_CODE = URL + "barcode/";
    const PROJECT_OVER_VIEW = "< PROJECT_DASHBOARD";
    //const WARRANTLIST_USER_WITHOUT_serialNumber = ""
    //my account in locals
    // const fakeUserId = "9d8c83af923d4300b9cb389e3f222ab3"
    //darayuth hang account in aws develpoment
    const message = {
        firstName: "First Name cannot be empty.",
        lastName: "Last Name cannot be empty.",
        email: "Email cannot be empty.",
        zipCode: "Zip Code cannot be empty.",
        sku: "SKU cannot be empty.",
        dsn: "Serial Number must start with G and contain 16 characters.",
        serialNumber: "Serial Number cannot be empty.",
        dsnDoesNotExist: "Serial Number does not exist"
    }
    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
    }

    let startDateQuery = query.get("start_date");
    let endDateQuery = query.get("end_date");
    let skusItemQuery = query.get("skus");

    const SKUS_EXIST = skusItemQuery !== "SKU" && skusItemQuery !== null;


    useEffect(() => {


        setLoading(false)

        if (!isfetchingApi) {
            setIsFetchingApi(true);
        } else {
            if (isDefaultGet) {
                setPageNumber();
                setIsDefaultGet(false);
            }
        }

        return () => {
            clearInterval(stopIntervalFetching);
            clearTimeout(timeOutReact)
        };
    }, [isfetchingApi])

    window.addEventListener('resize', () => {
        setScreenWidth(window.innerWidth);
    });

    const handleToggle = (column) => {
        if (screenWidth < 768) {
            if (selectedColumn === column) {
                setSelectedColumn('');
            } else {
                setSelectedColumn(column);
            }
        }
    };
    
    const onhandleGoBack = () => {
        history.push(ReactRoute.DASHBOARD_HOME_URL + companyId)
    }
    const handleClose = () => setShow(false);
    const handleShow = (name, userWarrantListId) => {
        setModalUserName(name)
        setModalWarrantUserId(userWarrantListId)
        setShow(true)
    };
    const onhandleChangeName = (e) => {
        setName(e.target.value);
    };
    const onhandleChangeEmail = (e) => {
        setEmail(e.target.value)
        // setIsFetchingApi(false);
    };
    const onhandleChangesku = (e) => setsku(e.target.value);
    const onhandleChangeserialNumber = (e) => {
        setserialNumber(e.target.value)
        // setIsFetchingApi(false);
    };
    const onhandleChangeZipCode = (e) => {
        setZipCode(e.target.value)
        // setIsFetchingApi(false);
    };
    const onhandleCloseGenerateUrl = (e) => {
        onhandleResetErrorToDefault();
        setShowGenerateUrl(false)
    }
    const onhandleDownloadDataIntoCsv = (columnList = [], range) => {
        const HEADER = ["Date", "User Name", 'Email Address', 'Zip', "phone", 'SKU', 'UPC', 'Serial Number']
        let rows = [
            HEADER,
        ];
        let csvContent = ""
        if (columnList.length > 0) {
            for (let data of columnList) {
                let firstName = data?.first_name + " " + data?.last_name
                rows.push([
                    ProductInfoUtil.getCurrentDate(data?.w_created_at),
                    firstName,
                    data?.email,
                    data?.zip_code,
                    data?.phone_number,
                    data?.final_sku,
                    data?.upc_code,
                    data?.serial_number
                ])

            }
        }
        rows.forEach(function (rowArray) {
            csvContent += rowArray + "\n";
        });

        const fileType = { type: 'text/csv;charset=utf-8' }
        const blob1 = new Blob([csvContent], fileType)
        const fileName = `WARRANTY_USER_LIST_${range}.csv`;
        const url = window.URL.createObjectURL(blob1);
        saveAs(
            url,
            fileName
        )
    }
    const handleDownloadSelect = async (pageNumber) => {
        let url =
            `${WarrantAppEndPoint.WARRANTY_END_POINT}${companyId}?serial_number=${serialNumber}
            &name=${name}
            &email=${email}
            &sku=${sku}
            &zipCode=${zipCode}&state=${state}
            &projectName=${projectName}
            &projectId=${projectId}
            &startDate=${startDate}
            &endDate=${endDate}
            &page=${pageNumber}
            &limit=${10000}`//maximum number of entries in CSV
        try {
            let apiResponse = await axios.get(url, header);
            const data = apiResponse?.data?.data
            const range = `${(pageNumber - 1) * 10000 + 1}-${pageNumber * 10000}`;
            onhandleDownloadDataIntoCsv(data?.userList, range);
        } catch (error) {
            console.log(error.message);
        }
    }
    const onhandleSearchForSerialNumber = (e, data) => {
        e.preventDefault();
        setCurrentSerialNumber(data);
        apiSearchForSerialNumber(data);
    }

    const onClickDropDownSku = (e, index, val) => {
        setsku(val);
        // setIsFetchingApi(false);
    }

    const onClickDropState = (e, index = 0, val) => {
        setState(val);
        // setIsFetchingApi(false);
    }

    const onClickDropProject = (e, index, val) => {

        setProjectName(val)
        setProjectId(index)
        //once product is selected, we need to filter the SKU list to match the product
        //getSKUListByProduct(index);
        // setIsFetchingApi(false);
    }

    const onFocusDateInput = (type) => {
        setDateInputType(prev => ({ ...prev, [type]: 'date' }));
    }

    const handleEllipseClick = () => {
        setShowEllipseModal(true);
    }
    const calcPagesToShow = () => {
        let start = Math.max(currentPage - 2, 1);
        let end = Math.min(start + 4, Math.ceil(overAllUsers / pageSize));
        start = Math.max(end - 4, 1);
        let pageList = Array.from({ length: end - start + 1 }, (_, i) => start + i);
        if (currentPage > 3 && Math.ceil(overAllUsers / pageSize) > 5) {
            pageList.unshift(1);
        }
        return pageList;
    }
    const handleModalClose = () => {
        setShowEllipseModal(false);
    }
    const handlePageSubmit = () => {
        let newPage = Math.max(1, Math.min(parseInt(enteredPage), Math.ceil(overAllUsers / pageSize)));
        setCurrentPage(newPage);
        setShowEllipseModal(false);
    }

    // const getSKUListByProduct = async (index) => {
    //     let apiResponse = await ConsumerController.fetchDetailByIdWithoutLogItem(index, token);
    //     //fetch data success
    //     if (apiResponse?.status === 200) {
    //         setSkuList(apiResponse?.data.skus);
    //         console.log(apiResponse?.data.skus);
    //     } else {
    //         console.log("fetch failed");
    //     }
    // }

    const breadCrumbs = [
        // {
        //     link: ReactRoute.DASHBOARD_COMPANY,
        //     text: "Companies",
        //     separator: ">"

        // }, 
        {
            link: "",
            text: "Search Warranties",
            separator: ""
        }
    ]

    const { setBreadcrumbs } = useContext(BreadcrumbContext);
    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, []);

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }
    const isInputEmpty = () => {
        return !name && !email &&
            !sku && !serialNumber &&
            !zipCode && !state
            && !startDate && !endDate
    }
    const isInput = () => {
        return startDate || endDate
            || name || email || serialNumber
            || zipCode || sku !== SKU || state !== STATE
            || projectName !== PRODUCT
    }
    const pageNumberBaseonData = (overAll) => {
        let sizeOfPage = pageSize;
        let lengthOfData = Math.ceil(overAll / sizeOfPage);

        if (currentPage > lengthOfData) {
            setCurrentPage(lengthOfData);
        }

        return Array.from({ length: lengthOfData }, (v, k) => k + 1);
    }
    const togglePagination = (overAll) => {
        if (overAll > pageSize) {//show pagination
            setIsWarrantyUserLimit(true)
        } else if (overAll < pageSize) { // do not show pagination
            setIsWarrantyUserLimit(false);
        }
    }
    /**
     * 
     * @API
     */
    const apiDeleteWarrantyUser = async () => {
        const endPoint = WarrantAppEndPoint.WARRANTY_END_POINT + companyId + "/" +
            modalWarrantUserId

        try {
            let apiResponse = await axios.delete(
                endPoint, header)
            setLoading(false);
            let testEndPoint = ""
            testEndPoint = WarrantAppEndPoint.WARRANTY_END_POINT + companyId + "?" + "page=" + currentPage + "&limit=" + pageSize
            apiGetFilterItem(currentPage);
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }
    }
    const fetchData = async (endPoint = WarrantAppEndPoint.WARRANTY_END_POINT + companyId + "?" + "page=" + pageNumber + "&limit=" + pageSize) => {
        //console.log(pageNumber, pageSize)
        if (isDefaultGet) {
            endPoint = WarrantAppEndPoint.WARRANTY_END_POINT + companyId + "?" + "page=" + [1] + "&limit=" + pageSize;
        }
        try {
            //this user be user from param.
            let apiResponse = await axios.get(endPoint, header);
            const data = apiResponse?.data?.data;
            console.log(apiResponse?.data?.data);
            let overAll = parseInt(data?.overAll)
            setStateListData(data?.stateList)
            togglePagination(overAll);
            //empty be default
            //empty because of user upload
            setProjectNameList(data?.projectList)
            setSkuList(data?.skuList);
            setLoading(true);
            if (data?.mailChimpList) {
                const newArray = [
                    ...data?.mailChimpList,
                    mailChimpList[0]
                ]
                setMailChimpList(newArray)
            }
            setPageNumber(pageNumberBaseonData(overAll));
            setWeeklyUsers(parseInt(data?.week));
            setMonthlyUsers(parseInt(data?.month));
            setOverAllUsers(parseInt(overAll));
            setWarrantyUserList(data?.userList);
            setFilterWarrantyUserList(data?.userList);
            setDefaultWarrantyUserList(data?.userList);
            setMailChimpAccount(data?.mailChimpAudienceId ? data?.mailChimpAudienceId : "MailChimp Account");
        } catch (error) {
            setLoading(true);
            console.log(error.message);
            setError(error?.message);
        }
    }
    const apiGetFilterItem = async (numberOfPage = "") => {

        let removeWhiteSpaceName = name.replace(/\s+/g, '')
        let url =
            `${WarrantAppEndPoint.WARRANTY_END_POINT}${companyId}?serial_number=${serialNumber}
                &name=${name}
                &email=${email}
                &sku=${sku}
                &zipCode=${zipCode}&state=${state}
                &projectName=${projectName}
                &projectId=${projectId}
                &startDate=${startDate}
                &endDate=${endDate}
                &page=${1}
                &limit=${pageSize}`
        if (numberOfPage) {
            url =
                `${WarrantAppEndPoint.WARRANTY_END_POINT}${companyId}?serial_number=${serialNumber}
                &name=${name}
                &email=${email}
                &sku=${sku}
                &zipCode=${zipCode}&state=${state}
                &projectName=${projectName}
                &projectId=${projectId}
                &startDate=${startDate}
                &endDate=${endDate}
                &page=${numberOfPage}
                &limit=${pageSize}`
        }
        if (isInputEmpty()) {
            //remove white space
        } else {
            try {
                let apiResponse = await axios.get(url, header);
                const data = apiResponse?.data?.data
                let overAll = parseInt(data?.overAll)
                setLoading(true)
                setFilterWarrantyUserList(data?.userList)
                togglePagination(overAll)
                setWeeklyUsers(parseInt(data?.week))
                setMonthlyUsers(parseInt(data?.month))
                setOverAllUsers(parseInt(data?.overAll));
                setPageNumber(pageNumberBaseonData(overAll));
                setStateListData(data?.stateList);
                setProjectNameList(data?.projectList);
                setSkuList(data?.skuList);
            } catch (error) {
                console.log(error.message);
                setLoading(true)
                setError(error?.message);
            }
        }
    }
    const onhandleDelete = () => {
        setShow(false);
        apiDeleteWarrantyUser();
    }
    const onhandleSubmitFilter = async (e) => {
        e?.preventDefault();
        setLoading(false);
        // clearInterval(stopIntervalFetching)
        apiGetFilterItem(currentPage);
    }
    const apiSearchForSerialNumber = async (serialNumberParam) => {
        const data = {
            serialNumber: serialNumberParam,
            companyId
        }
        try {
            setModalLoading(true);
            setShowModal(true);
            let apiResponse = await axios.post(WarrantAppEndPoint.WARRANTY_SEARCH_FOR_SERIAL_NUMBER + companyId, data, header);
            const projectIdApi = apiResponse?.data?.data?.product_info_id;
            const serialNumberApi = apiResponse?.data?.data?.dsn;
            //history.push(ReactRoute.DASHBOARD_ROLLUP_URL + projectIdApi + "/" + serialNumberApi)
            //window.open(window.location.origin.toString() + ReactRoute.DASHBOARD_ROLLUP_URL + projectIdApi + "/" + serialNumberApi);
            fetchDataWithDsn(projectIdApi, serialNumberApi);
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }
    }
    const onhandleUploadFile = async (e) => {
        // e.preventDefault();
        // console.log("onhandleUploadFile");
        // setFileName(e.target.files[0]?.name)

        const selectedFile = e.target.files
        // if (selectedFile.length === 0) {
        //     alert("Please select the file first before upload. ")
        // } else {
        let formData = new FormData();
        formData.append("product-info", selectedFile[0])
        const header = {
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
                "authorization": `Bearer ${token}`
            }
        }
        setuploadedLoading(false);

        // setLoading(true);
        try {
            let apiUploadResponse = await axios.post(WarrantAppEndPoint.WARRANTY_END_POINT_UPLOAD + companyId, formData, header)
            setuploadedLoading(true);
            setIsFetchingApi(false);
            apiGetFilterItem(currentPage);
            // setFileName("Upload")
            // setisUploaded(false);
            // setLoading(false)
        } catch (error) {
            setuploadedLoading(true);
            // setLoading(false)
            const matchingColumnError = error.response.data?.error?.matchingColumn
            const allColumnExistInMailChimp = error.response.data?.error?.columnExist
            if (matchingColumnError) {
                console.log(matchingColumnError);
                setError(matchingColumnError);
            } else if (allColumnExistInMailChimp) {
                console.log(allColumnExistInMailChimp);
                setError(allColumnExistInMailChimp);
            }
        }
        e.target.value = null; //clear input to trigger react rendering.
        //}
    }
    const integrateMailChimpApi = async (e) => {
        e.preventDefault();
        setMailChimpLoading(false);
        const END_POINT = WarrantAppEndPoint.WARRANTY_MAIL_CHIMP;
        const data = {
            companyId,
            mailChimpApiKey,
            mailChimpServer,
            mailChimpAudienceId
            //audience id 
        }
        try {
            let apiResponse = await axios.post(END_POINT, data, header)
            setMailChimpLoading(true);
            setSwitchComponentBoolean(false);
            onhandleResetErrorToDefault();
            // setShowGenerateUrl(false)
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
            if (error.response.data?.error?.apiKey) {
                setApiKeyErrorMessage(error.response.data?.error?.apiKey)
            }
            if (error.response.data?.error?.audienceId) {
                setAudienceIdErrorMessage(error.response.data?.error?.audienceId)
            }
            if (error.response.data?.error?.server) {
                setServerErrorMessage(error.response.data?.error?.server)
            }
            setMailChimpLoading(true);
        }
    }
    const apiUpdateMailChimp = async (id) => {
        const endPoint = MailChimpEndPoint.MAIL_CHIMP + companyId;
        const data = {
            mailChimpId: id,
            isActive: true
        }
        try {
            await axios.put(endPoint, data, header)
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }
    }
    ///api/v1/mail-chimp/:companyId/:audienceId
    const apiDeleteMailChimp = async (audienceId) => {
        setDeletedAudienceIdLoading(false)
        const endPoint = MailChimpEndPoint.MAIL_CHIMP + companyId + "/" + audienceId;
        try {
            await axios.delete(endPoint, header)
            // let newArray = [...isWarrantyUserLimit];
            // newArray.splice(0, 1)
            // setIsWarrantyUserLimit(newArray);
            setDeletedAudienceIdLoading(true)
        } catch (error) {
            setDeletedAudienceIdLoading(true)
            console.log(error.message);
        }
    }
    const fetchDataWithDsn = async (projectIdApi, dsnParam) => {
        // let apiResponse = await ConsumerController.fetchDetailById(param?.companyId, accessToken);
        try {
            //axios.post accepts two parameters: (URL, Data);
            let apiResponse = await axios.get(`${BackEndPoint.DASHBOARD_DETAIL_URL_ID}${param?.companyId}/${projectIdApi}/${dsnParam}?page=1`, { headers: { "authorization": `Bearer ${token}` } });
            if (apiResponse?.status === 200) {
                const projectNameApi = apiResponse?.data?.productInfo[0]?.project_name;
                localStorage.setItem("projectName", projectNameApi)
                dispatch(setProductInfoData(apiResponse?.data));
                dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: apiResponse?.data?.productionLogItems })
                setModalLoading(false);
            }

        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }

    }

    /**
     * @ONHANDLE @FUNCTIONS
     */
    // const onhandleChangeFileHandler = (e) => {
    //     setFileName(e.target.files[0]?.name)
    //     setSelectedFile(e.target.files)
    // }

    const onhandleResetAllFilterInputToDefault = (e) => {
        setsku("SKU")
        setName("")
        setserialNumber("");
        setEmail("")
        setZipCode("");
        setState("State")
        setProjectName("Product")
        setFileName("Upload")
        setStartDate("");
        setEndDate("");
        setCurrentPage(1);
        // apiGetFilterItem(1);
    }

    const onhandleChangeMailChimpApiKey = (e) => {
        setMailChimpApiKey(e.target.value)
        onhandleResetErrorToDefault();
    };
    const onhanldeChangeMailChimpServer = (e) => {
        setMailChimpServer(e.target.value)
        onhandleResetErrorToDefault();
    };
    const onhandleChangeMailChimpAudienceId = (e) => {
        setMailChimpAudienceId(e.target.value)
        onhandleResetErrorToDefault();
    };
    const onhandleSwitchScreen = (e) => {
        setShowSetting(false);
        setSwitchComponentBoolean(true)
    };
    const onhandleClosedSwitchScreen = (e) => {
        setSwitchComponentBoolean(false)
        // setStopIntervalFetching(setInterval(() => {
        //     fetchData();
        // }, 2000));
    };
    const onhandleResetErrorToDefault = (e) => {
        setApiKeyErrorMessage("");
        setAudienceIdErrorMessage("");
        setServerErrorMessage("")
    }

    const onClickDropDownMailChimp = (e, id, audienceId) => {
        e.preventDefault();
        setMailChimpAccount(audienceId)
        apiUpdateMailChimp(id)
        // localStorage.setItem("mailChimpId", id);
        //update they key id with temporrary
    }
    const onClickDropDownDeleteMailChimp = (e, audienceId) => {
        setShowAudienceId(false);
        apiDeleteMailChimp(audienceId);
        // e.stopPropagation();
    }
    const onhandleShowAudienceId = (e, audienceId) => {
        e.stopPropagation();
        setModalAudienceId(audienceId)
        setShowAudienceId(true);
    }
    const onhandleCloseAudienceId = (e) => {
        setShowAudienceId(false);
    }
    const onhandleChangeStartDate = (e) => {
        setStartDate(e.target.value)
        // setIsFetchingApi(false);
    }
    const onhandleChangeEndDate = (e) => {
        setEndDate(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleEllipse = () => {
        setPageNumber([...pageNumber, pageNumber.length + 1])
    }
    const onhandleNext = () => {
        let nextPageNumber = Math.min(currentPage + 1, pageNumber.length);
        setCurrentPage(nextPageNumber);
        // apiGetFilterItem(nextPageNumber);
    }
    const onhandlePrev = () => {
        let prevPageNumber = Math.max(currentPage - 1, 1);
        setCurrentPage(prevPageNumber);
        // apiGetFilterItem(prevPageNumber);
    }
    const onhandleSwitchPage = (e, newPage) => {

        e.nativeEvent.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        clearInterval(stopIntervalFetching);
        setCurrentPage(newPage);

        // apiGetFilterItem(newPage);


    }
    const onhandleSaveChangeSetting = () => {
        setShowSetting(false);
        // clearInterval(stopIntervalFetching);
        setLoading(false)
        let endPoint = WarrantAppEndPoint.WARRANTY_END_POINT + companyId + "?" + "page=" + pageNumber + "&limit=" + pageSize;
        apiGetFilterItem(currentPage);
    }
    const onhandleCloseSetting = () => {
        setShowSetting(false);
    }
    const onhandleShowSetting = () => {
        setShowSetting(true);
    }
    const onhandleChangePageSize = (input) => {
        setPageSize(input);
    }

    if (error) {
        return <ErrorHandling error={error} />;
    }

    return (

        <div className="mainContent">

            <Modal show={showAudienceId} onHide={onhandleCloseAudienceId} >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Account id</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {modalAudienceId} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onhandleCloseAudienceId}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={(e) => onClickDropDownDeleteMailChimp(e, modalAudienceId)}>
                        {deletedAudienceIdLoading ? "Delete" : <LoadingSpinner />}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {modalUserName} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={onhandleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showGenerateUrl} onHide={onhandleCloseGenerateUrl}>
                <form onSubmit={integrateMailChimpApi}>
                    <Modal.Header>
                        <Modal.Title>
                            <input type="text"
                                placeholder='server'
                                onChange={onhanldeChangeMailChimpServer}
                            />
                            {serverErrorMessage && <div className='warranty-list--error-message'>{serverErrorMessage}</div>}
                            <input type="text" placeholder='apiKey'
                                onChange={onhandleChangeMailChimpApiKey}
                            />
                            {apiKeyErrorMessage && <div className='warranty-list--error-message'>{apiKeyErrorMessage}</div>}
                            <input type="text" placeholder='audienceID'
                                onChange={onhandleChangeMailChimpAudienceId}
                            />
                            {audienceIdErrorMessage && <div className='warranty-list--error-message'>{audienceIdErrorMessage}</div>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onhandleCloseGenerateUrl}>
                            Close
                        </Button>
                        <button className='btn btn-success'>Submit</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showSetting} onHide={onhandleCloseSetting}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='warranty-list-settings--list'>
                        <div>
                            {mailChimpLoading ?
                                <button
                                    className='warranty-list--integrate-mail-chimp btn btn-info'
                                    onClick={onhandleSwitchScreen}
                                >
                                    Integrate MailChimp
                                </button>
                                :
                                <LoadingSpinner />
                            }
                        </div>
                        <div>
                            <DropdownButton id="dropdown-basic-button" title={`${pageSize} Results Per Page`}
                                className='consumer-warranty-number-of-files-per--page'
                            >
                                <Dropdown.Item href="#/action-1" onClick={(e) => onhandleChangePageSize(12)}>12</Dropdown.Item>
                                <Dropdown.Item href="#/action-2" onClick={(e) => onhandleChangePageSize(25)}>25</Dropdown.Item>
                                <Dropdown.Item href="#/action-3" onClick={(e) => onhandleChangePageSize(50)}>50</Dropdown.Item>
                            </DropdownButton>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onhandleCloseSetting}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={onhandleSaveChangeSetting}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} id={'serial-number-modal'} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Serial Number Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-summary">
                        <ConsumerDetailRollupLogList
                            startDateQuery={startDateQuery}
                            endDateQuery={endDateQuery}
                            skusItem={skusItem}
                            paramId={param?.companyId}
                            SKUS_EXIST={SKUS_EXIST}
                            currentSerialNumber={currentSerialNumber}
                            paramCompanyId={param?.companyId}
                            loading={modalLoading}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showEllipseModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Page Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NumberInput enteredValue={enteredPage} setEnteredValue={setEnteredPage} onHandleSubmit={handlePageSubmit}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>Close</Button>
                    <Button variant="primary" onClick={handlePageSubmit}>Go to page</Button>
                </Modal.Footer>
            </Modal>
            
            <div className={`warranty-list-user--container ${switchComponentBoolean ? "bg" : ""}`}>
                {switchComponentBoolean ?
                    <ConsumerWarrantyMailChimp
                        onhanldeChangeMailChimpServer={onhanldeChangeMailChimpServer}
                        onhandleChangeMailChimpApiKey={onhandleChangeMailChimpApiKey}
                        onhandleChangeMailChimpAudienceId={onhandleChangeMailChimpAudienceId}
                        serverErrorMessage={serverErrorMessage}
                        apiKeyErrorMessage={apiKeyErrorMessage}
                        audienceIdErrorMessage={audienceIdErrorMessage}
                        integrateMailChimpApi={integrateMailChimpApi}
                        onhandleClosedSwitchScreen={onhandleClosedSwitchScreen}
                        mailChimpLoading={mailChimpLoading}
                        stopIntervalFetching={stopIntervalFetching}
                        header={header}
                    />
                    :   
                    <>
                        <div className='warranty-list--container-table-header drop-shadow'>
                            <div className='warranty-list-wrapper--week-month-all-time'>
                                <div className='warranty-list-container--week-month-all-time'>
                                    <div className='warranty-list-all-time--box'>
                                        <div className='warranty-list-week-box--week-all-time'>Total</div>
                                        <div className='warranty-list-input--number'>{overAllUsers}</div>
                                    </div>
                                    <div className='warranty-list-week--box'>
                                        <div className='warranty-list-week-box--week-text'>new this week</div>
                                        <div className='warranty-list-input--number'>{weeklyUsers}</div>
                                    </div>
                                    <div className='warranty-list-month--box'>
                                        <div className='warranty-list-week-box--week-month'>new this month</div>
                                        <div className='warranty-list-input--number'>{monthlyUsers}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='warranty-list-filter-settings'>
                                <div className='warranty--export-button'>
                                    <label title="Click to upload waranty entries as CSV" className='warranty-list--upload btn'>
                                        <div className='warranty-list--upload-wrapped'>
                                            <input
                                                type="file"
                                                className='warranty-list-upload--input'
                                                onChange={onhandleUploadFile}
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            />
                                            <FontAwesomeIcon 
                                                style={{ lineHeight: '1.5' }}
                                                icon={faUpload}
                                            />
                                            <div>
                                                {uploadedLoading ?

                                                    fileName
                                                    :
                                                    <LoadingSpinner />
                                                }
                                            </div>
                                        </div>

                                    </label>
                                    <Dropdown onSelect={handleDownloadSelect}>
                                        <Dropdown.Toggle title="Click to download filtered user warranties as CSV" className='warranty--export-table-button btn'>
                                            Export
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {chunks.map((chunk, index) => 
                                                <Dropdown.Item eventKey={index + 1} key={index}>
                                                    {`Download entries ${index * 10000 + 1} - ${(index + 1) * 10000}`}
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <FontAwesomeIcon 
                                    className="filter-button cursor-pointer icon-default-background" 
                                    icon={faFilter} 
                                    onClick={() => setShowFilters(prev => !prev)}
                                />
                                <FontAwesomeIcon
                                    className="warranty-list-setting cursor-pointer icon-default-background"
                                    onClick={onhandleShowSetting}
                                    icon={faCog}
                                />
                            </div>
                        </div>
                        <div className='warranty-list--container-table'>
                            <div className={`filter-boxes ${showFilters ? 'show' : ''}`}>
                                {
                                    <form
                                        className='warranty-list-container--filter'
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        onSubmit={onhandleSubmitFilter}>
                                        <div className='warranty-list-wrapp--drop-down-and-input'>
                                            <div className="warranty-vertical-list">
                                                <div className="narrow-cursor-pointer" onClick={() => handleToggle('dateCreated')}>
                                                    <h3>Date created</h3>
                                                    <hr/>
                                                </div>
                                                {(selectedColumn === 'dateCreated' || screenWidth >= 768) && (
                                                    <>
                                                        <div className='warranty-list-tuple'>
                                                            <div>
                                                                <input
                                                                    className="input-field"
                                                                    type={dateInputType.start}
                                                                    onFocus={() => onFocusDateInput('start')}
                                                                    onChange={onhandleChangeStartDate}
                                                                    value={startDate}
                                                                    placeholder={dateInputType.start === 'text' ? 'Start Date' : ''}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='warranty-list-tuple'>
                                                            <div>
                                                                <input
                                                                    className="input-field"
                                                                    type={dateInputType.end}
                                                                    onFocus={() => onFocusDateInput('end')}
                                                                    onChange={onhandleChangeEndDate}
                                                                    value={endDate}
                                                                    placeholder={dateInputType.end === 'text' ? 'End Date' : ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="warranty-vertical-list">
                                                <div className="narrow-cursor-pointer" onClick={() => handleToggle('userInfo')}>
                                                    <h3>User info</h3>
                                                    <hr/>
                                                </div>
                                                {(selectedColumn === 'userInfo' || screenWidth >= 768) && (
                                                    <>
                                                        <div className='warranty-list-tuple'>
                                                            <input
                                                                className="input-field"
                                                                type="text"
                                                                onChange={onhandleChangeName}
                                                                value={name}
                                                                placeholder='Name'
                                                            />
                                                        </div>
                                                        <div className='warranty-list-tuple'>
                                                            <input
                                                                className="input-field"
                                                                type="text"
                                                                onChange={onhandleChangeEmail}
                                                                value={email}
                                                                placeholder='Email'
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="warranty-vertical-list">
                                                <div className="narrow-cursor-pointer" onClick={() => handleToggle('location')}>
                                                    <h3>Location</h3>
                                                    <hr/>
                                                </div>
                                                {(selectedColumn === 'location' || screenWidth >= 768) && (
                                                    <>
                                                        <div className='warranty-list-tuple'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="warranty-button-dropdown" id="">
                                                                    {state}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                                    {stateListData.length > 0 && stateListData.map((val, index) =>
                                                                        <Dropdown.Item key={index}
                                                                            onClick={(e) => onClickDropState(e, index, val)}
                                                                        >
                                                                            {val}
                                                                        </Dropdown.Item>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className='warranty-list-tuple'>
                                                            <input
                                                                type="text"
                                                                className='warranty-list-zip-code--input input-field'
                                                                onChange={onhandleChangeZipCode}
                                                                value={zipCode}
                                                                placeholder='Zip Code'
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="warranty-vertical-list">
                                                <div className="narrow-cursor-pointer" onClick={() => handleToggle('productInfo')}>
                                                    <h3>Product info</h3>
                                                    <hr/>
                                                </div>
                                                {(selectedColumn === 'productInfo' || screenWidth >= 768) && (
                                                    <>
                                                        <div className='warranty-list-tuple'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="warranty-button-dropdown" id="dropdown-button-dark-example1">
                                                                    {projectName}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                                    {projectNameList.length > 0 &&
                                                                        projectNameList.map((val, index) =>
                                                                            <Dropdown.Item key={index}
                                                                                onClick={(e) => onClickDropProject(e, val?.id, val)}>
                                                                                {val}
                                                                            </Dropdown.Item>
                                                                        )
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className='warranty-list-tuple'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="warranty-button-dropdown">
                                                                    {sku?.toUpperCase()}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                                    {skuList.length > 0 && skuList.map((val, index) =>
                                                                        <Dropdown.Item key={index}
                                                                            onClick={(e) => onClickDropDownSku(e, index, val)}>
                                                                            {val?.toUpperCase()}
                                                                        </Dropdown.Item>
                                                                    )
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            {/* <input type="text" className='warranty-list-sku--input' onChange={onhandleChangesku}/> */}
                                                        </div>
                                                        <div className='warranty-list-tuple'>
                                                            <input
                                                                type="text"
                                                                className='warranty-list-serialNumber--input input-field'
                                                                onChange={onhandleChangeserialNumber}
                                                                value={serialNumber}
                                                                placeholder='Serial Number'
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="last-input">
                                                <div className="close-filters">
                                                    <FontAwesomeIcon
                                                        className="cursor-pointer icon-default-background"
                                                        onClick={() => setShowFilters(false)}
                                                        icon={faX}
                                                    />
                                                </div>
                                                <div className="search-reset">
                                                    <button className='warranty-button-list--filter btn' onClick={onhandleSubmitFilter} >
                                                        <span className='button-text'>Search</span>
                                                    </button>
                                                    <button className='warranty-button-list--reset btn' onClick={onhandleResetAllFilterInputToDefault} >
                                                        <span className='button-text'>Reset</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </form>
                                }
                            </div>
                            <div className={`table-container-fs`}>
                                <table className="table warranty-list-table ">
                                    <thead className="warranty-list-table--heading">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">Zip</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">SKU</th>
                                            <th scope="col">Serial Number</th>
                                            <th scope="col" className='text-end'>
                                                {/* <button className='warranty--export-table-button btn' onClick={
                                                isFilter ? (e) => onhandleDownloadDataIntoCsv(e, filterWarrantyUserList)
                                                    : (e) => onhandleDownloadDataIntoCsv(e, warrantyUserList)
                                            } >
                                                Export
                                            </button> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='warranty-list-table--body'>
                                        {loading ? 
                                            filterWarrantyUserList.length > 0 ?
                                                filterWarrantyUserList.map((val, index) =>
                                                    <tr key={val?.id + "_" + index} className="row-hover">
                                                        <td scope="row">{ProductInfoUtil.getCurrentDate(val?.w_created_at)}</td>
                                                        <td>{`${val?.first_name + " " + val?.last_name}`}</td>
                                                        <td>{val?.email}</td>
                                                        <td>{val?.zip_code}</td>
                                                        <td>
                                                            {formatPhoneNumber(val?.phone_number)}
                                                        </td>
                                                        <td>{val?.final_sku?.toUpperCase()}</td>
                                                        <td>
                                                            { val?.clickable_serial_number ?
                                                                <Link
                                                                    className="warranty-list-table--serial-number"
                                                                    title="Production details about this device"
                                                                    to="#"
                                                                    onClick={(e) => onhandleSearchForSerialNumber(e, val?.serial_number, val?.skus)}
                                                                >
                                                                    {val?.serial_number}
                                                                </Link>
                                                            :
                                                                val?.serial_number
                                                            }
                                                        </td>
                                                        <td className="text-end">
                                                            <div className="column-two-items">
                                                                <a title="Delete warranty entry" className="" onClick={() => handleShow(`${val?.first_name + " " + val?.last_name}`, val?.id)}>
                                                                    <FontAwesomeIcon className="icon-default-color icon-default-background" size="lg" icon={faTrash} />
                                                                </a>
                                                                <a title="View device in Analytics" className="" target="_blank" rel="noopener noreferrer" href={`${getAnalyticsRoot()}/index.html?dsn=${val?.serial_number}`} id={val?.serial_number}>
                                                                    <FontAwesomeIcon className="icon-default-color icon-default-background" size="lg" icon={faChartColumn} />
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : <tr key={"NORECORDS"} className="row-hover"><td>No records match.</td>{Array.from({ length: 7 }, (_, i) => <td key={i} />)}</tr> :
                                            <tr className="warranty-list--loading-spinner">
                                                <td colSpan={8}>
                                                    <div className="spinner-border" role="status" />
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='warranty-list-footer drop-shadow'>
                            {isWarrantyUserLimit &&
                                <Pagination>
                                    <div className="pagination-organize">
                                        <Pagination.Prev onClick={onhandlePrev} disabled={currentPage === 1} />
                                        {
                                            calcPagesToShow().map(page =>
                                                <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>{page}</Pagination.Item>
                                            )
                                        }
                                        {Math.ceil(overAllUsers / pageSize) > 5 && <Pagination.Ellipsis onClick={handleEllipseClick} />}
                                        {Math.ceil(overAllUsers / pageSize) > 5 && currentPage < Math.ceil(overAllUsers / pageSize) - 2 && <Pagination.Item onClick={() => setCurrentPage(Math.ceil(overAllUsers / pageSize))}>{Math.ceil(overAllUsers / pageSize)}</Pagination.Item> }
                                        <Pagination.Next onClick={onhandleNext} disabled={currentPage === Math.ceil(overAllUsers / pageSize)} />
                                    </div>
                                </Pagination>
                            }
                        </div>
                    </>
                }


            </div>

        </div>
    );




};

export default ConsumerWarranty;
