import React from 'react';
import './Sp3ForResetPass.css';
const Sp3ForResetPass = () => {
    return (
        <img 
            src="/logo2-a.svg"
            alt="Transparent Holios Logo White" className="holios-header"
        />
    );
};

export default Sp3ForResetPass;
