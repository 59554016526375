import React from 'react';
import { useEffect, useContext } from 'react';
import { BreadcrumbContext } from '../nav/BreadcrumbContext';
import './error.css';

const ErrorHandling = ({error}) => {
    const { setLeftNav } = useContext(BreadcrumbContext);

    useEffect(() => {
        setLeftNav(false);        
        // reset leftNav to true when the component unmounts
        return () => setLeftNav(true);
    }, []);

    if (!error) {
        error = "404 not found"
    }

    return (
        <div className = "mainContent">
            <div className="offset-4 col-4">
                <div className="error-card card">
                    <div className="error-card-body card-body text-center"><p>{error}</p></div>
                </div>
            </div>
        </div>
    );
}
export default ErrorHandling;
