import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { DownloadSourceFile, UpdateProductionLog, filterProductionLogData } from '../../../../redux/action/ConsumerAction';

import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import ConsumerContentHeading from '../ConsumerContentHeading/ConsumerContentHeading';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';
import ConsumerNavigation from '../ConsumerNavigation/ConsumerNavigation';
import './ConsumerViewAddLog.css';
import { DeleteProductionLog, FetchProductionLog } from '../../../../redux/action/ConsumerAction';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import axios from 'axios';

import { saveAs } from "file-saver";
import { ConsumerTypes } from '../../../../redux/constants/ConsumerType';
import HelperUtil from '../../../../util/HelperUtil';
import Reprogramming from '../../../../util/Reprogramming';
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faClock, faCircleLeft } from '@fortawesome/free-solid-svg-icons';

const ROLL_UP = "< ROLL UP"
const ConsumerViewAddLog = () => {
    const param = useParams();
    const productionLogs = useSelector((state) => state.FetchProductionLogReducer.data);

    const loading = useSelector((state) => state.FetchProductionLogReducer.loading);
    const { success: isDeletedSuccess } = useSelector((state) => state.DeleteProductionLogReducer);
    const { success: isUpdatedSuccess, loading: isUpdateLoading } = useSelector((state) => state.UpdateProductionLogReducer);
    const dispatch = useDispatch();
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);
    const [logNameConfirmation, setLogNameConfirmation] = useState(null);

    const [edit, setEdit] = useState(true);
    const [refresh, setRefresh] = useState(true);

    const [isLogNameFilter, setIsLogNameFilter] = useState(true);
    const [isstartDateFilter, setIsStartDateFilter] = useState(true);
    const [issendDateFilter, setEndDateFilter] = useState(true);
    const [isTotalRowFilter, setIsTotalRowFilter] = useState(true);
    const [isAverageProgramingFilter, setIsAverageProgrammingFilter] = useState(true);
    const [isReprogrammingFilter, setIsReprogrammingFilter] = useState(true);


    let accessToken = localStorage.getItem("accessToken") || undefined;
    let projectName = localStorage.getItem("projectname") || undefined;
    let projectId = localStorage.getItem("projectId") || undefined;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const logId = queryParams.get('logId');

    // necessary when navigating from device search
    // the breadcrumb's product name is not stored without getting from the dashboard
    useEffect(() => {
        if (localStorage.getItem("projectname") === null) {
            const url = `http://localhost:3000/api/v1/dashboard/product-info/detail/without/alllogs/${param?.companyId}/${projectId}`;
            const header = {
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data',
                    "authorization": `Bearer ${accessToken}`
                }
            }
            axios.get(url, header).then((response) => {
                const data = response?.data;
                localStorage.setItem("projectName", data?.productInfo?.project_name);
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [localStorage.getItem("projectName")])

    useEffect(() => {
        if (isUpdatedSuccess) {
            dispatch(FetchProductionLog(param?.id, accessToken))
            dispatch({ type: ConsumerTypes.RESET_UPDATE_PRODUCTION_LOG_FILES_REQUEST })
            setEdit(true);
        }
        if (isDeletedSuccess) {
            dispatch(FetchProductionLog(param?.id, accessToken))
            dispatch({ type: ConsumerTypes.DELETE_PRODUCTION_LOG_RESET_SUCCESS })
            //set success to false
        } else {
            dispatch(FetchProductionLog(param?.id, accessToken))
        }
        //refresh to true so that when user press cancel button  it will return original data.
        if (!refresh) {
            setRefresh(true);
        }
    }, [refresh, isDeletedSuccess, isUpdatedSuccess]
    )
    const switchRoute = () => {
        const addLogEndPoint = `${endPoint.DASHBOARD_ADD_LOG}${param?.companyId}/${param?.id}`
        const currentEndPoint = "/dashboard/production/viewlog/" + param?.companyId + "/" + param?.id;
        history.push(addLogEndPoint, { prevRoute: currentEndPoint });
    }
    const onhandleShow = (e, rowId, name) => {
        e.stopPropagation();
        setShow(true)
        setId(rowId);
        setLogNameConfirmation(name);

    };
    const onhandleClose = () => {
        setShow(false);
    }
    const onhandleDeleteProductInfo = async (rowId) => {
        setShow(false)
        dispatch(DeleteProductionLog(rowId, accessToken))
    }
    const onhandleDownloadDataIntoCsv = (COLUMN = []) => {
        const HEADER = ["LOG NAME", "DESCRIPTION", "DATE", "TOTAL ROW", "AVERAGE PROGRAM TIME", "$ REPROGRAMMING"]
        const rows = [
            HEADER,
            COLUMN
        ];
        let csvContent = ""
        rows.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\n";
        });
        const fileType = { type: 'text/csv;charset=utf-8' }
        const blob1 = new Blob([csvContent], fileType)
        const fileName = COLUMN[0];
        const url = URL.createObjectURL(blob1);
        saveAs(
            url,
            fileName
        )
        // dispatch(DownloadProductionLog(rowId, accessToken))
    }
    const onhandledownloadFile = (s3Key, fileName, fileExtension) => {
        dispatch(DownloadSourceFile(accessToken, s3Key, fileName, fileExtension, param?.companyId))
    }
    const onhandleGoBackRoute = () => {
        history.goBack()
    }
    const onhandleEdit = () => {
        setEdit(false)
    }
    const onhandleChangeLogName = (index, e) => {
        const newData = {

            index,
            name: e.target.value,

        }
        dispatch({ type: ConsumerTypes.UPDATE_NAME_IN_PRODUCTION_LOG, payload: newData })
    }
    const onhandleChangeDescription = (index, e) => {
        const newData = {
            index,
            description: e.target.value,

        }
        dispatch({ type: ConsumerTypes.UPDATE_DESCRIPTION_IN_PRODUCTION_LOG, payload: newData })
    }
    const onhandleCancel = () => {
        setEdit(true)
        setRefresh(false)
    }
    const onhandleSubmit = () => {
        dispatch(UpdateProductionLog(param?.id, accessToken, productionLogs))

    }
    const breadCrumbs = [
        // {
        //     link: endPoint.DASHBOARD_COMPANY + localStorage.getItem("companyId"),
        //     text: "Companies",
        //     separator: ">"

        // }, 
        {
            link: endPoint.DASHBOARD_PROJECT_HOME_URL + localStorage.getItem("companyId"),
            text: "Products",
            separator: ">"
        }, {
            link: endPoint.DASHBOARD_DETAIL_URL + localStorage.getItem("companyId") + "/" + localStorage.getItem("projectId"),
            text: localStorage.getItem("projectName"),
            separator: ">"
        }, {
        //     link: endPoint.DASHBOARD_ROLLUP_URL + localStorage.getItem("companyId") + "/" + localStorage.getItem("projectId"),
        //     text: "Production Details",
        //     separator: ">"
        // }, {
            link: "",
            text: "Production Logs",
            separator: ""
        }
    ]

    const { setBreadcrumbs } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, [localStorage.getItem("projectName")]);

    const onhandleSortLogName = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (isLogNameFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.name < b?.name) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsLogNameFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.name > b?.name) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsLogNameFilter(true)
        }
    }
    const onhandleSortStartDate = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (isstartDateFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.first_run < b?.first_run) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsStartDateFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.first_run > b?.first_run) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsStartDateFilter(true)
        }
    }
    const onhandleSortEndDate = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (issendDateFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.last_run < b?.last_run) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setEndDateFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.last_run > b?.last_run) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setEndDateFilter(true)
        }
    }
    const onhandleSortTotalRow = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (isTotalRowFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.total_data < b?.total_data) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsTotalRowFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.total_data > b?.total_data) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsTotalRowFilter(true)
        }
    }
    const onhandleSortAverageProgramming = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (isAverageProgramingFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.average_programming_time_seconds < b?.average_programming_time_seconds) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsAverageProgrammingFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.average_programming_time_seconds > b?.average_programming_time_seconds) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsAverageProgrammingFilter(true)
        }
    }
    const onhandleSortReprogramming = (e) => {
        e.preventDefault();
        let newResult = [...productionLogs];
        if (isReprogrammingFilter === true) {
            newResult = newResult.slice().sort((a, b) => (a?.average_times_reprogrammed < b?.average_times_reprogrammed) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsReprogrammingFilter(false)
        } else {
            newResult = newResult.slice().sort((a, b) => (a?.average_times_reprogrammed > b?.average_times_reprogrammed) ? 1 : -1)
            dispatch({ type: ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST, payload: newResult })
            setIsReprogrammingFilter(true)
        }
    }

    const getTotalAverageReprogrammingTime = () => {
        let totalAverageReprogrammingTime = 0;
        let totalRows = 0;
        for (let index = 0; index < productionLogs.length; index++) {
            totalAverageReprogrammingTime += productionLogs[index]?.average_programming_time_seconds * Number(productionLogs[index]?.total_data);
            totalRows += Number(productionLogs[index]?.total_data);
        }
        let average = totalAverageReprogrammingTime / totalRows
        if (isNaN(average))
            average = 0;
        return (average).toFixed(2);
    }

    const getTotalPercentReprogrammed = () => {
        let totalPercentReprogrammed = 0;
        let totalRows = 0;
        for (let index = 0; index < productionLogs.length; index++) {
            totalPercentReprogrammed += productionLogs[index]?.average_times_reprogrammed * Number(productionLogs[index]?.total_data);
            totalRows += Number(productionLogs[index]?.total_data);
        }
        let percent = totalPercentReprogrammed * 100 / totalRows;
        if (isNaN(percent))
            percent = 0;
        return (percent).toFixed(2);
    }

    return (
        <div className="mainContent">
            {loading && isUpdateLoading
                ?
                <LoadingSpinner />
                :
                <div>
                    <div className="consumer-val-container ">
                        <ConsumerHeading
                            text={projectName}
                        />
                        <div className="consumer-view-log-container">
                            <ConsumerContentHeading
                                text="Production Logs"
                            />
                            <div className="consumer-view-log-button-container">
                                <button title="Click to edit log names and descriptions" className="consumer-view-log-button btn" onClick={onhandleEdit}>
                                    <span className="log-button-text">
                                        Edit
                                    </span>
                                </button>
                                <button className="consumer-view-log-button btn" onClick={switchRoute}>
                                    <span className="log-button-text">
                                        Add Production Log
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="product-info-grid-wrapper average-statistics-wrapper">
                            <div className="average-statistics-container" style={{ color: 'black' }}>
                                Average Program Time
                                <br />
                                <FontAwesomeIcon className="average-statistics-icon" icon={faClock} /> &nbsp;
                                {getTotalAverageReprogrammingTime()}
                                <span style={{ color: "rgb(120, 120, 120)" }}>S</span>
                            </div>
                            <div className="average-statistics-container" style={{ color: 'black' }}>
                                % Reprogrammed
                                <br />
                                <FontAwesomeIcon className="average-statistics-icon" icon={faCircleLeft} /> &nbsp;
                                {getTotalPercentReprogrammed()}
                                <span style={{ color: "rgb(120, 120, 120)" }}>%</span>
                            </div>
                        </div>
                        <div className="table-responsive w-100">
                            <table className="consumer-view-log--table table">
                                <thead className="consumer-view-log-heading-table">
                                    <tr>
                                        <th
                                            scope="col"
                                            onClick={onhandleSortLogName}
                                        >Log Name</th>
                                        <th scope="col" >Description</th>
                                        {/* <th scope="col">Date</th> */}
                                        <th scope="col" onClick={onhandleSortStartDate}>Start Date</th>
                                        <th scope="col" onClick={onhandleSortEndDate}>End Date</th>
                                        <th scope="col" onClick={onhandleSortTotalRow}>Total Rows</th>
                                        <th scope="col" onClick={onhandleSortAverageProgramming}>Average Programming Time</th>
                                        <th scope="col" onClick={onhandleSortReprogramming}>% Reprogrammed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productionLogs.length > 0 ?
                                        productionLogs?.map((val, index) => {
                                            let averageProgramTime = Reprogramming.convertDecimalToTenthPlace(val?.average_programming_time_seconds);
                                            let reprogram = Reprogramming.convertDecimalToTenthPlace(val?.average_times_reprogrammed)
                                            let totalRows = Number(val?.total_data).toLocaleString();

                                            const shouldFlash = val?.id === logId;

                                            return (
                                                <tr key={index} className={shouldFlash ? "flash-row" : ""}>
                                                    <td className="col-md-2 consumer-view-add-log--file-name" >

                                                        {edit ?
                                                            <a
                                                                className='consumer-view-add-log--file-name-link'
                                                                href="#"
                                                                title='Click to download file'
                                                                onClick={() => onhandledownloadFile(val?.s3_access_key, val?.name, val?.file_type)}
                                                            >
                                                                <span>
                                                                    {val?.name}
                                                                </span>
                                                            </a>
                                                            :
                                                            <textarea
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                onChange={(e) => onhandleChangeLogName(index, e)}
                                                                value={val?.name}
                                                                rows="1">
                                                            </textarea>
                                                        }

                                                    </td>

                                                    <td className='consumer-view-add-log--description col-md-3'>
                                                        {edit ?
                                                            <span
                                                                id="exampleFormControlTextarea1"
                                                                rows="1"
                                                                disabled
                                                            >
                                                                {HelperUtil.removeNullString(val?.description)}
                                                            </span>
                                                            :
                                                            <div className="">
                                                                <textarea
                                                                    className="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    onChange={(e) => onhandleChangeDescription(index, e)}
                                                                    value={HelperUtil.removeNullString(val?.description)}
                                                                    rows="1">

                                                                </textarea>

                                                            </div>

                                                        }

                                                    </td>
                                                    <td>{ProductInfoUtil.getCurrentDate(val?.first_run)}</td>
                                                    <td>{ProductInfoUtil.getCurrentDate(val?.last_run)}</td>
                                                    <td>{totalRows}</td>
                                                    <td>{averageProgramTime}s</td>
                                                    <td>{reprogram}%</td>
                                                    <td>
                                                        <div className="consumer-view-add-log--edit-icon">
                                                            <FontAwesomeIcon title="Click to delete log" className="trash-icon-project icon-default-background" icon={faTrash} size="lg" onClick={(e) => onhandleShow(e, val?.id, val?.name)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                )
                                            }) :
                                        <tr className="list--loading-spinner">
                                            <td colSpan={8}>
                                                <div className="spinner-border" role="status" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <Modal show={show} onHide={onhandleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete {logNameConfirmation}? </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={onhandleClose}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={() => onhandleDeleteProductInfo(id)}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="consumer-add-view-log--save-cancel-button">
                            {!edit && <SaveAndCancelButton
                                text="Save"
                                onhandleCancel={onhandleCancel}
                                onhandleSubmit={onhandleSubmit}
                            />}
                        </div>
                    </div>
                </div>
            }
        </div>


    );
};

export default ConsumerViewAddLog;
