import React  from 'react';
import { Route, Redirect } from "react-router-dom";


export const ProtectedRoute =  ({
  component: Component,
  ...rest
}) => {
  
   let accessToken = localStorage.getItem("accessToken");
  
  return (
    <Route
      {...rest}
      render={props => {
        if (accessToken && 
          accessToken !== 'null' && 
          accessToken !=='undefined') {
          
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to="/"
            />
          );
        }
      }}
    />
  );
};



