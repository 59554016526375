
import React, {useState} from 'react';
import './login.css';
import { Link, useHistory, useLocation } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import endPoint from '../../ENDPOINT/react-endpoints/endPoint';
import BackEnd from '../../ENDPOINT/back-end-endpoint/endPoint';

import InputError from '../InputError/InputError';
import HoliosInForm from '../HoliosInForm/HoliosInForm';
import InputPassword from '../InputPassword/InputPassword';
import AlertSuccess from '../AlertSuccess/AlertSuccess';
import CopyRight from '../CopyRight/CopyRight';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"

const Login = () => {
    var body = document.getElementsByTagName("BODY")[0];
    body.style.backgroundColor = "white";
    body.style.backgroundImage = "none";
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState({
        message:"",
        status:200
    });
    const [showPassword, setShowPassword] = useState(true);
    
    const location = useLocation();
    const signUpSuccess = location?.state?.success;
  
    const history = useHistory();

    const onhandleShowPassword = (e) => {
        setShowPassword(false);
    }
    const onhandleHidePassword = (e) => {
        setShowPassword(true);
    }
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }
    const updateDataInLocalStorage = (accessToken, userName, userId, companyId) => {
        let hostname = window.location.hostname;
        let idx = hostname.indexOf(".");
        if (idx !== -1 && hostname !== "127.0.0.1") {
            hostname = hostname.substr(idx + 1);
        }
        // first delete old value then add new one
        document.cookie = "_sp3=;max-age=0;domain=" + hostname + ";path=/";
        document.cookie = "_sp3=" + accessToken + ";domain=" + hostname + ";path=/";
        // localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("userId", userId);
        localStorage.setItem("companyId", companyId);
        if (companyId === -1) {
            history.push(endPoint.DASHBOARD_COMPANY + "/" + localStorage.getItem("companyId"));
        } else {
            history.push(endPoint.DASHBOARD_PROJECT_HOME_URL + localStorage.getItem("companyId"));
        }
    }

    const loginUserThroughApi = async (data) => {
        try {
            let apiResponse = await axios.post(BackEnd.LOGIN_URL, data);
            const accessToken = apiResponse?.data?.accessToken;
            const userName = apiResponse?.data?.userName;
            const userId = apiResponse?.data?.user_id;
            const companyId = apiResponse?.data?.company_id;
            updateDataInLocalStorage(accessToken, userName, userId, companyId);
        } catch (error) {
            setError(prevState => (
                {
                    ...prevState,
                    message: error?.response?.data?.error,
                    status: error?.response?.status
                }
            ))
        }
    }
    const onHandleSubmit = async (e) => {
        e.preventDefault();
        const user = {
            email: email,
            password: password
        }
        loginUserThroughApi(user);
    }

    const handleGoogleLogin = async googleData => {
        const googleTokenExist = googleData?.tokenId;
        if (googleTokenExist) {
            //send post request
            try {
                //axios.post accepts two parameters: (URL, Data);
                const data = {
                    token: googleTokenExist,
                    accountType: "google"
                }
                let response = await axios.post(BackEnd.GOOGLE_AUTH_URL, data);
                console.log(response);
                const accessToken = response?.data?.accessToken;
                const userName = response?.data?.userName;
                const userId = response?.data?.user_id;
                const companyId = response?.data?.company_id;
                updateDataInLocalStorage(accessToken, userName, userId, companyId);
            } catch (error) {
                console.log(error.response);
                setError(prevState => (
                    {
                        ...prevState,
                        message: error.response?.data?.error,
                        status: error.response?.status
                    }
                ))
            }
        } else {
           
        }
    }

    const handleAmazonLogin = (e) => {
        e.preventDefault();

        let options = {};
        options.scope = 'profile';
        options.pkce = true;
        options.response_type = 'code';
        options.scope_data = {
            'profile': { 'essential': false }
        };
        window.amazon.Login.authorize(options, function (response) {
            if (response.error) {
                console.log(response.error);
                return;
            }
            window.amazon.Login.retrieveToken(response.code, (response) => {
                if (response.error) {
                    console.log(response.error);
                    return;
                }
                const data = {
                    accessToken: response.access_token,
                    accountType: "amazon"
                }
                //post data to back-end
                axios.post(BackEnd.AMAZON_AUTH_URL, data)
                    .then((response) => {
                        const accessToken = response?.data?.accessToken;
                        const userName = response?.data?.userName;
                        const userId = response?.data?.user_id;
                        const companyId = response?.data?.company_id;
                        updateDataInLocalStorage(accessToken, userName, userId, companyId);
                    })
                    .catch((error) => {
                        setError(prevState => (
                            {
                                ...prevState,
                                message: error.response?.data?.error,
                                status: error.response?.status
                            }
                        ))
                    })

            })

        });

    }
    return (
        <div className="login-form-wrapper">
            <form className="login-form" onSubmit={onHandleSubmit}>
                <HoliosInForm />
                {error.status !== 200 && <InputError message={error.message} />}
                {signUpSuccess && <AlertSuccess message="Sign Up successful. Please sign in." />}
                <input
                    className="login-form-input-email"
                    type="email" placeholder="Email"
                    onChange={onChangeEmail}
                    required
                />

                <InputPassword
                    onChangePassword={onChangePassword}
                    onhandleShowPassword={onhandleShowPassword}
                    onhandleHidePassword={onhandleHidePassword}
                    showPassword={showPassword}
                />



                <button className="login-form-button-login btn">
                    <div className="login-text">Sign In</div>
                </button>

                

                <div className="do-not-have-an-account-sign-up-wrapper">
                    <div className="do-not-have-account">
                        Don't remember your password?
                    </div>
                    <Link to={endPoint.RESET_PASSWORD_URL} className="login-form-sign-up">
                        Forgot password
                    </Link>
                </div>

                <div className="do-not-have-an-account-sign-up-wrapper">
                    <div className="do-not-have-account">
                        Don't have an account?
                    </div>
                    <Link to={endPoint.REGISTER_URL} className="login-form-sign-up">
                        Sign up
                    </Link>
                </div>
                <CopyRight />
            </form>
        </div>
    )
}
export default Login;
