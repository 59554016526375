class SourceCodeFileEndPoint {
    /**
     * /api/v1/source-code-file/file:id
     */

    SOURCE_CODE_FILE_BY_FILE_ID = "/api/v1/source-code-file/file/"
    /**
     * /api/v1/source-code-file/:productInfoid/:fileType
     */
    SOURCE_CODE_FILE = `/api/v1/source-code-files/`

    SEARCH_SOURCE_CODE_FILES = "/api/v1/source-code-files/search/"

}
export default new SourceCodeFileEndPoint();
