import React, { useCallback, useEffect, useRef, useState } from 'react';
import UploadFile from '../../UploadFile/UploadFile';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';
import ConsumerLabelAndInput from '../ConsumerLabelAndInput/ConsumerLabelAndInput';
import './sourceCodeFile.css'
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import { useParams, Redirect, useHistory } from 'react-router';
import axios from 'axios';

import HelperUtil from '../../../../util/HelperUtil'
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import SourceCodeFileEndPoint from '../../../../ENDPOINT/back-end-endpoint/SourceCodeFileEndPoint';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import ErrorHandling from '../../../errorHandling/error';

const ConsumerSourceCodeEditFile = ({ onSwitchToListPageRoute, fileData, fileId }) => {
    /**
     * @LOCALSTORAGE
     */
    let token = localStorage.getItem("accessToken");
    let projectName = localStorage.getItem("projectname");
    const companyId = localStorage.getItem("companyId")
    let productInfoId = localStorage.getItem("projectId");
    /**
     * @REACTROUTER
     */
    const param = useParams();
    /**
     * @STATE
     */
    const [logNameValidation, setLogNameValidation] = useState(false);
    const [fileValidation, setFileValdiation] = useState(false);
    const [dateValidation, setDateValidation] = useState(false);
    const [uploadLoading, setUpdateLoading] = useState(false);
    const [error, setError] = useState(null);

    const [name, setName] = useState(fileData?.name);
    const [description, setDescription] = useState(fileData?.description);
    const [date, setDate] = useState(ProductInfoUtil.getCurrentInputTypeDateFormat(fileData?.file_date));
    const [selectedFile, setSelectedFile] = useState([{
        name: ProductInfoUtil.concatFileExtension(fileData)
    }]);
    const history = useHistory();
    /**
     * @API
     */
    const apiUpdate = async (formData) => {
        setUpdateLoading(true)
        const REACT_END_POINT_SOURCE_CODE_FILE_LIST = endPoint.DASHBOARD_SOURCE_CODE_FILE_LIST + companyId + "/" + fileId
        const header = {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
            "authorization": `Bearer ${token}`
        }
        const END_POINT = SourceCodeFileEndPoint.SOURCE_CODE_FILE_BY_FILE_ID + fileData?.id + "/" + companyId
        try {
            let apiResponse = await axios.put(END_POINT, formData, {
                headers: header
            })
            setUpdateLoading(false)
            onSwitchToListPageRoute();
            //history push
            // history.push(REACT_END_POINT_SOURCE_CODE_FILE_LIST)
            //push it so that it refresh the state.
        } catch (error) {
            setUpdateLoading(false)
            console.log(error.message);
            setError(error?.message)
        }
    }

    /**
     * @ALL @ONHANDLE
     */
    const onhandleSubmit = (e) => {
        e.preventDefault();

        if (!name) {
            setLogNameValidation(true);
        }
        // if (!isFile()) {
        //     setFileValdiation(true);
        // }
        if (!date) {
            setDateValidation(true);
        } else if (selectedFile.length > 0 && name && date) {
            let formData = ProductInfoUtil.populateFormData(selectedFile[0], name, description, date, productInfoId, companyId);
            if (!ProductInfoUtil.isFile(selectedFile)) formData.delete("product-info")
            formData.append("s3Key", fileData?.s3_access_key)
            formData.append("companyAssetId", fileData?.company_asset_id)
            apiUpdate(formData)
        }
    }
    const onhandleChangeName = (e) => setName(e.target.value);
    const onhandleChangeDescription = (e) => setDescription(e.target.value);
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setSelectedFile(acceptedFiles);
    }, [])
    const onhandleDateChange = (e) => {
        setDateValidation(false)
        setDate(e.target.value)
    }
    const onHandleGoingBackToOriginalRoute = () => {
        // history.goBack()
    }
    // if (success) {
    //     history.push(`${endPoint.DASHBOARD_DETAIL_URL}${param?.id}`);
    // }
    if (error) {
        return <ErrorHandling error={error} />;
    }
    if (uploadLoading) {
        return (
            <div className="scf-container">
                <LoadingSpinner />
            </div>

        )
    } else {
        return (
            <div className="scf-container">
                <div className="source-code-file--heading">
                    <ConsumerHeading
                    // text={data?.name}
                    />
                </div>
                <div className="source-code-file-summary">

                    <ConsumerLabelAndInput
                        labelText="File Name "
                        validation={logNameValidation}
                        star="*"
                        validationMessage="File name cannot be blank"
                        onhandleChange={onhandleChangeName}
                        inputText={name}
                    />
                    <ConsumerLabelAndInput
                        labelText="Description"
                        onhandleChange={onhandleChangeDescription}
                        inputText={HelperUtil.removeNullString(description)}
                    />
                    <div className="source-code-file-section--date">
                        <label className="consumer-production-date-label">
                            Date&nbsp;
                            <i className="star">*</i>
                        </label>
                        <input
                            placeholder="MM/DD/YEAR"
                            type="date"
                            max="3000-01-01"
                            name="date"
                            onChange={onhandleDateChange}
                            className="consumer-production-log-input"
                            required
                            value={date}

                        />
                        {dateValidation &&
                            <div className="validation-color-source-code">Date cannot be blank</div>
                        }
                    </div>
                    <div className="source-code-file-section">
                        <UploadFile
                            labelText="File"
                            onDrop={onDrop}
                            fileValidation={fileValidation}
                            fileName={selectedFile[0]?.name}
                        />
                    </div>
                    <SaveAndCancelButton
                        text="Save"
                        endPoint={`${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${param?.id}`}
                        onhandleSubmit={onhandleSubmit}
                        onhandleCancel={onSwitchToListPageRoute}
                    />
                </div>
            </div>
        );
    }

};

export default ConsumerSourceCodeEditFile;
