import {combineReducers} from 'redux';

import { UsersReducers } from './UsersReducer';
import { 
    ConsumerReducer, 
    UpdateProductInfoReducer,
    DownloadSourceFileCodeReducer,
    UploadSourceFileCodeReducer,
    FetchSourceFileCodeReducer,
    DeleteSourceCodeFileReducer,
    FetchProductionLogReducer,
    DeleteProductionLogReducer, 
    FetchProductionLogItemReducer,
    UpdateProductionLogReducer,
    

} from './ConsumerReducer';

import { 
    // AddCompanyReducer,
    FetchCompanyReducer,
    FetchSingleCompanyReducer,
    UpdateSingleCompanyReducer,
    DeleteSingleCompanyReducer
} from './CompanyReducer';
const allReducers = combineReducers({

    UsersReducers:UsersReducers,
    ConsumerReducer: ConsumerReducer,
    FetchProductionLogReducer: FetchProductionLogReducer,
    UpdateProductionLogReducer: UpdateProductionLogReducer,
    UpdateProductInfoReducer: UpdateProductInfoReducer,
    DownloadSourceFileCodeReducer: DownloadSourceFileCodeReducer,
    UploadSourceFileCodeReducer: UploadSourceFileCodeReducer,
    FetchSourceFileCodeReducer: FetchSourceFileCodeReducer,
    DeleteSourceCodeFileReducer: DeleteSourceCodeFileReducer,
    DeleteProductionLogReducer: DeleteProductionLogReducer,
    FetchProductionLogItemReducer: FetchProductionLogItemReducer,
    // AddCompanyReducer: AddCompanyReducer,
    FetchCompanyReducer: FetchCompanyReducer,
    FetchSingleCompanyReducer: FetchSingleCompanyReducer,
    UpdateSingleCompanyReducer: UpdateSingleCompanyReducer,
    DeleteSingleCompanyReducer: DeleteSingleCompanyReducer,

  
    
})
export default allReducers;
