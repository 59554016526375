import React, { useState } from 'react';
import { ConsumerMemoizedLog } from '../ConsumerLog/ConsumerLog';
import Pagination from 'react-bootstrap/Pagination'
import BackEndPoint from '../../../../ENDPOINT/back-end-endpoint/endPoint';
import { useDispatch, useSelector } from 'react-redux';
import {
    FetchProductionLogItem,
    FetchProductionLogItemByFilterstartDate,
    FetchProductionLogItemByFilterStartAndEndDate,
    setProductInfoData,
    setStartDate
} from '../../../../redux/action/ConsumerAction';
import axios from 'axios';
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';

const ConsumerDetailRollupLogList = ({
    startDateQuery = "",
    endDateQuery = "",
    skusItem = "",
    paramId,
    SKUS_EXIST,
    currentSerialNumber,
    paramCompanyId,
    loading
}
) => {

    const isDateExist = (date) => {
        return date && ProductInfoUtil.isValidDate(date);
    }

    const dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState([1]);
    const [currentPage, setCurrentPage] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [productionLogItemData, setproductionLogItemData] = useState([])
    const [filterData, setFilterData] = useState([]);
    const defaultProductionLogItem = useSelector((state) => state.FetchProductionLogItemReducer.filterData);

    let token = localStorage.getItem("accessToken") || undefined
    //this is pagination
    /**
     * 
     * @API
     */
    const getApi = async (queryFilterEndPoint) => {
        const header = {
            headers: { "authorization": `Bearer ${token}` }
        }
        try {
            let dbResponse = await axios.get(queryFilterEndPoint, header)
            setproductionLogItemData(dbResponse?.data?.data)
            setFilterData(dbResponse?.data?.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const onhandleNext = () => {
        let endPoint = ""
        let nextPageNumber = currentPage + 1;

        //start end date
        if (isDateExist(startDateQuery) && isDateExist(endDateQuery) && skusItem) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&skus=${skusItem}&page=${nextPageNumber}`;
        } else if (isDateExist(startDateQuery) && isDateExist(endDateQuery)) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&page=${nextPageNumber}`;
        } else if (skusItem) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?skus=${skusItem}&page=${nextPageNumber}`
        } else {
            //default
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?page=${nextPageNumber}`
        }
        // dispatch(FetchProductionLogItem(paramId, token, endPoint))
        getApi(endPoint)
        setCurrentPage(nextPageNumber);
    }

    const onhandlePrev = () => {
        let endPoint = ""
        let prevPageNumber = currentPage - 1;
        //start end date
        if (isDateExist(startDateQuery) && isDateExist(endDateQuery) && skusItem) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&skus=${skusItem}&page=${prevPageNumber}`;
        } else if (isDateExist(startDateQuery) && isDateExist(endDateQuery)) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&page=${prevPageNumber}`;

        } else if (SKUS_EXIST) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?skus=${skusItem}&page=${prevPageNumber}`
        }
        {
            //default
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?page=${prevPageNumber}`
        }
        getApi(endPoint)
        setCurrentPage(prevPageNumber);
    }
    const onhandleSwitchPage = (e, pageNumber) => {
        e.preventDefault();
        e.stopPropagation();
        let endPoint = ""
        //start date , and end date
        //start date and end date, skus
        //skus
        if (isDateExist(startDateQuery) && isDateExist(endDateQuery) && SKUS_EXIST) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&skus=${skusItem}&page=${pageNumber}`;
        } else if (isDateExist(startDateQuery) && isDateExist(endDateQuery)) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?start_date=${startDateQuery}&end_date=${endDateQuery}&page=${pageNumber}`;

        } else if (SKUS_EXIST) {
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?skus=${skusItem}&page=${pageNumber}`
        }
        else {
            //default
            endPoint = `${BackEndPoint.DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID}${paramCompanyId}/${paramId}?page=${pageNumber}`
        }
        getApi(endPoint)
        setCurrentPage(pageNumber);
        return false;

    }
    const onhandleEllipse = () => {
        setPageNumber([...pageNumber, pageNumber.length + 1])
    }
    const onhandleSearchDsn = (e) => {
        let value = e.target.value.toUpperCase();
        let result = [];

        setSerialNumber(e.target.value);
        /**
         * FILTER DATA DOES NOT EXIST, WE FILTER DEFAULT DATA INSTEAD
         * DEFAULT DATA IS THE DATA COME FROM FETCH DATA IN PARAENT
         * COMPONENT
         */
        if (filterData.length === 0) {
            result = defaultProductionLogItem?.filter((data) => {
                return data?.dsn.search(value) != -1;
            });
        } else {
            result = filterData?.filter((data) => {
                return data?.dsn.search(value) != -1;
            });
        }
        setproductionLogItemData(result)
    }
    const onhandlePageNumbers = () => {
        var userInputPageNumbers = document.getElementById('page-number-input-box').value;
        var pageNumberArr = []
        for (var x = 1; x <= userInputPageNumbers; x++) {
            pageNumberArr.push(x);
        }
        //window.alert(pageNumberArr);
        setPageNumber(pageNumberArr);
        //window.alert(pageNumber);
    }

    return (
        <div>
            {(!currentSerialNumber) && 
                <div className="filter-center">
                    <div className='filter-upc-code mb-3'>
                        <div className='filter-upc-code--upc-code'>
                            <div>Search Data - SERIAL NUMBER: </div>
                        </div>
                        <input
                            placeholder="SERIAL NUMBER"
                            className='filter-date--input-mask-start-date'
                            type="search"
                            value = {currentSerialNumber ? currentSerialNumber : this?.value}
                            onChange={onhandleSearchDsn}
                        />
                    </div>
                </div>
            } 

            {(serialNumber || currentSerialNumber) && 
                <>
                    <ConsumerMemoizedLog
                        productionLogItemData={productionLogItemData}
                        loading={loading}
                    />

                    {(!currentSerialNumber) && 
                        <>
                            <div className="consumer-production-log-container">
                                <Pagination>
                                    <Pagination.Prev onClick={onhandlePrev} />
                                    {
                                        pageNumber?.map((val, index) =>
                                            <Pagination.Item
                                                key={index}
                                                active={index + 1 === currentPage}
                                                onClick={(e) => onhandleSwitchPage(e, val)}>
                                                {val}
                                            </Pagination.Item>
                                        )
                                    }
                                    <Pagination.Ellipsis onClick={onhandleEllipse} />
                                    <Pagination.Next onClick={onhandleNext} />
                                </Pagination>
                            </div>
                            {/*<div style={{ paddingBottom: "2vh" }}>
                                NUMBER OF PAGES:
                                <input id="page-number-input-box" className='filter-date--input-mask-start-date' />
                                <button className='Consumer-detail-roll-up--search-button btn'
                                    onClick={onhandlePageNumbers}>
                                    Enter
                                </button>
                            </div>*/}
                            <div style={{ paddingBottom: "2vh" }}/>
                        </>
                    }
                </>
            }
            {!serialNumber && !currentSerialNumber && 
                <>
                    <div style={{ paddingBottom: "2vh" }}>

                    </div>
                </>
            }
            
        </div>
    );
};

export default ConsumerDetailRollupLogList;
