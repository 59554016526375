import getColors from './GetColors';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { forwardRef } from 'react';
import { Chart as ChartJS } from 'chart.js/auto'; // necessary import -- don't remove!

const UPC_SKUBarChart = forwardRef(({ productInfoData }, ref) => {
    const skusDataArr = productInfoData?.skus?.map(val => parseInt(val?.device_count));
    const skusArr = productInfoData?.skus?.map(val => val?.skus);
    const barColors = getColors(skusArr, [189, 223, 242], [42, 87, 131], 1);

    const chartData = {
        labels: skusArr,
        datasets: [{
            label: 'Number of Devices',
            data: skusDataArr,
            backgroundColor: barColors,
            borderColor: barColors
        }]
    };

    const options = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: "Number of Devices",
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                },
                ticks: {
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                }
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 5,
        },
        plugins: {
            title: {
                display: true,
                text: 'Production Per SKU',
                font: {
                    family: 'Poppins, sans-serif',
                    size: 13
                }
            },
            datalabels: {
                formatter: function (value) { return value.toLocaleString() },
                display: true,
                color: "rgb(120, 120, 120)",
                align: "top",
                anchor: "end",
                offset: 0,
                font: {
                    family: 'Poppins, sans-serif'
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItem, data) {
                        let label = data.labels[tooltipItem.index];
                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return label + ': ' + value;
                    }
                },
                titleFont: {
                    family: 'Poppins, sans-serif'
                },
                bodyFont: {
                    family: 'Poppins, sans-serif'
                }
            },
            legend: {
                display: false,
                labels: {
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                }
            },
        },
        animation: {
            duration: 500,
        },
    };

    const backgroundColor = {
        id: 'backgroundColor',
        beforeDraw: function(chart, args, options) {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    return (
        <Bar
            ref={ref}
            style={{ display: "inline-block", position: "relative", width: "100%" }}
            data={chartData}
            plugins={[ChartDataLabels, backgroundColor]}
            options={options} />
    );
});

export default UPC_SKUBarChart;