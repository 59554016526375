import React from 'react';
import './InputDate.css';
  
function InputDate({
    styleProperty,
    onChangeDate,
    inputText
}) {
  
    return (
      
        <div>
       
            <input
                
                placeholder="MM/DD/YEAR"
                type="date"
                max="3000-01-01"
                name="date"
               
                onChange={onChangeDate}
                className={styleProperty}
                required
                value={inputText}
                
            />

          
         
        </div>

    );
}

export default InputDate;
