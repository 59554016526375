//const URL  = "http://localhost:3001";
//const URL = "http://dashboardbackend-env.eba-szuweban.us-east-1.elasticbeanstalk.com"


class ProductInfoEndPoint {
    /*
        @Method delete, update, post ProductInfo table
    */
    DASHBOARD_PRODUCT_INFO_BY_ID = "/api/v1/dashboard/product-info/"
    DASHBOARD_PROJECT_BY_PRODUCT_INFO_ID = "/api/v1/dashboard/project/"
   
}



export default new ProductInfoEndPoint();
