import axios from "axios"
import { ConsumerTypes } from "../constants/ConsumerType"
import backendEndPoint from '../../ENDPOINT/back-end-endpoint/endPoint';
import SourceCodeFileEndPoint from "../../ENDPOINT/back-end-endpoint/SourceCodeFileEndPoint";
import ProductInfoUtil from "../../util/ProductInfoUtil";

import { saveAs } from "file-saver";
import ConsumerLogList from "../../components/dashboard/Consumer/ConsumerLog/ConsumerLogList";
export const setProductInfoData = (data) => {
    return {
        type: ConsumerTypes.SET_PRODUCT_INFO_DATA,
        payload: data
    }
}
export const setProjectName = (data) => {
    return {
        type: ConsumerTypes.SET_PROJECT_NAME,
        payload: data
    }
}
export const setClientName = (data) => {
    return {
        type: ConsumerTypes.SET_CLIENT_NAME,
        payload: data
    }
}
export const setDescription = (data) => {
    return {
        type: ConsumerTypes.SET_DESCRIPTION,
        payload: data
    }
}
export const setFirstName = (data) => {
    return {
        type: ConsumerTypes.SET_FIRST_NAME,
        payload: data
    }
}
export const setLastName = (data) => {
    return {
        type: ConsumerTypes.SET_LAST_NAME,
        payload: data
    }
}

export const setEmail = (data) => {
    return {
        type: ConsumerTypes.SET_EMAIL,
        payload: data
    }
}

export const setMobileNumber = (data) => {
    return {
        type: ConsumerTypes.SET_MOBILE_NUMBER,
        payload: data
    }
}

export const setStartDate = (data) => {
    return {
        type: ConsumerTypes.SET_START_DATE,
        payload: data
    }
}

export const setEndDate = (data) => {
    return {
        type: ConsumerTypes.SET_END_DATE,
        payload: data
    }
}

export const setForeCast = (data) => {
    return {
        type: ConsumerTypes.SET_FORE_CAST,
        payload: data
    }
}

export const setUnitRate = (data) => {
    return {
        type: ConsumerTypes.SET_UNIT_RATE,
        payload: data
    }
}

export const setUnitCap = (data) => {
    return {
        type: ConsumerTypes.SET_UNIT_CAP,
        payload: data
    }
}

export const setSkus = (data) => {
    return {
        type: ConsumerTypes.SET_SKUS,
        payload: data
    }
}

export const setContractNote = (data) => {
    return {
        type: ConsumerTypes.SET_CONTRACT_NOTE,
        payload: data
    }
}
export const filterProductionLogData = (data) => {
    return {
        type:ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST,
        payload:data
    }
}
const isFileType = (input, fileType) => {
    return fileType && fileType.length > 0 && fileType[0] === input;
}
const getSourceCodeFileType = (fileType) => {
    const HMCU = "HMCU",
        ACK = "ACK"
    let sourceCodeFileTypes = ""
    if (isFileType('H', fileType)) {
        sourceCodeFileTypes = HMCU
    } else if (isFileType('A', fileType)) {
        sourceCodeFileTypes = ACK
    }
    return sourceCodeFileTypes;
}
export const UpdateProductInfo = (data, token, id, companyId) => async (dispatch, getState) => {
    const header = {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${token}`
    }
    try {
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST })
        //fetch api
        await axios.put(
            `${backendEndPoint.DASHBOARD_UPDATE_PRODUCT_INFO}${companyId}/${id}`,
            data, {
            headers: header,
        })
        //dispatch success
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_SUCCESS })
    } catch (error) {
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_FAILURE, payload: error.message })
    }
}

export const DownloadSourceFile = (token, s3Key, fileName, fileExtension, companyId) => async (dispatch, getState) => {
    const header = { "authorization": `Bearer ${token}` };
    try {
        dispatch({ type: ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST })
        let apiResponse = await axios.get(
            `${backendEndPoint.DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILE}/${s3Key}/${companyId}`, { headers: header, responseType: 'blob' }
        );
        const result = apiResponse?.data;
        if (result) {
            const url = URL.createObjectURL(result);
            saveAs(url, apiResponse.headers['content-disposition'].split('filename=')[1]);
            dispatch({ type: ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS, payload: url })
        }
    } catch (error) {
        dispatch({ type: ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE })
    }
}

export const UploadSourceFile = (
    token,
    productInfoId,
    name,
    description,
    date,
    selectedFile,
    companyId,
    sourceCodeFileType
) => async (dispatch, getState) => {
    const header = {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${token}`
    }

    let formData = ProductInfoUtil.populateFormData(selectedFile[0], name, description, date, productInfoId, companyId, getSourceCodeFileType(sourceCodeFileType));
    try {
        dispatch({ type: ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST });
        await axios.post(backendEndPoint.DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILES + "/" + companyId, formData, {
            headers: header
        })
        dispatch({ type: ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS })
    } catch (error) {
        dispatch({ type: ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE, error: error.message });
        console.log(error.message);
    }
}


export const FetchSourceFiles = (id, companyId, token, fileType="", pageNumber = 1) => async (dispatch, getState) => {
    const sizeOfPage = 10;
    let endPoint = SourceCodeFileEndPoint.SOURCE_CODE_FILE + id + "/" + companyId + "/" + getSourceCodeFileType(fileType);
     endPoint += `?page=${pageNumber}&limit=${sizeOfPage}`;
 
    //Array.from({length: 10}, (v, k) => k+1); 
    //default end point
    // if(pageNumber === 0 && trackFile === 0){
    //     endPoint = SourceCodeFileEndPoint.SOURCE_CODE_FILE + id + "/" + getSourceCodeFileType(fileType);
    // }
    // //user change number in pagination.
    // //when the file container more than 10.
    // else if(pageNumber > 0 && trackFile > 10){
    //     console.log("pagination");
    //     endPoint += `?page=${pageNumber}&limit=${INTEGER}`;
    // }
    const header = {
        "authorization": `Bearer ${token}`
    }
    const getApi = async (urlEndPoint) => {
        let apiResponse = await axios.get(urlEndPoint,
            { headers: header }
        )
        return apiResponse?.data;
    }
    try {
        // dispatch({ type: ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST })
        let apiResponse = ""
        apiResponse = await getApi(endPoint);
        let dataResult = apiResponse?.data;
        let fileLength = apiResponse?.countFile;
        //when result in table is 0, when set switch sourcecode to false
        //so that button will switch from view source to add source-code
        if (dataResult && dataResult.length === 0) {
            localStorage.setItem("switchSourceCode", false);
        }
        
        //source code files are more than 10, then show pagination.
        if (fileLength && fileLength > sizeOfPage){
            let data = Math.ceil(fileLength / sizeOfPage);
            let pageNumber = Array.from({ length: data }, (v, k) => k + 1); 
            dispatch({ type: ConsumerTypes.SOURCE_CODE_PAGE_NUMBER, payload: pageNumber })
            // dispatch({ type: ConsumerTypes.TRACK_FILE_SOURCE_CODE_FILES, payload: result.length })
            dispatch({ type: ConsumerTypes.FETCH_FILTER_PAGINATION_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: true })
        }
        dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: dataResult })
        dispatch({ type: ConsumerTypes.FETCH_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: dataResult })

    } catch (error) {
        dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE, error: error.message });
        console.log(error.message);
    }
}
export const FilterSourceFile = (token, input, status, companyId) => async (dispatch, getState) => {

    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
       
    }
    const productInfoId = localStorage.getItem("projectId")
    const body = {
        input,
        sourceCodeFileType: getSourceCodeFileType(status),
        id: productInfoId
    }
    const endPoint = SourceCodeFileEndPoint.SEARCH_SOURCE_CODE_FILES + companyId;
    try {
        dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload:false })
        let apiResponse = await axios.post(endPoint, body, header);
        dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: apiResponse?.data?.data })
        dispatch({ type: ConsumerTypes.FETCH_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: apiResponse?.data?.data })
    } catch (error) {
        dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE, error: error.message });
        console.log(error.message);
    }
}

export const DeleteSourceCodeFile = (id, token, status) => async (dispatch, getState) => {

    const header = {
        "authorization": `Bearer ${token}`
    }
   
    const productInfoId = localStorage.getItem("projectId")

    try {
        const companyId = localStorage.getItem("companyId") || "";
        dispatch({ type: ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST })
        let apiResponse = await axios.delete(backendEndPoint.DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILE + '/' + id + "/" + productInfoId + "/" + companyId + "/" + getSourceCodeFileType(status), { headers: header })
        const fileCount = apiResponse?.data?.fileCount
        dispatch({ type: ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST_SUCCESS })
        //file count less than equal to 10
        if(fileCount && parseInt(fileCount) <= 10){
            window.location.reload(false);
        }
        
    } catch (error) {
        dispatch({ type: ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST_FAILURE, error: error.message })
        console.log((error.message));
    }
}


export const FetchProductionLog = (id, token) => async (dispatch, getState) => {
    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }

    const companyId = localStorage.getItem("companyId") || "";

    try {
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_REQUEST })
        let apiResponse = await axios.get(backendEndPoint.DASHBOARD_PRODUCT_PRODUCTION_LOG_BY_PRODUCT_ID + companyId + "/" + id, header);
        // console.log(apiResponse?.data?.data)
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_REQUEST_SUCCESS, payload: apiResponse?.data?.data })
    } catch (error) {
        console.log((error.message));
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_REQUEST_FAILURE, error: error.message })
    }
}

export const DeleteProductionLog = (id, token) => async (dispatch, getState) => {
    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }
    const companyId = localStorage.getItem("companyId") || "";
    try {
        dispatch({ type: ConsumerTypes.DELETE_PRODUCTION_LOG_REQUEST })
        await axios.delete(backendEndPoint.DASHBOARD_PRODUCT_PRODUCTION_LOG_BY_PRODUCTION_LOG_ID + companyId + "/" + id, header)
        dispatch({ type: ConsumerTypes.DELETE_PRODUCTION_LOG_REQUEST_SUCCESS })

    } catch (error) {
        console.log(error.message);
        dispatch({ type: ConsumerTypes.DELETE_CONTRACT_REQUEST_FAILURE, error: error.message })
    }
}
export const UpdateProductionLog = (productInfoId, token, productionLogData) => async (dispatch, getState) => {

    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }
    const data = {
        productionLogData
    }
    const companyId = localStorage.getItem("companyId") || "";
    try {
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST })
        await axios.put(backendEndPoint.DASHBOARD_PRODUCT_PRODUCTION_LOG_BY_PRODUCT_ID + companyId + "/" + productInfoId, data, header)
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST_SUCCESS })
    } catch (error) {
        console.log(error.message);
        dispatch({ type: ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST_FAILURE, error: error.message })
    }
}

/**
 * @ProductionLogItem 
 * 
 */

export const FetchProductionLogItem = (
    id, token,
    queryFilterEndPoint
) => async (dispatch, getState) => {


    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }
    // const START_DATE = "start_date"
    // const END_DATE = "end_date"
    // let queryFilterEndPoint = "";
    // if (dateCondition?.startDate === START_DATE
    //     && dateCondition?.endDate === END_DATE) {
    //     queryFilterEndPoint = `start_date=${startDateQuery
    //         }&end_date=${endDateQuery}&page=${pageNumber}`
    // }
    // else if (dateCondition?.startDate === START_DATE) {
    //     //start_date & page
    //     queryFilterEndPoint = `start_date=${startDateQuery}&page=${pageNumber}`
    // }
    // else if (dateCondtionDoesNotExist) {
    //     queryFilterEndPoint = `page=${pageNumber}`;
    // }
    try {
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST })
        let dbResponse = await axios.get(queryFilterEndPoint, header)
        dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: dbResponse?.data?.data })
    } catch (error) {
        console.log(error.message);
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE, error: error.message });
    }
}

export const FetchProductionLogItemByFilterstartDate = (route, token) => async (dispatch, getState) => {
    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }
    try {
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST })
        let dbResponse = await axios.get(route, header)
        dispatch({ type: ConsumerTypes.FETCH_SKU_LIST, payload: dbResponse?.data?.data?.skus })
        dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: dbResponse?.data?.data?.productionLogItems })
        dispatch(setProductInfoData(dbResponse?.data?.data))
        // dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE });
    } catch (error) {
        console.log(error.message);
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE, error: error.message });
    }
}

export const FetchProductionLogItemByFilterStartAndEndDate = (route, token) => async (dispatch, getState) => {
    const header = {
        headers: { "authorization": `Bearer ${token}` }
    }
    try {
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST })
        let dbResponse = await axios.get(route, header)
        dispatch({ type: ConsumerTypes.FETCH_SKU_LIST, payload: dbResponse?.data?.data?.skus })
        dispatch({ type: ConsumerTypes.FETCH_LIST_OF_SKUS, payload: dbResponse?.data?.data?.listOfSkus })
        dispatch({ type: ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS, payload: dbResponse?.data?.data?.productionLogItems })
        dispatch(setProductInfoData(dbResponse?.data?.data))
    } catch (error) {
        console.log(error.message);
        dispatch({ type: ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE, error: error.message });
    }
}
