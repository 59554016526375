import React from 'react';

function AddCompanyInputLabel({
    labelText, 
    onhandleChange, 
    star,
    validateInput,
    validateInputText,
    inputRef
    
}) {

    return (
        <div className="add-company-container-input-label">
            <label className="add-company-container-input-label--label">{labelText}
                {star && <i className="star">*</i>}
            </label>
            <input
                className="add-company-container-input-label--input"
                type="text"
                onChange={onhandleChange}
                ref={inputRef}
            />
            <label> </label>
            {validateInput &&
                <div className="add-company-container--input-validation">{validateInputText}</div>
            }
            
        </div>
    );
}

export default AddCompanyInputLabel;
