import React from "react";
import NumberFormat from 'react-number-format';
import InputValidation from "../../InputValidation/InputValidation";

const StartDateWithValidation = ({onChangeStartDate, text}) => {
    return(
        <div className="start-date">
                <label className="label-text label-first-contact-input label-start-date">START DATE
                    <i className="star">*</i>
                </label>
                {/* <NumberFormat 
                    placeholder="MM/DD/YEAR"
                    className="create-post-point-of-contact-input input-first-contact input-email validation-border" 
                    format="##/##/####" 
                    onValueChange={onChangeStartDate}
                /> */}
                <input
                    type="date"
                    name="date"
                    className="create-post-point-of-contact-input input-first-contact input-email"
                    onChange={onChangeStartDate}
                />
                <label></label>
                <InputValidation 
                    message={text}
                />
                                               
        </div>

    )
}
export default StartDateWithValidation;
