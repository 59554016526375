//const URL  = "http://localhost:3001";
//const URL = "http://dashboardbackend-env.eba-szuweban.us-east-1.elasticbeanstalk.com"

import Config from "../../config/Config";

const { URL } = Config;
class BackEnd {


  REGISTER_URL = `/api/user/register`
  LOGIN_URL = `/api/user/login`
  ACCESS_TOKEN_URL = `token`
  YOUR_HOME_URL = `api/yourstore/home`
  LOGOUT_URL = `/api/user/logout`
  GOOGLE_AUTH_URL = `/api/auth/google`
  AMAZON_AUTH_URL = `/api/auth/amazon`
  RESET_PASSWORD_URL = `/api/reset-password`
  // DASHBOARD_DELETE_PRODUCT_INFO = `/api/v1/dashboard/product-info/`
  DASHBOARD_CREAT_POST_URL = `/api/dashboard/product-info/create`
  // DASHBOARD_DETAIL_URL = `/api/dashboard/product-info/details`
  DASHBOARD_DETAIL_URL_ID = `/api/v1/dashboard/product-info/detail/`
  DASHBOARD_UPLOAD_FILE_URL = `/api/dashboard/product-info/upload`
  DASHBOARD_UPLOAD_FILE_LOG_URL = `/api/dashboard/product-info/log`


  DASHBOARD_ADD_LOG_FILE_URL = `/api/v1/dashboard/logfile`
  // productinfoid ---> log_file_id
  DASHBOARD_PRODUCT_INFO_DETAIL_LIST_LOG_FILE = `/api/v1/dashboard/logfile/`
  DASHBOARD_UPDATE_PRODUCT_INFO = `/api/v1/dashboard/product-info/`
  DASHBOARD_DELETE_SKUS_PRODUCT_INFO = `/api/dashboard/product-info/skus/delete`
  DASHBOARD_DELETE_FORE_CAST_PRODUCT_INFO = `/api/dashboard/product-info/fore-cast/delete`
  DASHBOARD_PRODUCT_INFO_ACK_SUMMARY_DETAIL = `/api/v1/dashboard/ack/summaries/`
  DASHBOARD_RESET_PASSWORD = `/api/v1/dashboard/reset-password`
  DASHBOARD_RESET_PASSWORD_DETAILS = `/api/v1/dashboard/reset-password/`
  //"/api/v1/dashboard/productionlog/:logFileId/upload"
  DASHBOARD_PRODUCT_PRODUCTION_LOG_UPLOAD = `/api/v1/dashboard/productionlog/upload`
  DASHBOARD_PRODUCT_PRODUCTION_LOG_BY_PRODUCT_ID = `/api/v1/dashboard/productionlogs/`
  DASHBOARD_PRODUCT_PRODUCTION_LOG_BY_PRODUCTION_LOG_ID = `/api/v1/dashboard/productionlog/`
  DASHBOARD_PRODUCT_INFO_DETAIL_BY_ID_WITHOUT_ALL_LOGS = `/api/v1/dashboard/product-info/detail/without/alllogs/`
  DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILE = `/api/v1/source-code-file`
  DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILES_BY_ID = `/api/v1/source-code-files/`
  DASHBOARD_PRODUCT_INFO_SOURCE_CODE_FILES = `/api/v1/source-code-files`

  DASHBOARD_PRODUCTION_LOG_ITEM_BY_PRODUCT_INFO_ID = `/api/v1/dashboard/productionlog-item/`

  DASHBOARD_COMPANY = `/api/v1/companies`
  DASHBOARD_COMPANY_BY_COMPANY_ID = `/api/v1/companies/`

  API_INFO_URL = `/api/v1/api-info`
}



export default new BackEnd();
