import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams, Link, useLocation, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'react-bootstrap/Pagination'

import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import { 
    FetchSourceFiles, 
    DownloadSourceFile, 
    DeleteSourceCodeFile,
    FilterSourceFile
 } from '../../../../redux/action/ConsumerAction';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import ConsumerContentHeading from '../ConsumerContentHeading/ConsumerContentHeading';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';

import CustomModal from '../../CustomModal/CustomModal';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';

import { ConsumerTypes } from '../../../../redux/constants/ConsumerType';
import ConsumerSourceCodeEditFile from './ConsumerSourceCodeEditFile';

import HelperUtil from '../../../../util/HelperUtil'
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
const PROJECT_DETAIL = "< PROJECT DETAILS"
const ConsumerSourceCodeFileList = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const anchorTageRef = useRef();
   
    const location = useLocation();
    const history = useHistory();

    const data = useSelector((state) => state.FetchSourceFileCodeReducer);
    const defaultData = useSelector((state) => state.FetchSourceFileCodeReducer.defaultData);
    const isPagination = useSelector((state) => state.FetchSourceFileCodeReducer.isPagination);
    const pageNumber = useSelector((state) => state.FetchSourceFileCodeReducer.pageNumber);
    // const trackFile = useSelector((state) => state.FetchSourceFileCodeReducer.trackFile);
    const { success, loading } = useSelector((state) => state.DeleteSourceCodeFileReducer);
    const downloadSuccess = useSelector((state) => state.DownloadSourceFileCodeReducer.success);

    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState({});
    const [switchToEditPage, setSwitchToEditPage] = useState(true);
    // const [pageNumber, setPageNumber] = useState([1]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchFilter, setSearchFilter] = useState(false);
    const [isDefault, setIsDefault] = useState(true);
    const [reactInterval, setReactInterval] = useState("");
    const [input, setInput] = useState("");

    let projectName = localStorage.getItem("projectname") || undefined
    let accessToken = localStorage.getItem("accessToken") || undefined

    const fileTypeStatus = location?.state

    useEffect(() => {
        console.log(data)
    }, [data.data])

    useEffect(() => {
        const deleteFileSuccessFully = () => {
            if (success) {
                //set success to false so that we can redeleted again.
                dispatch({ type: ConsumerTypes.RESET_DELETE_SOURCE_CODE_FILES_REQUEST });
            }
        }
        const searchForFileName = () => {
            if (searchFilter && !isDefault) {
                setSearchFilter(false);
                let interval = setTimeout(() => {
                    //set filter 
                   dispatch(FilterSourceFile(accessToken, input, fileTypeStatus, param?.companyId));
                }, 2000)
                setReactInterval(interval)
            }
        }
        const getDefaultData = () => {
            if(isDefault){
                dispatch(FetchSourceFiles(param?.id, param?.companyId, accessToken, fileTypeStatus, currentPage))
                setIsDefault(false);//resset default to true when the input file is empty
            } else if(searchFilter && isDefault){
                dispatch(FetchSourceFiles(param?.id, param?.companyId, accessToken, fileTypeStatus, currentPage))
                setIsDefault(false);//resset default to true when the input file is empty
            }
        }
        getDefaultData();
        searchForFileName();
        deleteFileSuccessFully();
     
        return () => clearTimeout(reactInterval)
        // return () => {
        //     window.removeEventListener('keydown', downHandler);
        //     window.removeEventListener('keyup', upHandler);
        // }
    }, [
        success, 
        switchToEditPage, 
        searchFilter,
        isDefault
    ])

    const onhandledownloadFile = (s3Key, fileName, fileExtension) => {
        dispatch(DownloadSourceFile(accessToken, s3Key, fileName, fileExtension, param?.companyId))
    }
    const onhandleDeleteFile = (companyAssetId) => {
        setShow(false);
        dispatch(DeleteSourceCodeFile(companyAssetId, accessToken, fileTypeStatus))
    }
    const onhandleShow = (e, name, companyAssetId) => {
        e.stopPropagation();
        setShow(true)
        setFileName(name)
        setId(companyAssetId)
    };
    const onhandleClose = () => {
        setShow(false);
    }
    const breadCrumbs = [
        // {
        //     link: endPoint.DASHBOARD_COMPANY + localStorage.getItem("companyId"),
        //     text: "Companies",
        //     separator: ">"

        // }, 
        {
            link: endPoint.DASHBOARD_PROJECT_HOME_URL + localStorage.getItem("companyId"),
            text: "Products",
            separator: ">"
        }, {
            link: endPoint.DASHBOARD_DETAIL_URL + localStorage.getItem("companyId") + "/" + localStorage.getItem("projectId"),
            text: localStorage.getItem("projectName"),
            separator: ">"
        }, {
            link: "",
            text: location?.state,
            separator: ""
        }
    ]
    const { setBreadcrumbs } = useContext(BreadcrumbContext);
  
    useEffect(() => {
      setBreadcrumbs(breadCrumbs);
    }, []);
    const onSwitchToEditPageRoute = (e, data = {}) => {
        e.stopPropagation(); 
        setFileData(data)
        setSwitchToEditPage(false);
       
        // history.push(endPoint.DASHBOARD_PROJECT_HOME_URL_EDIT + projectId)
    }
    const onSwitchToListPageRoute = (e) => {
        setSwitchToEditPage(true);
    }
    // const onSwitchToListPageRoute = (e) => {
    //     setSwitchToEditPage(true);
    // }
    const resetAllStateToDefault = () => {
        setSwitchToEditPage(false);
    }
    const onhandleSwitchToaddSourceCode = (e, nextEndPoint, data) => {
        e.preventDefault();
        const state = {
            pathname: nextEndPoint,
            state: data
        }
        history.push(state)
    }
    const onhandleSearchFileName = (e) => {
        const value = e.target.value || "";
        if(!value  || value === null || value.length === 0){
            setIsDefault(true);
            // dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: defaultData })
            setInput("");
        }else{
            setInput(value);
            setIsDefault(false);
        }
         // let result = []
        // result = defaultData?.filter(item => item?.name.search(value) != -1);
        // //set to default data
        // if ( value === null && value.length === 0){
        //     dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: defaultData })
        // }else{
        //     dispatch({ type: ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS, payload: result })
        // }
    }
    const onhandleEllipse = () => {
        // setPageNumber([...pageNumber, pageNumber.length + 1])
    }
    const onhandlePrev = () => {
        let prevPageNumber = currentPage - 1;
        dispatch(FetchSourceFiles(param?.id, accessToken, fileTypeStatus, prevPageNumber))
        setCurrentPage(prevPageNumber);
    }
    const onhandleNext = () => {
        let nextPageNumber = currentPage + 1;
        dispatch(FetchSourceFiles(param?.id, accessToken, fileTypeStatus, nextPageNumber))
        setCurrentPage(nextPageNumber);
    }
    const onhandleSwitchPage = (e, pageNumber) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(FetchSourceFiles(param?.id, accessToken, fileTypeStatus, pageNumber))
        setCurrentPage(pageNumber);

    }

    function downHandler({ key }) {
        
    }
    
    function upHandler(event) {
        let charCode = event.keyCode; 
        const isAlphabet = () => {
            return (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123);
        }
        const isNumber = () => {
            return charCode >= 48 && charCode <=57
        }
        if (isAlphabet() || isNumber()){
            setSearchFilter(true);
        }
    }

    const ADO_SOURCE_CODE_END_POINT = `${endPoint.DASHBOARD_SOURCE_CODE_FILE + param?.companyId + "/" + param?.id}`

    if(!switchToEditPage){
        return(
            <div className="mainContent">
                <div>
                    <ConsumerSourceCodeEditFile 
                        onSwitchToListPageRoute={onSwitchToListPageRoute}
                        fileData={fileData}
                        fileId={param?.id}
                        />
                </div>
            </div>
        )
    }else{
        return (
            
            <div className="mainContent">
                {loading ? (console.log("loading")) &&
                    <LoadingSpinner />
                    :
                    <div className="consumer-source-code--container">
                        <ConsumerHeading
                            text={projectName}
                        />
                       
                        <div className="consumer-source-code--wrapper">
                            {/* <ConsumerContentHeading
                                text="Source Code File List"
                            /> */}
                            <h5 className='consumer-source-code--text'>Source Code File List</h5>
                            {/* <button className='btn btn-info source-code-file-list--edit-source-btn'>Edit</button> */}
                            {/* <button className="consumer-view-log-button btn btn-info" onClick={onhandleSwitchRoute}>Add Source Code File</button> */}
                            <button
                                className="btn source-code-file-list--add-source-btn"
                                onClick={(e) => onhandleSwitchToaddSourceCode(e, ADO_SOURCE_CODE_END_POINT, location?.state)}
                            >
                                <span className='add-source-btn-text'>Add Source Code File</span>
                            </button>

                        </div>
                        <div className="consumer-source-code-file-name-container">
                            <div className='consumer-source-code-file-list--name-filter'>
                                <input 
                                    className='form-control  consumer-source-code-file-list-name-filter--input'
                                    style={{maxWidth: '20rem'}}
                                    type="search"
                                    placeholder='File Name'
                                    onChange={onhandleSearchFileName}
                                    onKeyUp={upHandler}
                                    onKeyDown={downHandler}
                                />
                            </div>
                        </div>
           
                       
                        <div className="table-responsive">
                            <table className="table table-container">
                                <thead className="consumer-view-log-heading-table">
                                    <tr>
                                        <th scope="col">File Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Download</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!data?.loading ?
                                        data.data.map((val, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope="row">{val?.name}</td>
                                                    <td className="description-box">{HelperUtil.removeNullString(val?.description)}</td>
                                                    <td>{ProductInfoUtil.getCurrentDate(val?.file_date)}</td>
                                                    <td className='source-code--column-three'>
                                                        <a
                                                            className='source-code-list--hyperlink'
                                                            ref={anchorTageRef}
                                                            onClick={() => onhandledownloadFile(val?.s3_access_key, val?.name, val?.file_type)}>
                                                            <i className="bi bi-cloud-download source-code-file-list--download"></i>
                                                        </a>
                                                    </td>
                                                    <td className="icon-column">
                                                        <div className='dashboard-container--trash-and--pencil'>
                                                            <div className="trash-icon-project icon-default-background" >
                                                                <FontAwesomeIcon className="" icon={faEdit} size="lg" onClick={(e) => onSwitchToEditPageRoute(e, val)} />
                                                            </div>
                                                            <div className="trash-icon-project icon-default-background">
                                                                <FontAwesomeIcon className="" icon={faTrash} size="lg" onClick={(e) => onhandleShow(e, val?.name, val?.company_asset_id)} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        )
                                        :
                                        <tr className="list--loading-spinner">
                                            <td colSpan={5}>
                                                <div className="spinner-border" role="status" />
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                            {isPagination && 
                                <div className='source-code-list-pagination--container'>
                                    <div className='source-code-list-pagination--wrapper'>
                                        <Pagination>
                                            <Pagination.Prev onClick={onhandlePrev} />
                                            {
                                                pageNumber?.map((val, index) =>{
                                               
                                                    return (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={(e) => onhandleSwitchPage(e, val)}
                                                        >
                                                            {val}
                                                        </Pagination.Item>
                                                    )
                                                })
                                            }
                                            {/* <Pagination.Ellipsis onClick={onhandleEllipse} /> */}
                                            <Pagination.Next onClick={onhandleNext} />
                                        </Pagination>
                                    </div>
                                </div>
                            }
                            <CustomModal
                                show={show}
                                onhandleClose={onhandleClose}
                                fileName={fileName}
                                onhandleDelete={onhandleDeleteFile}
                                id={id}
                            />

                        </div>

                    </div>
                }


            </div>
        );
    }
   
};

export default ConsumerSourceCodeFileList;
