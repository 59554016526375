import { UsersActionType } from "../constants/UsersActionType";

export const setUserSignUpSuccess = (success) => {
    return {
        type:UsersActionType.SET_SIGN_UP_SUCCESS,
        payload:success
    }
}

// export const setToken = (data = {}) => async (dispatch, getState) => {
//     try {
//         dispatch({ type:UsersActionType.TOKEN_REQUEST});
//         let apiResponse = await axios.post(BackEnd.GOOGLE_AUTH_URL, data);

//             const accessToken = response?.data?.accessToken;
//             const userName = response?.data?.userName;
//             const expiration = response?.data?.expiration_token
//             console.log(expiration);
//             if (accessToken) {
//                 console.log(response);
              
//                 history.push(endPoint.DASHBOARD_HOME_URL);
//             } 
       

//     } catch (error) {
//         dispatch({type: UsersActionType.TOKEN_REQUEST_FAILURE, payload: error.message})
//     }
// }
