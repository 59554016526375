import React, {  } from 'react';
import ProductInfoUtil from '../../../util/ProductInfoUtil';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCaretDown, faCaretUp, faEdit } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../loadSpinner/loadSpinner';
import './Table.css';

const Table = (
    {
        listItems,
        onhandleDeleteProductInfo,
        id,
        show,
        mfgDate,

        isSortColorProjectNameCarat,
        isSortProjectNameCaratInReverse,

        isSortLastActivityCaratInReverse,
        isSortLastActivityCarat,

        isSortDateCarat,
        isSortDateCaratInReverse,
        isSortTotalUnitCara,
        totalUnitExist,

        projectConfirmation,
        onhandleClose,
        onhandleShow,
        onhandleId,
        onhandleSortDate,
        onhandleSortLastActivty,
        onhandleSortProjectName,
        onSwitchToEditPageRoute,
        onhandleSortTotalUnit,
        isLoading

    }
) => {

    return (
        <div className="">
            <table className="table dashboard-table table-responsive" id="myTable" rules="cols">
                <thead className="table-head">
                    <tr>
                        <th scope="col" className="text-heading-after-start-date">
                            <div className="row dashboard-row">
                                <div className="col dashboard-column" onClick={onhandleSortProjectName}>
                                    Product Name {
                                        isSortColorProjectNameCarat ?
                                            "":
                                            isSortProjectNameCaratInReverse ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />
                                    }
                                </div>
                            </div>
                        </th>
                       
                        <th className="start-date-text" scope="col">
                            <div className="row dashboard-row">
                                <div className="col dashboard-column" onClick={onhandleSortProjectName}>
                                    Start Run Date 
                                </div>
                            </div>
                        </th>
                       
                        <th scope="col" className="text-heading-after-start-date">
                            <div className="row dashboard-row">
                                <div className="col dashboard-column" onClick={onhandleSortProjectName} >
                                    Last Run Date 
                                </div>
                            </div>
                        </th>
                        
                        <th scope="col" className="text-heading-after-start-date">
                            <div className="row dashboard-row">
                                <div className="col dashboard-column" onClick={onhandleSortProjectName}>
                                    Total Units&nbsp;
                                </div>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='table--tbody'>
                { isLoading ? 

                    <tr className="list--loading-spinner">
                        <td colSpan={5}>
                            <div className="spinner-border" role="status" />
                        </td>
                    </tr>

                    :

                    listItems.length > 0 ?
                        listItems?.map((item, index) =>

                            <tr
                                title="Click to view product details"
                                key={item.id} onClick={() =>
                                    onhandleId(item?.id)}
                                className="dashboard-table--rows"
                            >
                                <td className="table-row-text">
                                    {item?.project_name}
                                </td>
                               
                                <td className=" table-row-text">
                                    {ProductInfoUtil.getCurrentDate(item?.start_run_date)}
                                </td>
                                <td className="table-row-text">
                                    {ProductInfoUtil.getCurrentDate(item?.last_run_date)}
                                </td>
                                <td className="table-row-text">{item?.count}</td>
                                <td>
                                    <div className='dashboard-container--trash-and--pencil'>
                                        <div 
                                            title="Click to edit product details"
                                            onClick={(e) => onSwitchToEditPageRoute(e, item?.id)}
                                            className="trash-icon-project icon-default-background"
                                            >
                                            <FontAwesomeIcon className="" icon={faEdit} size="lg"/>
                                        </div>
                                        <div title="Click to delete product" className="trash-icon-project icon-default-background">
                                            <FontAwesomeIcon className="" icon={faTrash} size="lg" onClick={(e) => onhandleShow(e, item.id, item.project_name)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )

                    :

                    <tr>
                        <td colSpan={5} className="text-center">
                            No products
                        </td>
                    </tr>
                }
                </tbody>
                <Modal show={show} onHide={onhandleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Delete Product?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete {projectConfirmation}? </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onhandleClose}>
                            Close
                        </Button>
                        <Button variant="danger" className="project-delete--button" onClick={(e) => onhandleDeleteProductInfo(id)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </table>
        </div >

    )
}
export default Table;
