export const CompanyType = {
    // ADD_COMPANY_REQUEST: "ADD_COMPANY_REQUEST",
    // ADD_COMPANY_REQUEST_FAILURE: "ADD_COMPANY_REQUEST_FAILURE",
    // ADD_COMPANY_REQUEST_SUCCESS: "ADD_COMPANY_REQUEST_SUCCESS",

    FETCH_COMPANY_REQUEST: "FETCH_COMPANY_REQUEST",
    FETCH_COMPANY_REQUEST_FAILURE: "FETCH_COMPANY_REQUEST_FAILURE",
    FETCH_COMPANY_REQUEST_SUCCESS: "FETCH_COMPANY_REQUEST_SUCCESS",
    FETCH_COMPANY_REQUEST_FILTER_DATA:"FETCH_COMPANY_REQUEST_FILTER_DATA",

    FETCH_SINGLE_COMPANY_REQUEST:"FETCH_SINGLE_COMPANY_REQUEST",
    FETCH_SINGLE_COMPANY_REQUEST_FAILURE:"FETCH_SINGLE_COMPANY_REQUEST_FAILURE",
    FETCH_SINGLE_COMPANY_REQUEST_SUCCESS:"FETCH_SINGLE_COMPANY_REQUEST_SUCCESS",

    UPDATE_SINGLE_COMPANY_NAME:"UPDATE_SINGLE_COMPANY_NAME",
    UPDATE_SINGLE_COMPANY_CITY:"UPDATE_SINGLE_COMPANY_CITY",
    UPDATE_SINGLE_COMPANY_POSTAL_CODE:"UPDATE_SINGLE_COMPANY_POSTAL_CODE",
    UPDATE_SINGLE_COMPANY_STATE:"UPDATE_SINGLE_COMPANY_STATE",
    UPDATE_SINGLE_COMPANY_STREET_ADDRESS:"UPDATE_SINGLE_COMPANY_STREET_ADDRESS",
    UPDATE_SINGLE_COMPANY_OTHER_ADDRESS_DETAILS:"UPDATE_SINGLE_COMPANY_OTHER_ADDRESS_DETAILS",
    UPDATE_SINGLE_COMPANY_CREATED_AT:"UPDATE_SINGLE_COMPANY_CREATED_AT",
    UPDATE_SINGLE_COMPANY_CONTACT_FIRST_NAME:"UPDATE_SINGLE_COMPANY_CONTACT_FIRST_NAME",
    UPDATE_SINGLE_COMPANY_CONTACT_LAST_NAME: "UPDATE_SINGLE_COMPANY_CONTACT_LAST_NAME",
    UPDATE_SINGLE_COMPANY_CONTACT_EMAIL:"UPDATE_SINGLE_COMPANY_CONTACT_EMAIL",
    UPDATE_SINGLE_COMPANY_CONTACT_PHONE_NUMBER:"UPDATE_SINGLE_COMPANY_CONTACT_PHONE_NUMBER",
    UPDATE_SINGLE_COMPANY_CONTACT_TYPE_PHONE:"UPDATE_SINGLE_COMPANY_CONTACT_TYPE_PHONE",
    UPDATE_SINGLE_COMPANY_IMAGE_FILE_NAME:"UPDATE_SINGLE_COMPANY_IMAGE_FILE_NAME",
    UPDATE_SINGLE_COMPANY_REQUEST_RESET_SUCCESS:"UPDATE_SINGLE_COMPANY_REQUEST_RESET_SUCCESS",

    UPDATE_SINGLE_COMPANY_REQUEST:"UPDATE_SINGLE_COMPANY_REQUEST",
    UPDATE_SINGLE_COMPANY_REQUEST_FAILURE:"UPDATE_SINGLE_COMPANY_REQUEST_FAILURE",
    UPDATE_SINGLE_COMPANY_REQUEST_SUCCESS:"UPDATE_SINGLE_COMPANY_REQUEST_SUCCESS",

    DELETE_SINGLE_COMPANY_REQUEST:"DELETE_SINGLE_COMPANY_REQUEST",
    DELETE_SINGLE_COMPANY_REQUEST_FAILURE:"DELETE_SINGLE_COMPANY_REQUEST_FAILURE",
    DELETE_SINGLE_COMPANY_REQUEST_SUCCESS:"DELETE_SINGLE_COMPANY_REQUEST_SUCCESS",
    DELETE_SINGLE_COMPANY_RESET_SUCCESS: "DELETE_SINGLE_COMPANY_RESET_SUCCESS",

}
