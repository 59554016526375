import './UploadFile.css'
import { useDropzone } from 'react-dropzone';
import InputValidation from '../../InputValidation/InputValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const UploadFile = ({
    labelText,
    onDrop,
    fileValidation,
    fileName,
    fileError,
    isImage,
    onhandleDelete,
    isXcross,
    isCsv,
    imageSource
}) => {

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
        <div className="contract-attachment-container">
            <div
                className='contract-attachment--lable-text-x-cross'
            >
                <label className="upload-file-label-text"> {labelText}</label>
                {fileName && isXcross && <p className="">
                    <FontAwesomeIcon 
                        className="contract-attachment--x-cross"
                        onClick={onhandleDelete}
                        icon={faX}
                    ></FontAwesomeIcon>
                </p>}
            </div>

            <div {...getRootProps()}>
                {isImage ?
                    <input {...getInputProps()} accept="image/*" />
                    :
                    isCsv ? <input {...getInputProps()} accept=".csv" /> :
                        <input {...getInputProps()} />
                }

                {
                    isDragActive ?
                        <div className="upload-file">

                            <label className="custom-input-file-label">
                                <i className="bi bi-cloud-upload"></i> Upload

                            </label>
                            <p className="drop-file-text">Drop file here..</p>
                        </div>
                        :
                        /**
                         * 
                         * @filename && isImage
                         * singiflfe file
                         * check only @filename
                         */
                        <div className="upload-file">
                            {fileName && isImage ?
                                <div className='upload-file--label-text-image-wrapper'>
                                    {fileName && isImage && <img className="upload-file--image-source" src={imageSource} alt="User supplied upload"/>}
                                    <label className="custom-input-file-label">
                                        <i className="bi bi-cloud-upload"></i> Change Photo
                                    </label>
                                </div>
                                :

                                <label className="custom-input-file-label">
                                    {fileName ? <p className='upload-file-name--color'>{fileName}</p>
                                        :
                                        <div>
                                            <i className="bi bi-cloud-upload"></i> Upload
                                        </div>

                                    }

                                </label>
                            }

                            {fileValidation && <InputValidation
                                message="File cannot be blank"
                            />}
                            {fileError && <InputValidation
                                message={fileError}
                            />}

                        </div>

                }

            </div>

        </div>
    );
};

export default UploadFile;
