import ProductInfoUtil from "../../../../util/ProductInfoUtil";
import { getAnalyticsRoot } from "../../../nav/AnalyticsLink";
import './ConsumerLogList.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'

const ConsumerLogList = ({ 
    productionLogItems,
    loading,
    userClickedpage
 }) => {
    let logs;
    if (loading) {
        logs = (
            <tr className="warranty-list--loading-spinner">
                <td colSpan={8}>
                    <div className="spinner-border" role="status" />
                </td>
            </tr>
        )
    } else {
        logs = productionLogItems?.map((val, index) => {
            const id = productionLogItems[index].id;
            return (
                <tr key={index}>
                    <td className="table-content">
                        {ProductInfoUtil.getCurrentDateAndTime(productionLogItems[index].mfg_date)}</td>
                    <td className="table-content">{productionLogItems[index].sku?.toUpperCase()}</td>
                    <td className="table-content">{Number(productionLogItems[index].upc_code)}</td>
                    <td className="table-content">{productionLogItems[index].dsn}</td>
                    <td className="table-content">{Number(productionLogItems[index].hmcu_version)}</td>
                    <td className="table-content">{Number(productionLogItems[index].ack_version)}</td>
                    <td className="table-content">{productionLogItems[index].hardware_revision_numbers}</td>
                    <td className="table-content">
                        <FontAwesomeIcon 
                            className="chart-button cursor-pointer icon-default-background" 
                            icon={faChartColumn} 
                            //open the link in a new tab
                            onClick={() => window.open(`${getAnalyticsRoot()}/index.html?dsn=${productionLogItems[index].dsn}`, '_blank')}
                            title="View device in Analytics"
                        /></td>
                </tr>
            )
        })
    }

    return (
        <table className="table w-100" style={{marginBottom: '0px'}}>
            <thead className="consumer-log-list-heading">
                <tr>
                    <th className="heading-text" scope="col">Date</th>
                    <th className="heading-text" scope="col">SKU</th>
                    <th className="heading-text" scope="col">UPC</th>
                    <th className="heading-text" scope="col">Serial Number</th>
                    <th className="heading-text" scope="col">HCMU Version</th>
                    <th className="heading-text" scope="col">ACK Version</th>
                    <th className="heading-text" scope="col" style={{whitespace: "nowrap"}}>HW Rev.</th>
                    <th className="heading-text" scope="col"></th>
                </tr>
            </thead>
            <tbody>

                {logs}
            </tbody>
        </table>
    )
}

export default ConsumerLogList;
