import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown, DropdownButton, Pagination } from 'react-bootstrap'

import { useContext } from 'react';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import { getAnalyticsRoot } from '../../../nav/AnalyticsLink';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { saveAs } from "file-saver";
import '../ConsumerWarranty/ConsumerWarranty.css'
import './ConsumerSearch.css'
import NumberInput from '../NumberInput';
import axios from 'axios'
import ProductInfoUtil from '../../../../util/ProductInfoUtil'
import ConsumerDetailRollupLogList from '../ConsumerDetailRollup/ConsumerDetailRollupLogList';
import DeviceSearchEndPoint from '../../../../ENDPOINT/back-end-endpoint/DeviceSearchEndPoint'
import { useDispatch } from 'react-redux';
import ErrorHandling from '../../../errorHandling/error';
import ReprogrammedChart from './charts/ReprogrammedChart';
import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import ProductInfoEndPoint from '../../../../ENDPOINT/back-end-endpoint/ProductInfoEndPoint'
import ConsumerController from '../../../../controller/ConsumerController';

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn, faFilter, faCog, faX, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import { TokenFileWebIdentityCredentials } from 'aws-sdk';

const ConsumerWarranty = () => {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');
    const PRODUCT = "Product",
        SKU = "SKU"
    
    const [overAllUsers, setOverAllUsers] = useState(0);
    const [projectName, setProjectName] = useState(PRODUCT);
    const [projectId, setProjectId] = useState("");
    const [projectNameList, setProjectNameList] = useState([]);

    const [warrantyUserList, setWarrantyUserList] = useState([]);

    const [showEllipseModal, setShowEllipseModal] = useState(false);
    const [showSourceLogInfo, setShowSourceLogInfo] = useState(false);
    const [logData, setLogData] = useState(null);
    const [enteredPage, setEnteredPage] = useState("");
    const [skusItem, setSkusItem] = useState(query.get("skus") === null ? "SKU" : query.get("skus"));
    const [currentSerialNumber, setCurrentSerialNumber] = useState(null);

    const [productionInfo, setFilterWarrantyUserList] = useState([]);
    // const [selectedFile, setSelectedFile] = useState([]);

    const [fileName, setFileName] = useState("Upload");
    const [stopIntervalFetching, setStopIntervalFetching] = useState(true);
    const [timeOutReact, setTimeOutReact] = useState(true);
    const [isfetchingApi, setIsFetchingApi] = useState(true);

    //productINFoid is @SKU
    const [sku, setsku] = useState(SKU);
    const [skuList, setSkuList] = useState([]);
    const [serialNumber, setserialNumber] = useState("");
    const [UPC, setUPC] = useState("");
    const [HWRev, setHWRev] = useState("");
    const [HMCUVer, setHMCUVer] = useState("");
    const [ACKVer, setACKVer] = useState("");

    const [modalAudienceId, setModalAudienceId] = useState("");
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);
    const [uploadedLoading, setuploadedLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(true);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [pageNumber, setPageNumber] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isWarrantyUserLimit, setIsWarrantyUserLimit] = useState(false);

    const [showSetting, setShowSetting] = useState(false);
    const [pageSize, setPageSize] = useState(12);
    const [DSNDuplication, setDSNDuplication] = useState("all");

    const [isDefaultGet, setIsDefaultGet] = useState(true);

    const [showFilters, setShowFilters] = useState(false);
    const [dateInputType, setDateInputType] = useState({ start: "text", end: "text" });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [chunks, setChunks] = useState([1]);
    const [projectDictionary, setProjectDictionary] = useState({});
    const [narrowLog, setNarrowLog] = useState('');
    const [narrowLogName, setNarrowLogName] = useState('');

    useEffect(async () => {
        setIsFetchingApi(false);
        apiGetFilterItem(currentPage);
    }, [currentPage, pageSize, DSNDuplication]);

    useEffect(() => {
        setChunks(Array.from({ length: Math.ceil(overAllUsers / 10000) }, (_, i) => i + 1));
    }, [overAllUsers]);

    useEffect(() => {
        if (narrowLog !== '') {
            setLoading(false);
            apiGetFilterItem(currentPage);
        }
    }, [narrowLog]);

    useEffect(() => {
        if (projectName !== 'Product') {
            setSkuList(projectDictionary[projectName]);
        } else {
            setSkuList([]);
            for (let project in projectDictionary) {
                setSkuList([...skuList, ...projectDictionary[project]]);
            }
        }
    }, [projectName, projectId])

    let firstLoad = true;
    useEffect( async () => {
        if (firstLoad) {
            firstLoad = false;
            try {
                const url = ProductInfoEndPoint.DASHBOARD_PRODUCT_INFO_BY_ID + param?.companyId;
                let apiResponse = await axios.get(url, { headers: { "authorization": `Bearer ${token}` } }).catch((error) => {
                    setError(apiResponse?.data.error);
                    console.log(error);
                });
                if (apiResponse?.data?.error) { // if the user is not yet associated with an account
                    setError(apiResponse?.data.error);
                }

                const projectList = new Set();
                const newSkuList = [];
                const updatedProjectDictionary = { ...projectDictionary };

                for (const row of apiResponse?.data?.productInfo) {
                    const projectName = row?.project_name;
                    const projectId = row?.id;

                    projectList.add(projectName);

                    let apiResponse = await ConsumerController.fetchDetailByIdWithoutLogItem(param?.companyId, projectId, token);
                    if (apiResponse?.status === 200) {
                        const skuArray = apiResponse?.data?.skus.map(sku => sku.skus);
                        newSkuList.push(...skuArray);

                        if (updatedProjectDictionary[projectName]) {
                            updatedProjectDictionary[projectName].push(...skuArray);
                        } else {
                            updatedProjectDictionary[projectName] = skuArray;
                        }
                    }
                }

                let uniqueProjects = Array.from(projectList);
                setProjectNameList(uniqueProjects);
                setSkuList(newSkuList);
                setProjectDictionary(updatedProjectDictionary);
            } catch (error) {
                console.log(error);
                setError(error?.message);
            }
        }
    }, []);

    const param = useParams();
    const companyId = param?.companyId;

    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
    }

    let startDateQuery = query.get("start_date");
    let endDateQuery = query.get("end_date");
    let skusItemQuery = query.get("skus");

    const SKUS_EXIST = skusItemQuery !== "SKU" && skusItemQuery !== null;

    const history = useHistory();


    useEffect(() => {


        setLoading(false)

        if (!isfetchingApi) {
            setIsFetchingApi(true);
        } else {
            if (isDefaultGet) {
                setPageNumber();
                setIsDefaultGet(false);
            }

        }
        return () => {
            clearInterval(stopIntervalFetching);
            clearTimeout(timeOutReact)
        };
    }, [isfetchingApi])

    window.addEventListener('resize', () => {
        setScreenWidth(window.innerWidth);
    });

    const handleToggle = (column) => {
        if (screenWidth < 768) {
            if (selectedColumn === column) {
                setSelectedColumn('');
            } else {
                setSelectedColumn(column);
            }
        }
    };
    
    const showLogInfo = (id) => {
        setModalLoading(true);
        setShowSourceLogInfo(true);
        let url =`${DeviceSearchEndPoint.DEVICESEARCH_END_POINT}${companyId}/${id}`;
        axios.get(url, header).then((response) => {
            const data = response?.data?.data;
            setLogData(data);
            setModalLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const onhandleChangeserialNumber = (e) => {
        setserialNumber(e.target.value)
        // setIsFetchingApi(false);
    };
    const onhandleDownloadDataIntoCsv = (columnList = [], range) => {
        const HEADER = ["Date", "SKU", 'UPC', 'DSN', "HMCUVER", 'ACKVER', 'HWREV']
        let rows = [
            HEADER,
        ];
        let csvContent = ""
        if (columnList.length > 0) {
            for (let data of columnList) {
                rows.push([
                    data?.mfg_date,
                    data?.skus,
                    data?.upc_code,
                    data?.dsn,
                    data?.hmcu_version,
                    data?.ack_version,
                    data?.hardware_revision_numbers
                ])
    
            }
        }
        rows.forEach(function (rowArray) {
            csvContent += rowArray + "\n";
        });
    
        const fileType = { type: 'text/csv;charset=utf-8' }
        const blob1 = new Blob([csvContent], fileType)
        const fileName = `DEVICE_SEARCH_${range}.csv`; // Include the range in the filename
        const url = window.URL.createObjectURL(blob1);
        saveAs(
            url,
            fileName
        )
    }
    const handleDownloadSelect = async (pageNumber) => {
        let url = `${DeviceSearchEndPoint.DEVICESEARCH_END_POINT}${companyId}`;
        let params = {
            serial_number: serialNumber,
            sku: sku,
            projectName: projectName,
            projectId: projectId,
            startDate: startDate,
            endDate: endDate,
            UPC: UPC,
            HWRev: HWRev,
            HMCUVer: HMCUVer,
            ACKVer: ACKVer,
            sourceLog: narrowLog,
            DSNDuplication: DSNDuplication,
            page: pageNumber,
            limit: 10000
        }
        try {
            let apiResponse = await axios.post(url, params, header);
            const data = apiResponse?.data?.data
            const range = `${(pageNumber - 1) * 10000 + 1}-${pageNumber * 10000}`; // Calculate the range
            onhandleDownloadDataIntoCsv(data?.deviceList, range);
        } catch (error) {
            console.log(error.message);
        }
    }
    const onClickDropDownSku = (e, index, val) => {
        setsku(val);
    }
    const onClickDropProject = (e, index, val) => {
        setProjectName(val)
        setProjectId(index)
    }
    const onFocusDateInput = (type) => {
        setDateInputType(prev => ({ ...prev, [type]: 'date' }));
    }
    const breadCrumbs = [
        {
            link: "",
            text: "Search Devices"
        }
    ]

    const { setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, []);
    const pageNumberBaseonData = (overAll) => {
        let sizeOfPage = pageSize;
        let lengthOfData = Math.ceil(overAll / sizeOfPage);

        if (currentPage > lengthOfData) {
            setCurrentPage(lengthOfData);
        }

        return Array.from({ length: lengthOfData }, (v, k) => k + 1);
    }
    const togglePagination = (overAll) => {
        if (overAll > pageSize) {//show pagination
            setIsWarrantyUserLimit(true)
        } else if (overAll < pageSize) { // do not show pagination
            setIsWarrantyUserLimit(false);
        }
    }

    /**
     * 
     * @API
     */
    const apiGetFilterItem = async (numberOfPage = "") => {

        let url =`${DeviceSearchEndPoint.DEVICESEARCH_END_POINT}${companyId}`;

        let params = {
            serial_number: serialNumber,
            sku: sku,
            projectName: projectName,
            projectId: projectId,
            startDate: startDate,
            endDate: endDate,
            UPC: UPC,
            HWRev: HWRev,
            HMCUVer: HMCUVer,
            ACKVer: ACKVer,
            sourceLog: narrowLog,
            DSNDuplication: DSNDuplication,
            page: numberOfPage,
            limit: pageSize
        }
        if (!numberOfPage) {
            params.page = 1;
        }

        try {
            let apiResponse = await axios.post(url, params, header);
            const data = apiResponse?.data?.data
            let overAll = parseInt(data?.overAll)
            setLoading(true);
            setFilterWarrantyUserList(data?.deviceList);
            togglePagination(overAll);
            setOverAllUsers(parseInt(data?.overAll));
            setPageNumber(pageNumberBaseonData(overAll));
            // setProjectNameList(data?.projectList);
            // setSkuList(data?.skuList);
        } catch (error) {
            console.log(error.message);
            setLoading(true)
            setError(error?.message);
        }

    }
    const onhandleSubmitFilter = async (e) => {
        e?.preventDefault();
        setLoading(false);
        apiGetFilterItem(currentPage);
    }

    /**
     * @ONHANDLE @FUNCTIONS
     */
    // const onhandleChangeFileHandler = (e) => {
    //     setFileName(e.target.files[0]?.name)
    //     setSelectedFile(e.target.files)
    // }

    const onhandleResetAllFilterInputToDefault = () => {
        setsku("SKU")
        setserialNumber("");
        setUPC("");
        setHWRev("");
        setHMCUVer("");
        setACKVer("");
        setProjectName("Product")
        setStartDate("");
        setEndDate("");
        setCurrentPage(1);
        setNarrowLog('');
        setNarrowLogName('');
        dateInputType.start = "text";
        dateInputType.end = "text";
        // apiGetFilterItem(1);
    }
    const onhandleChangeStartDate = (e) => {
        setStartDate(e.target.value)
        // setIsFetchingApi(false);
    }
    const onhandleChangeEndDate = (e) => {
        setEndDate(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleChangeACKVer = (e) => {
        setACKVer(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleChangeHMCUVer = (e) => {
        setHMCUVer(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleChangeHWRev = (e) => {
        setHWRev(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleChangeUPC = (e) => {
        setUPC(e.target.value);
        // setIsFetchingApi(false);
    }
    const onhandleNext = () => {
        let nextPageNumber = Math.min(currentPage + 1, pageNumber.length);
        setCurrentPage(nextPageNumber);
    }
    const onhandlePrev = () => {
        let prevPageNumber = Math.max(currentPage - 1, 1);
        setCurrentPage(prevPageNumber);
    }
    const handleEllipseClick = () => {
        setShowEllipseModal(true);
    }
    const handleModalClose = () => {
        setShowEllipseModal(false);
    }
    const handlePageSubmit = () => {
        let newPage = Math.max(1, Math.min(parseInt(enteredPage), Math.ceil(overAllUsers / pageSize)));
        setCurrentPage(newPage);
        setShowEllipseModal(false);
    }
    const calcPagesToShow = () => {
        let start = Math.max(currentPage - 2, 1);
        let end = Math.min(start + 4, Math.ceil(overAllUsers / pageSize));
        start = Math.max(end - 4, 1);
        let pageList = Array.from({ length: end - start + 1 }, (_, i) => start + i);
        if (currentPage > 3 && Math.ceil(overAllUsers / pageSize) > 5) {
            pageList.unshift(1);
        }
        return pageList;
    }
    // const onhandleSaveChangeSetting = () => {
    //     setShowSetting(false);
    //     // clearInterval(stopIntervalFetching);
    //     setLoading(false)
    //     let endPoint = DeviceSearchEndPoint.DEVICESEARCH_END_POINT + companyId + "?" + "page=" + pageNumber + "&limit=" + pageSize;
    //     apiGetFilterItem(currentPage);
    // }
    const onhandleCloseSetting = () => {
        setShowSetting(false);
    }
    const onhandleShowSetting = () => {
        setShowSetting(true);
    }
    const onhandleChangePageSize = (input) => {
        setPageSize(input);
    }
    const onHandleDSNDuplication = (input) => {
        setDSNDuplication(input);
    }
    const navigateToProductionLogs = (productId, logId) => {
        localStorage.setItem('projectId', productId);
        history.push(`${endPoint.DASHBOARD_VIEWLOG_URL}${param?.companyId}/${productId}?logId=${logId}`);
    }
    const narrowByLog = (logName, logId) => {
        setNarrowLog(logId);
        setNarrowLogName(logName);
        setShowSourceLogInfo(false);
    }

    if (error) {
        return <ErrorHandling error={error} />;
    }

    return (

        <div className="mainContent">

            <Modal show={showSetting} onHide={onhandleCloseSetting}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='warranty-list-settings--list'>
                        <div>
                            <DropdownButton id="dropdown-basic-button" title={`${pageSize} Results Per Page`}
                                className='consumer-warranty-number-of-files-per--page'
                            >
                                <Dropdown.Item onClick={() => onhandleChangePageSize(12)}>12</Dropdown.Item>
                                <Dropdown.Item onClick={() => onhandleChangePageSize(25)}>25</Dropdown.Item>
                                <Dropdown.Item onClick={() => onhandleChangePageSize(50)}>50</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div>
                            <DropdownButton id="dropdown-basic-button" title={`DSN Duplication: ${DSNDuplication}`}
                                className='consumer-warranty-number-of-files-per--page'
                            >
                                <Dropdown.Item onClick={() => onHandleDSNDuplication("all")}>Show all results</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleDSNDuplication("duplicate")}>Only show reprogrammed DSNs</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleDSNDuplication("unique")}>Only show non-reprogrammed DSNs</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleDSNDuplication("latest")}>Only show unique devices</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onhandleCloseSetting}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={onhandleSaveChangeSetting}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>

            <Modal show={showSourceLogInfo} onHide={() => setShowSourceLogInfo(false)} className="wide-modal">
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: 'inline' }}>Source Log: {modalLoading ? <i>loading...</i> : (logData && logData?.name)} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="tall-modal">
                    { modalLoading ? <div style={{height: "22rem", display: "inline-block", width: "100%"}}><LoadingSpinner /></div> :
                        (logData && 
                            <>
                                {logData.description && 
                                    <aside>
                                        Description: 
                                        <div className='description-box-modal'>
                                            <i>{logData.description}</i><br/>
                                        </div>
                                    </aside>
                                }
                                <div className="modal-grid">
                                    <div className="modal-tables table-scroll">
                                        {logData.file_date && logData.created_at && 
                                        <table className="table warranty-list-table">
                                            <thead className="warranty-list-table--heading">
                                                <tr>
                                                    <th scope="col">File date</th>
                                                    <th scope="col">Uploaded</th>
                                                </tr>
                                            </thead>
                                            <tbody className='warranty-list-table--body'>
                                                <tr className="row-hover">
                                                    <td scope="row">{new Date(logData.file_date).toLocaleDateString()}</td>
                                                    <td>{new Date(logData.created_at).toLocaleString()}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        }
                                        {logData.total_data && 
                                        <table className="table warranty-list-table">
                                            <thead className="warranty-list-table--heading">
                                                <tr><th scope="col">Entries</th></tr>
                                            </thead>
                                            <tbody className='warranty-list-table--body'>
                                                <tr className="row-hover"><td scope="row">{logData.total_data}</td></tr>
                                            </tbody>
                                        </table>
                                        }
                                        {logData?.average_programming_time_seconds != 0 && logData?.average_times_reprogrammed != 0 &&
                                        <table className="table warranty-list-table">
                                            <thead className="warranty-list-table--heading">
                                                <tr>
                                                    <th scope="col">Average programming time</th>
                                                    <th scope="col">% Reprogrammed</th>
                                                </tr>
                                            </thead>
                                            <tbody className='warranty-list-table--body'>
                                                <tr className="row-hover">
                                                    <td scope="row">{logData.average_programming_time_seconds} seconds</td>
                                                    <td>{(logData.average_times_reprogrammed * 100).toFixed(2)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                    <div>
                                        {logData?.average_times_reprogrammed != 0 && logData.total_data &&
                                            <div className="reprogrammed-chart">
                                                <ReprogrammedChart
                                                    labels={['Reprogrammed', 'Total']}
                                                    data={[logData.average_times_reprogrammed, 1 - logData.average_times_reprogrammed]}
                                                    chartTitle="% Reprogrammed in log"
                                                    centerLabel={logData.total_data}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {logData && !modalLoading &&
                    <a
                        className="nav-link cursor-pointer"
                        onClick={() => navigateToProductionLogs(logData?.product_info_id, logData?.id)}
                    >
                        Production Logs
                    </a>}
                    <div className="modal-buttons">
                        <Button variant="primary" disabled={modalLoading || !logData} className="btn narrow-log-btn" onClick={() => narrowByLog(logData?.name, logData?.id)}>Only show results from this log</Button>
                        <Button variant="secondary" onClick={() => setShowSourceLogInfo(false)}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showEllipseModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Page Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NumberInput enteredValue={enteredPage} setEnteredValue={setEnteredPage} onHandleSubmit={handlePageSubmit}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>Close</Button>
                    <Button variant="primary" onClick={handlePageSubmit}>Go to page</Button>
                </Modal.Footer>
            </Modal>

            <div className='warranty-list-user--container'>

                <div className='warranty-list--container-table-header drop-shadow'>
                    <div className='warranty-list-wrapper--week-month-all-time'>
                        <div className='warranty-list-container--week-month-all-time'>
                            <div className='warranty-list-all-time--box'>
                                <div className='warranty-list-week-box--week-all-time'>Total</div>
                                <div className='warranty-list-input--number'>{overAllUsers}</div>
                            </div>
                        </div>
                    </div>
                    <div className='warranty-list-filter-settings'>
                        <div className='warranty--export-button'>
                            <Dropdown onSelect={handleDownloadSelect}>
                                <Dropdown.Toggle title="Click to download filtered device info as CSV" className='warranty--export-table-button btn'>
                                    Export
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {chunks.map((chunk, index) => 
                                        <Dropdown.Item eventKey={index + 1} key={index}>
                                            {`Download entries ${index * 10000 + 1} - ${(index + 1) * 10000}`}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <FontAwesomeIcon 
                            className="filter-button cursor-pointer icon-default-background" 
                            icon={faFilter} 
                            onClick={() => setShowFilters(prev => !prev)}
                        />
                        <FontAwesomeIcon
                            className="warranty-list-setting cursor-pointer icon-default-background"
                            onClick={onhandleShowSetting}
                            icon={faCog}
                        />
                    </div>
                </div>
                <div className={`filter-boxes ${showFilters ? 'show' : ''}`}>
                    {
                        <form
                            className='warranty-list-container--filter'
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            onSubmit={onhandleSubmitFilter}>
                            <div className='devices-list-wrapp--drop-down-and-input'>
                                <div className="warranty-vertical-list">
                                    <div className="narrow-cursor-pointer" onClick={() => handleToggle('dateCreated')}>
                                        <h3>Date created</h3>
                                        <hr/>
                                    </div>
                                    {(selectedColumn === 'dateCreated' || screenWidth >= 768) && (
                                        <>
                                            <div className='warranty-list-tuple'>
                                                <div>
                                                    <input
                                                        className="input-field"
                                                        type={dateInputType.start}
                                                        onFocus={() => onFocusDateInput('start')}
                                                        onChange={onhandleChangeStartDate}
                                                        value={startDate}
                                                        placeholder={dateInputType.start === 'text' ? 'Start Date' : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <div>
                                                    <input
                                                        className="input-field"
                                                        type={dateInputType.end}
                                                        onFocus={() => onFocusDateInput('end')}
                                                        onChange={onhandleChangeEndDate}
                                                        value={endDate}
                                                        placeholder={dateInputType.end === 'text' ? 'End Date' : ''}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="warranty-vertical-list">
                                    <div className="narrow-cursor-pointer" onClick={() => handleToggle('userInfo')}>
                                        <h3>Hardware info</h3>
                                        <hr/>
                                    </div>
                                    {(selectedColumn === 'userInfo' || screenWidth >= 768) && (
                                        <div className="filter-break">
                                            <div className='warranty-list-tuple'>
                                                <input
                                                    type="text"
                                                    className='warranty-list-serialNumber--input input-field'
                                                    onChange={onhandleChangeserialNumber}
                                                    value={serialNumber}
                                                    placeholder='DSN'
                                                />
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <input
                                                    type="text"
                                                    className='warranty-list-zip-code--input input-field'
                                                    onChange={onhandleChangeUPC}
                                                    value={UPC}
                                                    placeholder='UPC'
                                                />
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <input
                                                    type="text"
                                                    className='warranty-list-zip-code--input input-field'
                                                    onChange={onhandleChangeHWRev}
                                                    value={HWRev}
                                                    placeholder='HW Rev'
                                                />
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="warranty-button-dropdown" id="dropdown-button-dark-example1">
                                                        {projectName}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                        {projectNameList.length > 0 &&
                                                            projectNameList.map((val, index) =>
                                                                <Dropdown.Item key={index}
                                                                    onClick={(e) => onClickDropProject(e, val?.id, val)}>
                                                                    {val}
                                                                </Dropdown.Item>
                                                            )
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <Dropdown>
                                                    <Dropdown.Toggle className={projectName !== 'Product' ? "warranty-button-dropdown" : "warranty-button-dropdown disabled"}>
                                                        {sku?.toUpperCase()}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                        {skuList.length > 0 && skuList.map((val, index) =>
                                                            <Dropdown.Item key={index}
                                                                onClick={(e) => onClickDropDownSku(e, index, val)}>
                                                                {val?.toUpperCase()}
                                                            </Dropdown.Item>
                                                        )
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <input type="text" className='warranty-list-sku--input' onChange={onhandleChangesku}/> */}
                                            </div>
                                            {narrowLog && <div className='warranty-list-tuple'>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="warranty-button-dropdown disabled">
                                                        {narrowLogName}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='warranty-list-drop-down--scroll'>
                                                            <Dropdown.Item>
                                                                {narrowLogName}
                                                            </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>}
                                        </div>
                                    )}
                                </div>
                                <div className="warranty-vertical-list">
                                    <div className="narrow-cursor-pointer" onClick={() => handleToggle('location')}>
                                        <h3>Software info</h3>
                                        <hr/>
                                    </div>
                                    {(selectedColumn === 'location' || screenWidth >= 768) && (
                                        <>
                                            <div className='warranty-list-tuple'>
                                                <input
                                                    type="text"
                                                    className='warranty-list-zip-code--input input-field'
                                                    onChange={onhandleChangeHMCUVer}
                                                    value={HMCUVer}
                                                    placeholder='HMCU Ver'
                                                />
                                            </div>
                                            <div className='warranty-list-tuple'>
                                                <input
                                                    type="text"
                                                    className='warranty-list-zip-code--input input-field'
                                                    onChange={onhandleChangeACKVer}
                                                    value={ACKVer}
                                                    placeholder='ACK Ver'
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="last-input">
                                    <div className="close-filters">
                                        <FontAwesomeIcon
                                            className="cursor-pointer icon-default-background"
                                            onClick={() => setShowFilters(false)}
                                            icon={faX}
                                        />
                                    </div>
                                    <div className="search-reset">
                                        <button className='warranty-button-list--filter btn' onClick={onhandleSubmitFilter} >
                                            <span className='button-text'>Search</span>
                                        </button>
                                        <button className='warranty-button-list--reset btn' onClick={() => onhandleResetAllFilterInputToDefault()} >
                                            <span className='button-text'>Reset</span>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    }
                </div>
                <div className="table-container-fs">
                    <table className="table warranty-list-table ">
                        <thead className="warranty-list-table--heading">
                            <tr>
                                <th scope="col">Mfg. Date</th>
                                <th scope="col">SKU</th>
                                <th scope="col">UPC</th>
                                <th scope="col">DSN</th>
                                <th scope="col">HMCU Ver.</th>
                                <th scope="col">ACK Ver.</th>
                                <th scope="col">HW Rev.</th>
                                <th scope="col" className='text-end'></th>
                            </tr>
                        </thead>
                        <tbody className='warranty-list-table--body'>
                            {loading ? (
                                productionInfo.length > 0 ? (
                                    productionInfo.map((val, index) =>
                                        <tr key={val?.id + "_" + index} className="row-hover">
                                            <td scope="row">{ProductInfoUtil.getShortCurrentDateAndTime(val?.mfg_date)}</td>
                                            <td>{val?.skus?.toUpperCase()}</td>
                                            <td>{val?.upc_code}</td>
                                            <td>{val?.dsn}</td>
                                            <td>{val?.hmcu_version}</td>
                                            <td>{val?.ack_version}</td>
                                            <td>{val?.hardware_revision_numbers}</td>
                                            <td className="text-end">
                                                <div className="column-two-items">
                                                    <a className="" title="View Source Log" onClick={() => showLogInfo(val?.production_log_id)}>
                                                        <FontAwesomeIcon className="icon-default-color icon-default-background" size="lg" icon={faFileAlt} />
                                                    </a>
                                                    <a className="" title="View device in Analytics" target="_blank" rel="noopener noreferrer" href={`${getAnalyticsRoot()}/index.html?dsn=${val?.dsn}`} id={val?.dsn}>
                                                        <FontAwesomeIcon className="icon-default-color icon-default-background" size="lg" icon={faChartColumn} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr key={"NORECORDS"} className="row-hover">
                                        <td>No records match.</td>
                                        {Array.from({ length: 7 }, () => <td />)}
                                    </tr>
                                )
                            ) : (
                                <tr className="warranty-list--loading-spinner">
                                    <td colSpan={8}>
                                        <div className="spinner-border" role="status" />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='warranty-list-footer drop-shadow'>
                    {isWarrantyUserLimit &&
                        <Pagination>
                            <div className="pagination-organize">
                                <Pagination.Prev onClick={onhandlePrev} disabled={currentPage === 1} />
                                {
                                    calcPagesToShow().map(page =>
                                        <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>{page}</Pagination.Item>
                                    )
                                }
                                {Math.ceil(overAllUsers / pageSize) > 5 && <Pagination.Ellipsis onClick={handleEllipseClick} />}
                                {Math.ceil(overAllUsers / pageSize) > 5 && currentPage < Math.ceil(overAllUsers / pageSize) - 2 && <Pagination.Item onClick={() => setCurrentPage(Math.ceil(overAllUsers / pageSize))}>{Math.ceil(overAllUsers / pageSize)}</Pagination.Item> }
                                <Pagination.Next onClick={onhandleNext} disabled={currentPage === Math.ceil(overAllUsers / pageSize)} />
                            </div>
                        </Pagination>
                    }
                </div>
            </div>
        </div>
    );




};

export default ConsumerWarranty;
