import React, {useEffect, useState} from 'react';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import MailChimpEndPoint from '../../../../ENDPOINT/back-end-endpoint/MailChimpEndPoint';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import axios from 'axios';

const ConsumerWarrantyMailChimp = ({
    onhanldeChangeMailChimpServer,
    onhandleChangeMailChimpApiKey,
    onhandleChangeMailChimpAudienceId,
    serverErrorMessage,
    apiKeyErrorMessage,
    audienceIdErrorMessage,
    integrateMailChimpApi,
    onhandleClosedSwitchScreen,
    mailChimpLoading,
    header,
    stopIntervalFetching,
    mailChimpid

}) => {
    /**
     * @STATE
     */
    const [accountIdList, setAccountIdList] = useState([]);
    const [mailChimpId, setMailChimpId] = useState("");
    const [accountId, setAccountId] = useState("");
    const [serverName, setServerName] = useState("");
    const [apikey, setApiKey] = useState("");// No of Records to be displayed on each page 
    const [changeColorLive, setChangeColorLive] = useState(false);
  
    const [loadingGetRequest, setLoadingGetRequest] = useState(true);
    const [loadingUpdateRequest, setLoadingUpdateRequest] = useState(true);
    const [triggerUseEffectAfterPutRequest, settriggerUseEffectAfterPutRequest] = useState(false);
    
    /**
     * @API
     */
    const getMailChimpData = async () => {
        setLoadingGetRequest(false);
        const END_POINT = MailChimpEndPoint.MAIL_CHIMP + localStorage.getItem("companyId");
        try {
            let apiResponse = await axios.get(END_POINT, header);
            const data = apiResponse?.data?.data;
            setAccountIdList(data)
            setMailChimpId(data[0]?.id);
            setLoadingGetRequest(true);
            settriggerUseEffectAfterPutRequest(true);
        } catch (error) {
            console.log(error.message);
            setLoadingGetRequest(true);
        }
    }
    const updateMailChimpData = async () => {
        setLoadingUpdateRequest(false);
        try {
            const data = {
                accountId,
                serverName,
                apikey
            }
            const END_POINT = MailChimpEndPoint.MAIL_CHIMP + mailChimpId
            await axios.put(END_POINT, data, header)
            setLoadingUpdateRequest(true)
            settriggerUseEffectAfterPutRequest(false);
            resetInpuToEmpty();
        } catch (error) {
            console.log(error.message);
            setLoadingUpdateRequest(true);
        }
    }

    const testMailChimpConnection = async () => {
        const data = {
            accountId: accountIdList[0]?.audience_id,
            serverName: accountIdList[0]?.server,
            apikey: accountIdList[0]?.api_key
        }
        const ENDPOINT = MailChimpEndPoint.TEST_MAIL_CHIMP_CONNECTION + localStorage.getItem("companyId");
        try {
            let apiResponse = await axios.post(ENDPOINT, data, header);
            alert("connection live")
            setChangeColorLive(true);
        } catch (error) {
            alert("connection disconnected")
            console.log(error.message);
        }
    }
    /**
     * @ONHANDLECHANGE
     */
    const onhandleChangeAccountId = (e) => setAccountId(e.target.value);
    const onhandleChangeServerName = (e) => setServerName(e.target.value);
    const onhandleChangeApiKey = (e) => setApiKey(e.target.value);
    /**
     * @HELPER @FUNCTION
     */
    const resetInpuToEmpty = () => {
        setAccountId("");
        setServerName("");
        setApiKey("");

    }

    useEffect(() => {
        clearInterval(stopIntervalFetching);
        getMailChimpData();
    }, [triggerUseEffectAfterPutRequest])
    const accountIdExist = accountIdList.length > 0;
    if (accountIdExist){
        return(
            <div className='integration-center'>
                <div className='existence-mail-chimp-account--wrapper'>
                    <div className='existence-mail-chimp-account--first-column'>
                        <div className='mail-chimp-warranty--text'>
                            Current Integration Account ID
                        </div>
                        <div className='mail-chimp-warranty--text'>
                            AccountId: {accountIdList[0]?.audience_id}
                        </div>
                        <div className='mail-chimp-warranty--text'>
                            Api Key: {accountIdList[0]?.api_key}
                        </div>
                        <div className='mail-chimp-warranty--text'>
                            Server Name: {accountIdList[0]?.server}
                        </div>
                        <div className='mail-chimp-warranty--connection-wrapper'>
                            <button 
                                className='mt-2 btn btn-primary test'
                                onClick={testMailChimpConnection}
                            >
                                <div>  Test Connection</div>
                                <div className='mail-chimp-warranty--connection-wrapper-circle'>
                                    <div className='mail-chimp-warranty--status-circle' style={{ backgroundColor: changeColorLive ? "green" : "white"}}></div>
                                </div>
                            </button>
                            
                        </div>
                    </div>
                    <div className='existence-mail-chimp-account--second-column'>
                        <div className='mail-chimp-warranty--text'>
                            If you would like to change the integration point. 
                            <br></br>
                            Fill out the information on the right.
                        </div>
                    </div>
                    <div className='mail-chimp-warranty--wrapper'>
                        <p className='mail-chimp-warranty--text'>
                            To have warranty user information added to MailChimp,
                            <br></br>
                            please provide the following information:
                        </p>
                        <div className='mail-chimp-warranty--container'>
                            <label className='mail-chimp-warranty--account-id'>Account ID:
                                <input
                                    className='mail-chimp-warranty--input'
                                    type="text"
                                    value={accountId}
                                    // value={accountId[0]?.audience_id}
                                    onChange={onhandleChangeAccountId}
                                />
                            </label>
                            {audienceIdErrorMessage && <div className='warranty-list--error-message'>{audienceIdErrorMessage}</div>}
                            <label
                                className='mail-chimp-warranty--api-key'>
                                Api Key
                                <input
                                    className='mail-chimp-warranty--input'
                                    type="text"
                                    value={apikey}
                                    // value={accountId[0]?.api_key}
                                    onChange={onhandleChangeApiKey}
                                />
                            </label>
                            {apiKeyErrorMessage && <div className='warranty-list--error-message'>{apiKeyErrorMessage}</div>}
                            <label
                                className='mail-chimp-warranty--server-name'
                            >Server Name
                                <input
                                    className='mail-chimp-warranty--input'
                                    type="text"
                                    value={serverName}
                                    // value={accountId[0]?.server}
                                    onChange={onhandleChangeServerName}
                                />
                            </label>
                            {serverErrorMessage && <div className='warranty-list--error-message'>{serverErrorMessage}</div>}
                            {mailChimpLoading ?
                                <SaveAndCancelButton
                                    text="Save"
                                    onhandleSubmit={integrateMailChimpApi}
                                    onhandleCancel={onhandleClosedSwitchScreen}
                                />
                                :
                                <LoadingSpinner / >
                            }
                            {/* <div className='mail-chimp-warranty--all-buttons'>
                                <button
                                    className='mail-chimp-warranty--button-cancel btn btn-info'
                                    onClick={onhandleClosedSwitchScreen}
                                >
                                    Cancel</button>
                            
                                    <button
                                    className='mail-chimp-warranty--button-save btn btn-info'
                                        onClick={updateMailChimpData}
                                    >
                                    {loadingUpdateRequest ? "Save" : <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>}
                                    </button>
                            </div> */}
                        </div>
                    </div>
                
                </div>
            </div>
        )
    }
    else if (
            loadingGetRequest === false){
        return (
            <LoadingSpinner />
        )
    }
    return (
        <div className='integration-center'>
            <div className='mail-chimp-warranty--wrapper'>
            
                <p className='mail-chimp-warranty--text'>
                    To have warranty user information added to MailChimp,
                    <br></br>
                    please provide the following information:
                </p>
                <div className='mail-chimp-warranty--container'>
                    <label className='mail-chimp-warranty--account-id'>Account ID:
                        <input
                            className='mail-chimp-warranty--input'
                            type="text"
                            onChange={onhandleChangeMailChimpAudienceId}
                        />
                    </label>
                    {audienceIdErrorMessage && <div className='warranty-list--error-message'>{audienceIdErrorMessage}</div>}
                    <label 
                        className='mail-chimp-warranty--api-key'>
                        Api Key
                        <input
                            className='mail-chimp-warranty--input'
                            type="text"
                            onChange={onhandleChangeMailChimpApiKey}
                        />
                    </label>
                    {apiKeyErrorMessage && <div className='warranty-list--error-message'>{apiKeyErrorMessage}</div>}
                    <label
                        className='mail-chimp-warranty--server-name'
                    >Server Name
                        <input
                            className='mail-chimp-warranty--input'
                            type="text"
                            onChange={onhanldeChangeMailChimpServer}
                        />
                    </label>
                    {serverErrorMessage && <div className='warranty-list--error-message'>{serverErrorMessage}</div>}
                    {mailChimpLoading ?
                        <SaveAndCancelButton
                            text="Save"
                            onhandleSubmit={integrateMailChimpApi}
                            onhandleCancel={onhandleClosedSwitchScreen}
                        />
                        :
                        <LoadingSpinner / >
                    }
                    {/* <div className='mail-chimp-warranty--all-buttons'>
                        <button 
                            className='mail-chimp-warranty--button-cancel'
                            onClick={onhandleClosedSwitchScreen}
                            >
                            Cancel</button>
                        {mailChimpLoading ? 
                            <button
                                className='mail-chimp-warranty--button-save'
                                onClick={integrateMailChimpApi}
                            >
                                Save
                            </button>
                            :
                            <LoadingSpinner / >
                        }
                    
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ConsumerWarrantyMailChimp;
