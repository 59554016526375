import axios from "axios"
import { CompanyType } from "../constants/CompanyType";
import backendEndPoint from '../../ENDPOINT/back-end-endpoint/endPoint';

// export const UpdateProductInfo = (data, token, id) => async (dispatch, getState) => {
    // const header = {
    //     'Accept': 'multipart/form-data',
    //     'Content-Type': 'multipart/form-data',
    //     "authorization": `Bearer ${token}`
    // }
//     try {
//         dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST })
//         //fetch api
//         let apiResponse = await axios.put(
//             `${backendEndPoint.DASHBOARD_UPDATE_PRODUCT_INFO}${id}`,
//             data, {
//             headers: header,
//         })
//         //dispatch success
//         dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_SUCCESS })
//     } catch (error) {
//         dispatch({ type: ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_FAILURE, payload: error.message })
//     }
// }
// export const AddCompanyAction = (data, token) => async (dispatch, getState) => {
//      const header = {
//         headers: {
//             "authorization": `Bearer ${token}`
//         }
//     }
//     try {
//         dispatch({ type: CompanyType.ADD_COMPANY_REQUEST})
//         let apiResponse = await axios.post(backendEndPoint.DASHBOARD_COMPANY, data, header);
//         dispatch({ type: CompanyType.ADD_COMPANY_REQUEST_SUCCESS})
//         console.log(apiResponse);
//     } catch (error) {
//         dispatch({ type: CompanyType.ADD_COMPANY_REQUEST_FAILURE, payload: error.message })
//     }

// }

export const FetchCompanyAction = (token, companyId) => async (dispatch, getState) => {
    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
    }
    try {
        dispatch( { type: CompanyType.FETCH_COMPANY_REQUEST})
        let apiResponse = await axios.get(backendEndPoint.DASHBOARD_COMPANY + "/" + companyId, header);
        dispatch({ type: CompanyType.FETCH_COMPANY_REQUEST_SUCCESS, payload: apiResponse?.data?.data})
        dispatch({ type: CompanyType.FETCH_COMPANY_REQUEST_FILTER_DATA, payload: apiResponse?.data?.data})
    } catch (error) {
        dispatch({ type: CompanyType.FETCH_COMPANY_REQUEST_FAILURE, payload: error.message})
    }
}

export const FetchSingleCompanyAction = (token, companyId, exactId) => async (dispatch, getState) => {
    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
    }
    try {
        dispatch({ type: CompanyType.FETCH_SINGLE_COMPANY_REQUEST})
        let apiResponse = await axios.get(backendEndPoint.DASHBOARD_COMPANY_BY_COMPANY_ID + exactId + "/" + companyId, header);
        dispatch({ type: CompanyType.FETCH_SINGLE_COMPANY_REQUEST_SUCCESS, payload:apiResponse?.data?.data})
    } catch (error) {
        dispatch({ type: CompanyType.FETCH_SINGLE_COMPANY_REQUEST_FAILURE, payload: error.message })
    }
}

export const UpdateSingleCompanyAction = (data, token, companyId, id) => async (dispatch, getState) => {
    const header = {
        headers: {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
            "authorization": `Bearer ${token}`
        }
    }
    try {
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_REQUEST })
        await axios.put(backendEndPoint.DASHBOARD_COMPANY_BY_COMPANY_ID + companyId + "/" + id, data, header);
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_SUCCESS})
    } catch (error) {
        dispatch({ type: CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_FAILURE, payload: error.message })
    }
}


export const DeleteSingleCompanyAction = (token, companyId, id) => async (dispatch, getState) => {
    const header = {
        headers: {
            "authorization": `Bearer ${token}`
        }
    }
    try {
        dispatch({ type: CompanyType.DELETE_SINGLE_COMPANY_REQUEST })
        await axios.delete(backendEndPoint.DASHBOARD_COMPANY_BY_COMPANY_ID + companyId + "/" + id, header);
        dispatch({ type: CompanyType.DELETE_SINGLE_COMPANY_REQUEST_SUCCESS })
    } catch (error) {
        dispatch({ type: CompanyType.DELETE_SINGLE_COMPANY_REQUEST_FAILURE, payload: error.message })
    }
}


