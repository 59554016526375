import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import './createPost.css';
import ProjectNameWithValidation from '../../ProjectNameWithValidation/ProjectName';
import StartDateWithValidation from '../../StartDateWithValidation/StartDateWithValidation';
import EndDateWithValidation from '../../EndDateWithValidation/EndDateWithValidation';
import UploadFile from '../../UploadFile/UploadFile';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import HelperUtil from '../../../../util/HelperUtil';
import InputValidation from '../../../InputValidation/InputValidation';
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import ProductInfoEndPoint from '../../../../ENDPOINT/back-end-endpoint/ProductInfoEndPoint';
import NumberFormat from 'react-number-format';
import ErrorHandling from '../../../errorHandling/error';



const ProjectPageEdit = () => {

    const mobileDropDownElement = useRef();
    const history = useHistory();
    const INPUT_EMPTY = true;
    const param = useParams()
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.FetchSingleCompanyReducer);

    const [dateMessage, setDateMessage] = useState("");
    const [startDateMessage, setStartDateMessage] = useState("");
    const [endDateMessage, setEndDateMessage] = useState("");

    const [projectName, setProjectName] = useState(null);
    const [description, setDescription] = useState(null);
    const [clientName, setclientName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contract, setContract] = useState(null);
    const [unitRate, setUnitRate] = useState(null);
    const [cap, setCap] = useState(null);
    const [interactionWindow, setInteractionWindow] = useState(null);
    const [forecast, setForecast] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [typeOfPhone, setTypeOfPhone] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fileType, setFileType] = useState("");
    
    const [contractNote, setContractNote] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isUploadedSuccess, setIsUploadedSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startDateInputType, setstartDateInputType] = useState(true);
    const [endDateInputType, setEndDateInputType] = useState(true);
    const [s3Accesskey, setAccessKey] = useState("");
    const [error, setError] = useState(null);

    const [validation, setValidation] = useState({
        projectValidate: false,
        firstNameValidate: false,
        lastNameValidate: false,
        emailValidate: false,
        startDateValidate: false,
        endDateValidate: false
    });
    const [skus, setSkus] = useState([
        {
            id: 1,
            value: ""
        }
    ]);

    const breadCrumbs = [
        {
            text: "Products",
            link: endPoint.DASHBOARD_HOME_URL + localStorage.getItem("companyId"),
            separator: ">"
        },
        {
            text: "Edit Product Info",
            link: endPoint.DASHBOARD_PROJECT_HOME_URL_EDIT + localStorage.getItem("companyId") + "/" + param?.id
        }
    ];

    const { setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, []);

    let token = localStorage.getItem("accessToken") || undefined;
    const companyId = localStorage.getItem("companyId") || "";


    const getApi = async () => {
       
        const projectId = param?.id
        const header = {
            headers:{
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
                "authorization": `Bearer ${token}`
            }
          
        }
        try {
            let apiResponse = await axios.get(
                ProductInfoEndPoint.DASHBOARD_PROJECT_BY_PRODUCT_INFO_ID + companyId + "/" + projectId,
                header
            )
       
            const data = apiResponse?.data?.data[0];
            const {getCurrentDate} = ProductInfoUtil;
            setAccessKey(data?.s3_access_key)
            setProjectName(data?.project_name)
            setDescription(data?.description === 'null' ? "" : data?.description)
            setFirstName(data?.first_name)
            setLastName(data?.last_name)
            setEmail(data?.email)
            setTypeOfPhone(data?.type_of_phone)
            setPhoneNumber(data?.phone_number)
            setStartDate(getCurrentDate(data?.start_date))
            setEndDate(getCurrentDate(data?.end_date))
            setForecast(data?.forecast_val)
            setUnitRate(data?.unit_rate_cents)
            setCap(data?.cap)
            setInteractionWindow(data?.interaction_window_in_months)
            setContractNote(data?.contract_note === 'null' ? "" : data?.contract_note)
            setFileType(data?.file_type)
    
        } catch (error) {
            console.log(error.message);
            setError(error?.message);
        }
    }
    const updateApi = async () => {
        let formData = new FormData();
        formData.append("projectName", projectName);
        formData.append("description", description);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phoneNumber", phoneNumber);
        formData.append("typeOfPhone", typeOfPhone)
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("foreCast", forecast);
        formData.append("unitRate", unitRate)
        formData.append("cap", cap)
        formData.append("interactionWindow", interactionWindow)
        formData.append("contractNote", contractNote)
        formData.append("s3AccessKey", s3Accesskey);
        formData.append("product-info", selectedFile[0])


     

        const projectId = param?.id
        const header = {
            headers : {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data',
                "authorization": `Bearer ${token}`
            }
        
        }
        try {
            let apiResponse = await axios.put(
                ProductInfoEndPoint.DASHBOARD_PROJECT_BY_PRODUCT_INFO_ID + companyId + "/" + projectId,
                formData,
                header
            )
            setLoading(false);
            switchTheRoute();
        } catch (error) {
            setLoading(false);
            console.log(error.message);
            setError(error?.message);
        }
    }
    useEffect(() => {
        getApi();
    }, [])



    const onhandleDropDown = () => {
        mobileDropDownElement.current.style.color = "black";
    }

    const onhandleCloseSuccces = () => {
        setShowSuccess(false)
        if (isUploadedSuccess) {
            //swithc route
            switchTheRoute();
        }
    };
    const onhandleShowSuccess = () => setShowSuccess(true);

    const switchTheRoute = () => {
        history.goBack();
    }

    const onDrop = useCallback(acceptedFiles => {

        // Do something with the files
        setFileType(acceptedFiles[0]?.name)
        setSelectedFile(acceptedFiles);
    }, [])

    const onhandleDeleteProductUpload = (e) => {
        setSelectedFile([]);
    }

    const onhandleCancel = () => {
        history.goBack();
    }
 
    const allLogsInput = () => {
        console.log(projectName, description, firstName, lastName);
        console.log(email, typeOfPhone, phoneNumber);
        console.log(startDate, endDate);
        console.log(forecast, cap, unitRate, contractNote);
        console.log(selectedFile[0]);
    }
    const apiSendPostRequest = async () => {
        
      
        updateApi();

        // let formData = new FormData();
        // formData.append("projectName", projectName);
        // formData.append("description", description);
        // formData.append("firstName", firstName);
        // formData.append("lastName", data?.last_name);
        // formData.append("email", data?.email);
        // formData.append("phoneNumber", data?.phone_number);
        // formData.append("typOfPhoneNumber", data?.type_of_phone)
        // formData.append("startDate", startDate);
        // formData.append("endDate", endDate);
        // formData.append("forecast", forecast);
        // formData.append("unitRate", unitRate)
        // formData.append("cap", cap)
        // formData.append("skus", JSON.stringify(skus));
        // formData.append("contractNote", contractNote)
        // formData.append("product-info", selectedFile[0])

        // try {
        //     const header = {
        //         'Accept': 'multipart/form-data',
        //         'Content-Type': 'multipart/form-data',
        //         "authorization": `Bearer ${token}`
        //     }

        //     let dbResponse = await axios.post(ProductInfoEndPoint.DASHBOARD_PRODUCT_INFO_BY_ID + param?.id, formData, { headers: header })
        //     setLoading(false);
        //     setShowSuccess(true)
        //     setIsUploadedSuccess(true);

        //     // switchTheRoute();
        // } catch (error) {
        //     console.log(error.message);
        //     setShowSuccess(true)
        //     setIsUploadedSuccess(false);
        //     setError(error?.message);
        // }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const { isValidDate } = ProductInfoUtil;

        // LOG_ALL_INPUT();
        if (!projectName) {
            setValidation(prevState => ({ ...prevState, projectValidate: INPUT_EMPTY }))
        }
        if (!startDate) {
            setStartDateMessage("Start date cannot be blank.")
            setValidation(prevState => ({ ...prevState, startDateValidate: INPUT_EMPTY }))
        }
        if (!endDate) {
            setEndDateMessage("End date cannot be blank.")
            setValidation(prevState => ({ ...prevState, endDateValidate: INPUT_EMPTY }))
        }

        if (projectName &&
            startDate &&
            endDate
        ) {

            setLoading(true);
            apiSendPostRequest();

        }

    }

    const onhandleChangeFirstName = (e) => {
        
        setFirstName(e.target.value)
        // if (!data.first_name) {
        //     setValidation(prevState => ({ ...prevState, firstNameValidate: INPUT_EMPTY }))
        // }
    };
    const onhandleChangeLastName = (e) => {
     
        setLastName(e.target.value)
        // if (!data.last_name) {
        //     setValidation(prevState => ({ ...prevState, lastNameValidate: INPUT_EMPTY }))
        // } 
    };
    const onhandleChangeEmail = (e) => {
        // data.email = e.target.value
        setEmail(e.target.value);
        // if (!data.email) {
        //     setValidation(prevState => ({ ...prevState, emailValidate: INPUT_EMPTY }))
        // } 

    };
    const onChangePhoneNumber = (values, sourceInfo) => {
  
        setPhoneNumber(values.value)
     

    }
    const onhandleChangeTypeOfPhone = (e) => {
       setTypeOfPhone(e.target.value);
    }
    const onChangeContractNote = (e) => {
        setContractNote(e.target.value);
    }
    const onChangeProjectName = (e) => {
        setValidation(prevState => ({ ...prevState, projectValidate: false }))
        setProjectName(e.target.value);
    }
    const onChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const onChangeStartDate = (e) => {
        setValidation(prevState => ({ ...prevState, startDateValidate: false }))
        setStartDate(e.target.value)
    }
    const onChangeEndDate = (e) => {
        setValidation(prevState => ({ ...prevState, endDateValidate: false }))
        setEndDate(e.target.value);
    }
    const onChangeUnitRate = (values) => {
        const { formattedValue, value, floatValue } = values;
        setUnitRate(floatValue)
    }
    const onChangeCap = (values) => {
        const { formattedValue, value } = values;
        setCap(value)
    }
    const onChangeInteractionWindow = (values) => {
        const { formattedValue, value } = values;
        setInteractionWindow(value)
    }
    const onChangeForeCast = (values) => {
        const { formattedValue, value } = values;
        setForecast(value)
    }
    const onhandleFocusStartDate = () => {
        setstartDateInputType(false);
    }
    const onhandleStopFocusing = () => {
        setstartDateInputType(true)
    }
    const onhandleFocusEndDate = () => {
        setEndDateInputType(false);
    }
 
    if (error) {
        return <ErrorHandling error={error} />;
    }
    return (
        <div className="mainContent">
            
            {loading ?
                <LoadingSpinner />
                :
                <div> 
                    <div className="createPost-form-container offset-container">
                        <Modal show={showSuccess} onHide={onhandleShowSuccess} className="dashboard-modal-container">
                            <Modal.Header className="dashboard-modal-header">
                                <Modal.Title>
                                    {isUploadedSuccess ? " Upload successful." : "Upload unsucessfuly"}
                                </Modal.Title>
                            </Modal.Header>
                            {/* <Modal.Body>Delete successful. </Modal.Body> */}
                            <Modal.Footer className="dashboard-modal-footer">
                                <Button variant="success" size="lg" onClick={onhandleCloseSuccces}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <form onSubmit={handleSubmit} className="createPost-form ">
                            <h1 className="detail-text">Edit Product</h1>
                            <h2 className="detail-text">Details</h2>
                            <div className="detail-wrapper">
                                {!validation.projectValidate ?
                                    <div className="project-name">
                                        <label className="label-text">Product Name
                                            <i className="star"> *</i>
                                        </label>
                                        <input
                                            className="create-post-detail-input projectname-input"
                                            type="text"
                                            value={projectName}
                                            onChange={onChangeProjectName}
                                        />
                                    </div>
                                    :
                                    <ProjectNameWithValidation
                                        onChangeProjectName={onChangeProjectName}
                                    />
                                }

                                <div className="description">
                                    <label className="label-text">Description</label>
                                    <input
                                        className="create-post-detail-input description-input"
                                        type="text"
                                        value={description}
                                        placeholder="Provide a brief overview of the product"
                                        onChange={onChangeDescription}
                                    />
                                </div>

                            </div>
                            <h2 className="detail-text">Point of Contact </h2>

                            <div className="point-of-contact-wrapper">
                                <div className="point-contact-container">
                                    {validation.firstNameValidate
                                        ?
                                        <div className="first-name">
                                            <label className="label-text label-first-contact-input ">First Name
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className=" create-post-point-of-contact-input input-first-contact input-firstname"
                                                onChange={onhandleChangeFirstName}
                                                type="text"
                                                placeholder="First Name"
                                            />
                                            <label>
                                            </label>
                                            <InputValidation
                                                message="First name cannot be blank"
                                            />
                                        </div>
                                        :
                                        <div className="first-name">

                                            <label className="label-text label-first-contact-input ">First Name
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className="create-post-point-of-contact-input input-first-contact input-firstname"
                                                placeholder="First Name"
                                                type="text"
                                                value={firstName}
                                                onChange={onhandleChangeFirstName}
                                            />

                                        </div>
                                    }

                                    {!validation.lastNameValidate ?
                                        <div className="last-name">
                                            <label className="label-text label-last-name label-second-contact-input">Last Name
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className="create-post-point-of-contact-input input-second-contact input-lastname"
                                                placeholder="Last Name"
                                                type="text"
                                                value={lastName}
                                                onChange={onhandleChangeLastName} />
                                        </div>
                                        :
                                        <div className="last-name">
                                            <label className="label-text label-last-name label-second-contact-input ">Last Name
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className="create-post-point-of-contact-input input-second-contact input-lastname"
                                                onChange={onhandleChangeLastName}
                                                type="text"
                                                placeholder="Last Name"
                                            />
                                            <label>
                                            </label>
                                            <InputValidation
                                                message="Last name cannot be blank"
                                            />
                                        </div>

                                    }

                                </div>
                                <div className="point-contact-container">
                                    {!validation.emailValidate ?
                                        <div className="email">
                                            <label className="label-text label-first-contact-input">Email
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className="create-post-point-of-contact-input input-first-contact input-email"
                                                placeholder="name@example.com"
                                                type="text"
                                                value={email}
                                                onChange={onhandleChangeEmail} />
                                        </div>
                                        :
                                        <div className="email">
                                            <label className="label-text label-first-contact-input">Email
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                className="create-post-point-of-contact-input input-first-contact input-email"
                                                placeholder="name@example.com"
                                                type="text"
                                                onChange={onhandleChangeEmail} />
                                            <label>
                                            </label>
                                            <InputValidation
                                                message="Email cannot be blank"
                                            />
                                        </div>

                                    }
                                    <div className="phone">
                                        <label className="label-text label-second-contact-input phone-label">Phone
                                        </label>
                                        <div className="phonegroup">
                                            <select
                                                className={typeOfPhone ? "phones drop-down-product--phone" : "color-phone drop-down-product--phone"}
                                                onClick={onhandleDropDown}
                                                ref={mobileDropDownElement}
                                                onChange={onhandleChangeTypeOfPhone}
                                            >
                                                <option className="" value="" disabled>{HelperUtil.removeNullString(typeOfPhone) ? HelperUtil.removeNullString(typeOfPhone) : "Home"}</option>
                                                <option className="" value="Home" >Home</option>
                                                <option className="" value="Mobile" >Mobile</option>
                                                <option className="" value="Work" >Work</option>
                                            </select>
                                            <NumberFormat
                                                placeholder="+1 (000) 000-0000"
                                                className="create-post-point-of-contact-input input-phone"
                                                format="+1 (###) ###-####"
                                                onValueChange={onChangePhoneNumber}
                                                value={phoneNumber}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h2 className="detail-text">Contract</h2>
                                <div className="contract-wrapper">
                                    {!validation.startDateValidate ?
                                        <div className="start-date">
                                            <label className="label-text label-first-contact-input label-start-date">Start Date
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                placeholder="mm/dd/yyyy"
                                                type={startDateInputType ? "text" : "date"}
                                                value={startDate}
                                                id="datepicker"
                                                max="3000-01-01"
                                                onFocus={onhandleFocusStartDate}
                                                name="date"
                                                onChange={onChangeStartDate}
                                                className="create-post-point-of-contact-input input-first-contact input-date"
                                                required
                                            />

                                        </div>
                                        :
                                        <StartDateWithValidation
                                            onChangeStartDate={onChangeStartDate}
                                            text={startDateMessage}
                                        />
                                    }
                                    {!validation.endDateValidate ?
                                        <div className="end-date">
                                            <label className="label-text label-first-contact-input label-end-date">End Date
                                                <i className="star"> *</i>
                                            </label>
                                            <input
                                                placeholder="mm/dd/yyyy"
                                                type={endDateInputType ? "text" : "date"}
                                                // className="add-source-code-file-input--date"
                                                max="3000-01-01"
                                                value={endDate}
                                                name="date"
                                                onFocus={onhandleFocusEndDate}
                                                onChange={onChangeEndDate}
                                                className="create-post-point-of-contact-input input-first-contact input-endate"
                                                required
                                            />
                                        </div>
                                        :
                                        <EndDateWithValidation
                                            onChangeEndDate={onChangeEndDate}
                                            text={endDateMessage}
                                        />
                                    }
                                </div>
                                <div className={`vertical-group ${ localStorage.getItem("breadCrumbFlag") ? 'admin-only' : 'hidden' }`}>
                                    <label
                                        className="admin-only-label"
                                    >
                                        Admin Only
                                    </label>
                                    <div className={`contract-wrapper-three-items`}>
                                        <div className="unitcap">
                                            <label className="label-text label-first-contact-input">
                                                Unit Cap
                                            </label>

                                            <NumberFormat
                                                placeholder="1,000,000"
                                                className="input-unitcap"
                                                value={cap}
                                                thousandSeparator={true}
                                                onValueChange={onChangeCap}
                                            />
                                        </div>
                                        <div className="unitcap">
                                            <label className="label-text label-first-contact-input">
                                                Interaction Window
                                            </label>

                                            <NumberFormat
                                                placeholder="12"
                                                className="input-unitcap"
                                                value={interactionWindow}
                                                thousandSeparator={true}
                                                onValueChange={onChangeInteractionWindow}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="contract-note-wrapper">
                                    <textarea
                                        type="text"
                                        className="contract-note-field"
                                        placeholder="Notes"
                                        value={contractNote}
                                        onChange={onChangeContractNote}
                                    />
                                </div>

                                <div className="contract-wrapper-five-items">
                                    <UploadFile
                                        labelText="Contract Attachment"
                                        onDrop={onDrop}
                                        onhandleDelete={onhandleDeleteProductUpload}
                                        isXcross={true}
                                        fileName={selectedFile[0]?.name}
                                    />
                                </div>
                                <SaveAndCancelButton
                                    endPoint={endPoint.DASHBOARD_HOME_URL}
                                    text="Save"
                                    onhandleCancel={onhandleCancel}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                
            }



        </div>

    )
}

export default ProjectPageEdit;
