
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc); // this is important to convert utc to local time
class ProductInfoUtil{
    getCurrentDate(val){
        if(val){
            return dayjs.utc(val).format("MM/DD/YYYY");
        }
        return "--";  
    }
    getShortCurrentDateAndTime(val) {
        return dayjs(val).format('MM/DD/YY h:mm:ss A');
    }
    getCurrentDateWithoutDayJs(date) {
        if (date === null || date === undefined) {
            return "";
        } else {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (isNaN(month)
                || isNaN(day)
                || isNaN(year)) {
                return ""
            }
            return [month,day,year].join('/');
        }
    }
    getCurrentInputTypeDateFormat(val) {
        if (val) {
            return dayjs(val).format("YYYY-MM-DD");
        }
        return "";
    }

    compareDates(a, b, descending=true) {
        let da = dayjs(a);
        let db = dayjs(b);
        if (da.isBefore(db)) return descending ? 1 : -1;
        else if (da.isAfter(db)) return descending ? -1 : 1;
        else return 0;
    }
    
    getCurrentDateWithFormateOfDash(val) {
        return dayjs(val).format("YYYY-MM-DD");
    }
    getCurrentDateAndTime(val) {
        // return moment(val).format('MMMM Do YYYY,[ at ] hh:mm:ss a');
        return dayjs(val).format('MM/DD/YYYY h:mm:ss A');
    }
    getSelectedText(){
        var text = "";
        if (typeof window.getSelection != "undefined") {
            text = window.getSelection().toString();
        } else if (typeof document.selection != "undefined" && document.selection.type === "Text") {
            text = document.selection.createRange().text;
        }
        return text;
    }
    changeCurrToEmptyString(val, highLightText){
        const newString = val.replace(highLightText, "");
        return newString;
    }
    populateAddUIdata(data){
        let newArray = [];
        
        for(let index=1; index<data.length; index++){
                const object = {
                    subForeCastYear:data[index].year,
                    subForeCastVal:data[index].forecast_val,
                    identifier:data[index]?.identifier
                }
                newArray.push(object)
            }
        return newArray;
    }
    isValidDate(dateString){
        return dayjs(dateString, 'MM/DD/YYYY', true).isValid();
    };
    convertDate(dateString){
        return dayjs(dateString).format('MM/DD/YYYY');
    };
    convertDateToInputTypeFormat(dateString){
        return dayjs(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD');
    };
    convertDatetoMMYYYY(dateString){
        return dayjs(dateString).format('MM/YYYY');
    };
    populateFormData = (
            data, name, description, 
            date, productInfo_id, companyId,
        sourceCodeFileType
        ) => {
        let formData = new FormData();
        formData.append('product-info', data)
        formData.append('name', name)
        formData.append("description", description)
        formData.append("date", date);
        formData.append("productInfoId", productInfo_id);
        formData.append("companyId", companyId);
        formData.append("sourceCodeFileType", sourceCodeFileType)
        // formData.append("productInfoId", productId)
        // formData.append("logFileId", logFileId);
        return formData;
    }
    findTotalOfMfgDate = (mfgdate) => {
        let total = 0
        for (let data of mfgdate) {
            total += parseInt(data.count);
        }
        return total;
    }
    
    getbytesToMegaBytes(bytes) {
        
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(bytes, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
       
    }
    concatFileExtension(fileData) {
        if (fileData?.file_type && fileData?.file_name) {
            let getFileType = fileData?.file_type.split("/")[1];
            if (getFileType) return fileData.file_name + "." + getFileType
        }
        return "";
    }
    isFile (selectedFile){
        return selectedFile[0]?.size > 0
    }


    
}

const productInfoUtil = new ProductInfoUtil();
export default productInfoUtil;
