import React from 'react';
import './ConsumerContentHeading.css'
const ConsumerContentHeading = ({text}) => {
    return (
        <div className="consumer-content-container ">
            <h5 className="consumer-content-heading">{text}</h5>
        </div>
    );
};

export default ConsumerContentHeading;
