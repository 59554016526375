import React, {useState, useContext, useEffect } from 'react';
import DashBoard from './DashBoard';
import { BreadcrumbContext } from '../nav/BreadcrumbContext';
import './homeDashBoard.css';
import ReactEndPoint from '../../ENDPOINT/react-endpoints/endPoint'

const COMPANY_ROUTE = " Companies"
const HomeDashBoard = () => {
    let breadCrumbs = [
        {
            link: "",
            text: "Products",
            separator: ""
        }
    ];

    if (localStorage.getItem("breadCrumbFlag") === "true") {
        breadCrumbs = [
            {
                link: ReactEndPoint.DASHBOARD_COMPANY + "/" + "-1",
                text: "Companies",
                separator: ">"
            },
            {
                link: "",
                text: "Products",
                separator: ""
            }
        ];
    }

    const { setBreadcrumbs } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, []);

    return(
        <div className="mainContent">
            <DashBoard />
        </div>
    )
}

export default HomeDashBoard
