import React, { useCallback, useState, useContext, useEffect } from 'react';

import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';
import UploadFile from '../../UploadFile/UploadFile';
import ConsumerContentHeading from '../ConsumerContentHeading/ConsumerContentHeading';
import ConsumerHeading from '../ConsumerHeading/ConsumerHeading';
import ConsumerLabelAndInput from '../ConsumerLabelAndInput/ConsumerLabelAndInput';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import InputValidation from '../../../InputValidation/InputValidation';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import endPoint from '../../../../ENDPOINT/react-endpoints/endPoint';
import BackEnd from '../../../../ENDPOINT/back-end-endpoint/endPoint';

import './ConsumerProductionLog.css';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ProductInfoUtil from '../../../../util/ProductInfoUtil';
import ErrorHandling from '../../../errorHandling/error';
import axios from 'axios';
// import { useDropzone, Dropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'

import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faClipboard } from '@fortawesome/free-solid-svg-icons';

function isEmpty(str) {
    return (!str || str.length === 0);
}
function isFileEmpty(obj) {
    return Object.keys(obj).length === 0;
}
function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}
const ConsumerProductionLog = () => {
    const param = useParams();
    const productInfoid = param?.id;
    const companyId = localStorage.getItem("companyId")
    let accessToken = localStorage.getItem("accessToken");
    let projectName = localStorage.getItem("projectname");
    const history = useHistory();

    const [progressBarNumber, setProgressBarNumber] = useState(0);
    const [error, setError] = useState(null);

    const [productionlogFileData, setProductionLogFileData] = useState(
        [
            {
                id: uuidv4(),
                name: "",
                description: "",
                date: "",
                productInfoId: productInfoid,
                companyId: companyId,
                files: {},
                logNameValidation: false,
                dateValidation: false,
                fileValidation: false
            }
        ]
    );

    const FILE_LIMIT = 10;

    const onhandleSubmit = async () => {
        const isFileMoreThanTen = productionlogFileData.length > FILE_LIMIT
        if (isFileMoreThanTen) {
            alert("The files maximum upload are limited to 10 only.")
        } else {
            let isDateNameOrFileEmpty = true;
            let productionLogData = [...productionlogFileData]
            for (let i = 0; i < productionlogFileData.length; i++) {
                if (isEmpty(productionlogFileData[i]?.name)) {
                    productionLogData[i].logNameValidation = true;
                    isDateNameOrFileEmpty = false
                }
                if (isEmpty(productionlogFileData[i].date)) {
                    productionLogData[i].dateValidation = true;
                    isDateNameOrFileEmpty = false
                }
                if (isFileEmpty(productionlogFileData[i].files)) {
                    productionLogData[i].fileValidation = true;
                    isDateNameOrFileEmpty = false
                }
                setProductionLogFileData(productionLogData)
            }
            /**
             * @isDateNameOrFileEmpty contain false do not submit
             */
            if (isDateNameOrFileEmpty) {
                let apiResponse = null
                let backError = null;
                // let formData = ProductInfoUtil.populateFormData(selectedFile[0], name, 
                //     description, date, productInfoid, companyId);
                let formData = new FormData();
                for (let fileCollection of productionlogFileData) {
                    formData.append('product-info', fileCollection.files)
                }
                formData.append("data", JSON.stringify(productionlogFileData))
                formData.append("productInfoId", productInfoid)
                formData.append("companyId", companyId);

                const header = {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data',
                    "authorization": `Bearer ${accessToken}`
                }
                try {
                    /**
                     * @URL /api/v1/dashboard/productionlog/:logFileId/upload
                     */
                    const URL = `${BackEnd.DASHBOARD_PRODUCT_PRODUCTION_LOG_UPLOAD + "/" + companyId + "/" + productInfoid}`;
                    apiResponse = await axios.post(URL, formData, {
                        headers: header,
                        onUploadProgress: function (progressEvent) {
                            var percentCompleted = Math.floor((progressEvent.loaded * 50) / progressEvent.total)
                            setProgressBarNumber(percentCompleted)
                        },
                    })
                    setProgressBarNumber(100)
                    if (apiResponse?.status === 200) {
                        const TWO_SECOND = 1000;
                        await timeout(TWO_SECOND)
                        history.push(`${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${param?.id}`);
                    }
                } catch (error) {
                    setProgressBarNumber(0);
                    console.log("progress bar error");
                    console.log(error);
                    setError(error?.message)
                }

            }
        }
    }
    // const onhandleNameChange = (e) => {
    //     setName(e.target.value)
    //     setLogNameValidation(false)
    // };
    const onhandleNameChange = (e, index) => {
        let productionLogData = [...productionlogFileData]
        productionLogData[index].name = e.target.value;
        productionLogData[index].logNameValidation = false;
        setProductionLogFileData(productionLogData)
    };
    const onhandleDescriptionChange = (e, index) => {
        let productionLogData = [...productionlogFileData]
        productionLogData[index].description = e.target.value;
        setProductionLogFileData(productionLogData)
    };
    const onhandleDateChange = (e, index) => {
        let productionLogData = [...productionlogFileData]
        productionLogData[index].date = e.target.value;
        productionLogData[index].dateValidation = false;
        setProductionLogFileData(productionLogData)
        // setDateValidation(false);
        // setDate(e.target.value);
    }
    const onhandleCancel = () => {
        setProgressBarNumber(0);
        history.goBack();
    }
    const onhandleDropFile = (file, index) => {
        let productionLogData = [...productionlogFileData]
        //first row first file 
        productionLogData[index].files = file[0];
        productionLogData[index].fileValidation = false;
        setProductionLogFileData(productionLogData)
    }
    // const onDrop = useCallback(acceptedFiles => {
    //     console.log(acceptedFiles);
    //     // Do something with the files
    //     setSelectedFile(acceptedFiles);
    // }, [])
    // const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //     onDrop })
    const onhandleCopy = (index) => {

        const newData = {
            id: uuidv4(),
            name: productionlogFileData[index]?.name,
            description: productionlogFileData[index]?.description,
            date: productionlogFileData[index]?.date,
            productInfoId: productInfoid,
            companyId: companyId,
            files: {},
            logNameValidation: false,
            dateValidation: false,
            fileValidation: false
        }

        setProductionLogFileData(productionlogFileData => productionlogFileData.concat(newData))
    }
    const onhandleDelete = (index) => {
        if (productionlogFileData.length > 1) {
            setProductionLogFileData(productionlogFileData => productionlogFileData.filter((_, i) => i !== index))
        }
    }

    const breadCrumbs = [
        // {
        //     link: endPoint.DASHBOARD_COMPANY + localStorage.getItem("companyId"),
        //     text: "Companies",
        //     separator: ">"

        // }, 
        {
            link: endPoint.DASHBOARD_PROJECT_HOME_URL + localStorage.getItem("companyId"),
            text: "Products",
            separator: ">"
        }, {
            link: endPoint.DASHBOARD_DETAIL_URL + localStorage.getItem("companyId") + "/" + localStorage.getItem("projectId"),
            text: localStorage.getItem("projectName"),
            separator: ">"
        },
        {
            link: endPoint.DASHBOARD_VIEWLOG_URL + localStorage.getItem("companyId") + "/" + localStorage.getItem("projectId"),
            text: "Production Logs",
            separator: ">"
        },
        {
            link: "",
            text: "Add Production Data",
            separator: ""
        }
    ]

    const { setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, []);

    if (error) {
        return <ErrorHandling error={error} />;
    }

    return (
        <div className="mainContent">
            <div className="ps-table-container">
                {progressBarNumber <= 0?
                    <>
                        <div className="row">
                            <h5 className=" nopadding consumer-log-content--heading">Add Production Logs</h5>
                        </div>
                        <div className='add-production-log-table'>
                            <div className="row consumer-production-log-row--header">
                                <div className='col-1 first-column consumer-production-log--column'>
                                </div>
                                <div className='col-2'>
                                    <h5>Log Name*</h5>
                                </div>
                                <div className='col-3'>
                                    <h5>Description</h5>
                                </div>
                                <div className='col-3'>
                                    <h5>Date</h5>
                                </div>
                                <div className='col-2 fifth-column consumer-production-log--column'>
                                    <h5 className="file-header">File*</h5>
                                </div>
                                <div className=' col-1 sixth-column consumer-production-log--column'>
                                </div>
                            </div>
                            {productionlogFileData.map((data, index) =>
                                <div className="row table-row" key={data.id}>
                                    <div className='col-1 first-column consumer-production-log--column-body edit-column'>
                                        <FontAwesomeIcon title="Click to delete row" className="icon icon-default-background" icon={faTrash} size="lg" onClick={() => onhandleDelete(index)} />
                                    </div>

                                    <div className='col-2 second-column consumer-production-log--column-body'>
                                        <input
                                            onChange={(e) => onhandleNameChange(e, index)}
                                            placeholder="Name"
                                            value={data.name}
                                            className="consumer-production-log--column--input-name form-control"
                                        />
                                        {
                                            data?.logNameValidation &&
                                            <InputValidation
                                                message="Name cannot be empty."
                                            />
                                        }

                                    </div>
                                    <div className='col-3 third-column consumer-production-log--column-body'>
                                        <input
                                            value={data.description}
                                            placeholder='Description'
                                            onChange={(e) => onhandleDescriptionChange(e, index)}
                                            className="consumer-production-log--column--input-description form-control"
                                        />
                                    </div>
                                    <div className='col-3 fourth-column consumer-production-log--column-body'>
                                        <input
                                            placeholder="MM/DD/YEAR"
                                            value={data.date}
                                            type="date"
                                            name="date"
                                            max="2030-01-01"
                                            onChange={(e) => onhandleDateChange(e, index)}
                                            className="consumer-production-log--column--input-date  w-100 form-control"
                                            required
                                        />
                                        {
                                            data?.dateValidation &&
                                            <InputValidation
                                                message="Date cannot be empty."
                                            />
                                        }
                                    </div>
                                    <div className='col-2 fifth-column  consumer-production-log--column-body'>
                                        <Dropzone onDropAccepted={(files) => onhandleDropFile(files, index)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className='upload-section'>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {productionlogFileData[index].files?.name ?
                                                            productionlogFileData[index].files?.name
                                                            :
                                                            <label title="Upload log file" className="consumer-production-log--column-upload">
                                                                <i className="bi bi-cloud-upload"></i> Upload
                                                            </label>
                                                        }
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            data?.fileValidation &&
                                            < InputValidation
                                                message="File cannot be empty."
                                            />
                                        }
                                    </div>
                                    <div
                                        className='col-1 sixth-column consumer-production-log--column-body edit-column'
                                    >
                                        <FontAwesomeIcon title="Click to duplicate row" className="icon icon-default-background" icon={faClipboard} size="lg" onClick={() => onhandleCopy(index)} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="consumer-production-log--save-cancel-buttons ">
                            <SaveAndCancelButton
                                // endPoint={location?.state?.prevRoute}
                                text="Add"
                                onhandleSubmit={onhandleSubmit}
                                onhandleCancel={onhandleCancel}
                            />
                        </div>
                    </>
                :
                    <div className="progress-bar-box">
                        <span className="progress-bar-text">Uploading...  Please don't close this page.</span>
                        <div style={{ width: '20rem', height: '20rem', margin: '0 auto' }}>
                            <CircularProgressbar
                                value={progressBarNumber}
                                text={`${progressBarNumber}%`}
                                styles={buildStyles({
                                    textColor: "black",
                                    pathColor: "rgb(39, 104, 169)",
                                    trailColor: "white",
                                    strokeLinecap: 'butt',
                                    transitionDuration: 500
                                })}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ConsumerProductionLog;
