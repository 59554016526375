import React from 'react';
import InputValidation from '../../InputValidation/InputValidation';

const ProjectNameWithValidation = ({onChangeProjectName}) => {
    return(
         <div className="project-name">
            <label className="label-text">Product Name
                <i className="star">*</i>
            </label>
            <input 
                className="create-post-detail-input projectname-input validation-border" 
                type="text" 
                onChange={onChangeProjectName}
                />
                     <label></label>
            <InputValidation 
                message="Product name cannot be blank"
            />
                           
        </div>
    )
}
export default ProjectNameWithValidation;
