import { UsersActionType } from "../constants/UsersActionType";
const initialState = {
    success: false,
    token:null,
    loading:false,
    error:''
   
}

export const UsersReducers =(state = initialState, action) => {
    switch (action.type) {
        case UsersActionType.SET_SIGN_UP_SUCCESS:
            return {...state, success:action.payload};
        case UsersActionType.TOKEN_REQUEST:
            return {...state, loading:true};
        case UsersActionType.TOKEN_REQUEST_SUCCESS:
            return {...state, loading: false, token:action.payload};
        case UsersActionType.TOKEN_REQUEST_FAILURE:
            return {...state, loading: true, errror:action.payload};
        default:
            return state;
    }
}
