import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";

import ReactEndPoint from '../../ENDPOINT/react-endpoints/endPoint';
import BackendEndpoint from '../../ENDPOINT/back-end-endpoint/endPoint';

import axios from 'axios';

import './ResetPassword.css';
import HoliosInForm from '../HoliosInForm/HoliosInForm';
const ResetPass= () => {
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);
    const param = useParams();
    const history = useHistory();
    //const success = localStorage.getItem("success");
    const success = true;

    const onhandleSubmit = (e) => {
        e.preventDefault();
        //userid, token, password
        if(password === confirmPassword){
            updatePassword(password, param?.resetToken);
        }else{
            alert("password does not match.")
        }
       
    }
    const updatePassword = async (password, token) => {
        const data = {
            password
        }
        const header = {
            "authorization": `Bearer ${token}`
        }
        try {
            await axios.put(BackendEndpoint.RESET_PASSWORD_URL, data, {headers: header});
            //navigate to login url
            history.push(ReactEndPoint.LOGIN_URL)
            localStorage.setItem("success", false)
        } catch (error) {
            console.log(error.message);
            localStorage.setItem("success", false)
        }
    }
    

    const onchangePassword = (e) => {
        setPassword(e.target.value)
    }
    const onconfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    return(
        <div>
            {success ?

                <div className="mx-auto my-auto">
                    < form className="card-reset-password" onSubmit={onhandleSubmit} >
                        <HoliosInForm />
                        <h1>Change Password</h1>
                        <div className="text-left">New Password</div>
                        <input
                            type="password"
                            onChange={onchangePassword}
                            className="form-control"
                        />
                        <div className="">ConfirmPassword </div>
                        <input
                            type="password"
                            onChange={onconfirmPassword}
                            className="form-control"
                        />
                        <br />
                        <button className="form-control btn btn-primary">Change Password</button>
                    </form >
                </div >
                :
                <div>
                    404 Not Found
                </div>
            }
        </div>
       
      
    )
}
export default ResetPass;
