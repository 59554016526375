import React from 'react';
import { Modal, Button } from 'react-bootstrap';


const CustomModal = ({
    show,
    onhandleClose,
    fileName,
    onhandleDelete,
    id
}) => {
    return (
        <div>
            <Modal show={show} onHide={onhandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {fileName && fileName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete it ? </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onhandleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onhandleDelete(id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomModal;
