import { CompanyType } from "../constants/CompanyType";

export const FetchCompanyReducer = (state = {
    loading: false,
    error: '',
    success: false,
    data: [],
    filterData: []
}, action) => {
    switch (action.type) {
        case CompanyType.FETCH_COMPANY_REQUEST:
            return { ...state, loading: true };
        case CompanyType.FETCH_COMPANY_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload }
        case CompanyType.FETCH_COMPANY_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload }
        case CompanyType.FETCH_COMPANY_REQUEST_FILTER_DATA:
            return { ...state, filterData: action.payload }
        default:
            return state;
    }
}

export const FetchSingleCompanyReducer = (state = {
    loading: false,
    error: '',
    success: false,
    data: {}
}, action) => {
    switch (action.type) {
        case CompanyType.FETCH_SINGLE_COMPANY_REQUEST:
            return { ...state, loading: true };
        case CompanyType.FETCH_SINGLE_COMPANY_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload }
        case CompanyType.FETCH_SINGLE_COMPANY_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload }
        case CompanyType.UPDATE_SINGLE_COMPANY_NAME:
            return { ...state, data: { ...state.data, name: action.payload } }
        case CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_FIRST_NAME:
            return { ...state, data: { ...state.data, first_name: action.payload } }
        case CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_LAST_NAME:
            return { ...state, data: { ...state.data, last_name: action.payload } }
        case CompanyType.UPDATE_SINGLE_COMPANY_OTHER_ADDRESS_DETAILS:
            return { ...state, data: { ...state.data, other_address_details: action.payload } }
        case CompanyType.UPDATE_SINGLE_COMPANY_POSTAL_CODE:
            return { ...state, data: { ...state.data, postal_code: action.payload } }   
        case CompanyType.UPDATE_SINGLE_COMPANY_STATE:
            return { ...state, data: { ...state.data, state: action.payload } }  
        case CompanyType.UPDATE_SINGLE_COMPANY_STREET_ADDRESS:
            return { ...state, data: { ...state.data, street_address: action.payload } }  
        case CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_EMAIL:
            return {...state, data: { ...state.data, email: action.payload }}  
        case CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_PHONE_NUMBER:
            return {...state, data: { ...state.data, phone_number: action.payload }}  
        case CompanyType.UPDATE_SINGLE_COMPANY_CITY:
            return {...state, data: { ...state.data, city: action.payload }}  
        case CompanyType.UPDATE_SINGLE_COMPANY_CREATED_AT:
            return { ...state, data: { ...state.data, created_at: action.payload }}  
        case CompanyType.UPDATE_SINGLE_COMPANY_CONTACT_TYPE_PHONE:
            return { ...state, data: { ...state.data, type_of_phone: action.payload } }  
        case CompanyType.UPDATE_SINGLE_COMPANY_IMAGE_FILE_NAME:
            return { ...state, data: { ...state.data, file_name: action.payload } }  
        case "UPDATE_SINGLE_COMPANY_WARRANTY_API_KEY":
            return { ...state, data: { ...state.data, warranty_api_key: action.payload } }
        default:
            return state;
    }
}

export const UpdateSingleCompanyReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case CompanyType.UPDATE_SINGLE_COMPANY_REQUEST:
            return { ...state, loading: true };
        case CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true}
        case CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_FAILURE:
            return { loading: true, error: action.payload }
        case CompanyType.UPDATE_SINGLE_COMPANY_REQUEST_RESET_SUCCESS:
            return { ...state, success: false }
        default:
            return state;
    }
}

export const DeleteSingleCompanyReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case CompanyType.DELETE_SINGLE_COMPANY_REQUEST:
            return { ...state, loading: true };
        case CompanyType.DELETE_SINGLE_COMPANY_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CompanyType.DELETE_SINGLE_COMPANY_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true }
        case CompanyType.DELETE_SINGLE_COMPANY_RESET_SUCCESS:
            return { ...state, success: false }
        default:
            return state;
    }
}
