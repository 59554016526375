import React, {useEffect, useState} from 'react';
import {  URL } from '../../fakeUserId';
import QRCode from 'qrcode';
import { useParams } from 'react-router-dom';
const BarCode = () => {
    const [src, setSrc] = useState("");
    const param = useParams();
    const serialNumber = param?.serial_number_id;
    const companyIdParam = param?.companyId;

    const URL_WITH_DSN = URL + serialNumber + "/" + companyIdParam
   
    useEffect(() => {
        
        const OPTS = { data: "hello" }
        QRCode.toDataURL(URL_WITH_DSN, OPTS, function (err, url) {
            if (err) throw err
            setSrc(url)
        })
    }, [])
    return (
        <div className='text-center'>
            <img src={src} />
        </div>
    );
};

export default BarCode;
