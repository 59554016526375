import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { BreadcrumbContext } from '../../../nav/BreadcrumbContext';



import { AddCompanyAction } from '../../../../redux/action/CompanyAction';
import LoadingSpinner from '../../../loadSpinner/loadSpinner';
import ConsumerContentHeading from '../../Consumer/ConsumerContentHeading/ConsumerContentHeading';
import SaveAndCancelButton from '../../SaveAndCancelButton/SaveAndCancelButton';
import UploadFile from '../../UploadFile/UploadFile';
import CompanyUtil from '../../../../util/CompanyUtil';
import CopyRight from '../../../CopyRight/CopyRight';


import './AddCompany.css';
import AddCompanyInputLabel from './AddCompanyInputLabel';
import CompanyStateData from '../StateData.json';
import ReactEndPoint from '../../../../ENDPOINT/react-endpoints/endPoint'
import backendEndPoint from '../../../../ENDPOINT/back-end-endpoint/endPoint'
import axios from 'axios';
import { validate } from 'uuid';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const AddCompany = () => {
    let accessToken = localStorage.getItem("accessToken") || null;
    let userId = localStorage.getItem("userId");
    const history = useHistory();

    const [stateList, setStateList] = useState(CompanyStateData);
    const [srcImg, setSrcImg] = useState(null);
    //save the image that used to be crop
    const [image, setImage] = useState(null);//change the aspect ratio of crop tool as you preferred
    const [crop, setCrop] = useState({ aspect: 16 / 9 });//save the resulted image
    const [result, setResult] = useState(null);

    const [search, setSearch] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);  
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [removedUsers, setRemovedUsers] = useState([]);

    const { setLeftNav } = useContext(BreadcrumbContext);
  
    useEffect(() => {
        setLeftNav(false);
        // reset leftNav to true when the component unmounts
        return () => setLeftNav(true);
    }, []);

    const dispatch = useDispatch();

    // const {loading, success} = useSelector((state) => state.AddCompanyReducer);
    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState(null);
    const [streetAddress, setStreetAddress] = useState(null);
    const [blogSuitUnits, setBlogSuitUnits] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [typeOfMobile, setTypeOfMobile] = useState(null);
    const [warrantyApiKey, setWarrantyApiKey] = useState("");
    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [boolImage, setboolImage] = useState(true);


    const [validateCompanyInput, setValidateCompanyInput] = useState(false);
    const [validateFirstNameInput, setValidateFirstNameInput] = useState(false);
    const [validateLastNameInput, setValidateLastNameInput] = useState(false);
    const [validateEmailInput, setValidateEmailInput] = useState(false);
    const [validateatSign, setValidateAtSign] = useState(false);

    const stateRef = useRef();
    const mobileRef = useRef();
    const inputRef = useRef();

    const param = useParams();

    useEffect(() => {
        async function fetchUsers(searchString = '') {
            const url = "/api/v1/limited-user-list";
            const searchPayload = {
                "search": searchString
            };
            try {
                const apiResponse = await axios.post(url, searchPayload, { headers: { "authorization": `Bearer ${accessToken}` } });
                const users = apiResponse?.data?.data || [];
                
                // Filter users where company_id matches param?.companyId and add to selectedUsers
                const matchingUsers = users.filter(user => user.company_id === param?.companyId);
                setSelectedUsers(matchingUsers);
    
                // Update searched users
                setSearchedUsers(users);
            } catch (error) {
                console.log(error);
            }
        }
    
        fetchUsers();
    }, [param?.companyId, accessToken]);

    useEffect(() => {
        generateNewKey();
    }, []);

    /**
     * 
     * @Helperfunction 
     *  
     */
    function ValidateEmail(input) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (input && input.match(validRegex)) {
            return true;
        } 
        return false;
    }
    const removeStartAndEndWhiteSpace = (str) => {
        return str && str.trim();
    }
    const onhandleSubmit = (e) => {
        e.preventDefault();
        ValidateEmail(email);
        if (!removeStartAndEndWhiteSpace(company)) {
            setValidateCompanyInput(true);
        }
        if (!removeStartAndEndWhiteSpace(firstName)) {
            setValidateFirstNameInput(true);
        }
        if (!removeStartAndEndWhiteSpace(lastName)) {
            setValidateLastNameInput(true);
        }
        //check for empty email first
        //then check for 
        if (!removeStartAndEndWhiteSpace(email)) {
            setValidateEmailInput(true);
        }
        //check if email exist, then check if email is valid
        else if(!ValidateEmail(email)){
            setValidateAtSign(true);
        }
        else if (
            removeStartAndEndWhiteSpace(company)
            && removeStartAndEndWhiteSpace(firstName)
            && removeStartAndEndWhiteSpace(lastName)
            && removeStartAndEndWhiteSpace(email) && ValidateEmail(email)) {
            let formData = new FormData();
            if (result) {
                //if user crop the logo, then we append crop image
                formData.append("product-info", result, selectedFile[0]?.path);
            } else {
                //add original logo
                formData.append("product-info", selectedFile[0])
            }
            formData.append("name", company);
            formData.append("streetAddress", streetAddress);
            formData.append("otherAddress", blogSuitUnits);
            formData.append("city", city);
            formData.append("state", state);
            formData.append("postalCode", zipCode);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName)
            formData.append("email", email);
            formData.append("phoneNumber", mobile);
            formData.append("typeOfPhone", typeOfMobile)
            formData.append("userId", userId);
            formData.append("removedUsers", JSON.stringify(removedUsers));
            const newUsersList = selectedUsers.filter(user => user.company_id !== param?.companyId);
            formData.append("newUsers", JSON.stringify(newUsersList));
            formData.append("warrantyApiKey", warrantyApiKey);
            apiAddCompany(formData, accessToken)

        }
    }
    const apiAddCompany = async (data, token) => {
        const header = {
            headers: {
                "authorization": `Bearer ${token}`
            }
        }
        try {
            setLoading(true);
            let apiResponse = await axios.post(backendEndPoint.DASHBOARD_COMPANY + "/" + param?.companyId, data, header);
            if (apiResponse?.status === 200) {
                history.push(ReactEndPoint.DASHBOARD_COMPANY + "/" + "-1");
            }
        } catch (error) {
            console.log(error.message)
            setLoading(false)
        }
    }
    const onhandleChangeCompany = (e) => {
        setCompany(e.target.value)
        setValidateCompanyInput(false)
    };
    const onhandleChangeFirstName = (e) => {
        setFirstName(e.target.value);
        setValidateFirstNameInput(false)
    };
    const onhandleChangeLastName = (e) => {
        setLastName(e.target.value)
        setValidateLastNameInput(false);
    };
    const onhandleChangeStreetAddress = (e) => setStreetAddress(e.target.value);
    const onhandleChangesetBlogUnits = (e) => setBlogSuitUnits(e.target.value);
    const onhandleChangesetCity = (e) => setCity(e.target.value);
    const onhandleChangeState = (e) => setState(e.target.value)
    const onhandleChangeZipCode = (e) => setZipCode(e.target.value);
    const onhandleChangeEmail = (e) => {
        setEmail(e.target.value)
        setValidateEmailInput(false);
        setValidateAtSign(false);
    };
    const onhandleChangeMobile = (values, sourceInfo) => setMobile(values.value)
    const onhandleChangeSetTypeOfMobile = (e) => setTypeOfMobile(e.target.value);

    const onhandleDropDownState = () => {
        stateRef.current.style.color = "black";
    }
    const onhandleDropDownMobile = () => {
        mobileRef.current.style.color = "black";
    }
    const onhandleCancel = () => {
        history.goBack();
    }
    const resetAllInputToDefault = () => {

    }
    const onhandleFocus = () => {
        console.log(inputRef.autocomplete);
    }
    const onhandleChangeApiKey = (e) => {
        setWarrantyApiKey(e.target.value);
    }
    const generateNewKey = (e) => {
        e?.preventDefault();
        let key = '';
        while (key.length < 80) {
            key += Math.random().toString(36).substring(2);
        }
        key = key.substring(0, 80);
        setWarrantyApiKey(key);
    }


    const onDrop = useCallback(acceptedFiles => {
        const createImageURL = URL.createObjectURL(acceptedFiles[0])
        setSrcImg(createImageURL);
        setSelectedFile(acceptedFiles)
        setShow(true)
        setImage(true)
    }, [])

    const getCroppedImg = async () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");

            // New lines to be added
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            // const base64Image = canvas.toDataURL("image/jpeg", 1);

            var base64Image = canvas.toDataURL("image/jpeg", 1);
            var blobData = CompanyUtil.dataURItoBlob(base64Image);

            const isNotCrop = base64Image.length <= 6;
            if (isNotCrop) {
                alert("Please crop the logo or close to keep the original logo.")
            } else {
                setShow(false);
                setResult(blobData);
            }
            // setboolImage(false)

        } catch (e) {
            console.log("crop the image");
        }
    };

    const handleClose = () => {
        setShow(false)
        // setboolImage(false)
    };

    const handleSearch = async () => {
        const url = "/api/v1/limited-user-list";
        const searchPayload = {
            "search": search
        };
        try {
            const apiResponse = await axios.post(url, searchPayload, { headers: { "authorization": `Bearer ${accessToken}` } });
            setSearchedUsers(apiResponse?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
    };
    const handleSelect = (user) => {
        if (!selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
    
            // If the user is in removedUsers, remove them
            if (removedUsers.some(removedUser => removedUser.id === user.id)) {
                setRemovedUsers(prevRemoved => prevRemoved.filter(u => u.id !== user.id));
            }
        }
    };
    const handleDeselect = (user) => {
        setSelectedUsers(selectedUsers.filter(u => u.id !== user.id));
    
        // If the user's company_id matches param?.companyId, add to removedUsers
        if (user.company_id === param?.companyId) {
            setRemovedUsers([...removedUsers, user]);
        }
    };
    
    return (
        <div className="mainContent">
            {loading ?
                <Container>
                    <LoadingSpinner />
                </Container>
                :
                <div className="createPost-form-container offset-container company-createPost">

                    <form onSubmit={onhandleSubmit} className="createPost-form" >
                        
                    { localStorage.getItem("breadCrumbFlag")
                        ? 
                            <div className="admin-only">
                                <label
                                    className="admin-only-label"
                                >
                                    Admin Only
                                </label>
                                <input 
                                    type="text" 
                                    placeholder="Search all users..." 
                                    value={search} 
                                    onChange={e => setSearch(e.target.value)}
                                    onKeyUp={handleSearch}
                                    onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                                    className='admin-only-input'
                                />
                                <div className='horizontal-group'>
                                    <div className="vertical-group">
                                        <label className="add-company-container-input-label--label user-label">ALL USERS</label>
                                        <div className="userSelectBox">
                                            {searchedUsers.map(user => (
                                                !selectedUsers.some(selectedUser => selectedUser.id === user.id) && (
                                                    <div key={user.id} onClick={() => handleSelect(user)} className="user-selector">
                                                        {user.name} ({user.email})
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>

                                    <FontAwesomeIcon icon={faArrowRight} />
                                    
                                    <div className="vertical-group">
                                        <label className="add-company-container-input-label--label user-label">COMPANY ACCESS</label>
                                        <div className="userSelectBox">
                                            {selectedUsers.map(user => (
                                                <div key={user.id} onClick={() => handleDeselect(user)} className="user-selector">
                                                    {user.name} ({user.email})
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {selectedUsers.map(user => (
                                        user.company_id !== null && user.company_id !== param?.companyId ? (
                                            <label key={user.id} className="admin-warning">
                                                {user.name} ({user.email}) belongs to a different company that will be overwritten
                                            </label>
                                        ) : null
                                    ))}
                                </div>

                                <hr />

                                <div className="vertical-group">
                                    <label className="add-company-container-input-label--label user-label">WARRANTY API KEY</label>
                                    <input
                                        className="add-company-container-input-label--input"
                                        type="text"
                                        onChange={onhandleChangeApiKey}
                                        value={warrantyApiKey}
                                    />
                                    <button
                                        id='generate-new-key'
                                        onClick={(e) => generateNewKey(e)}
                                    >
                                        Generate New
                                    </button>
                                </div>

                            </div>
                        :
                            <></>
                    }
                    
                        <ConsumerContentHeading
                            text="Organization"
                        />
                        <AddCompanyInputLabel
                            labelText="COMPANY NAME "
                            star="*"
                            inputRef={inputRef}
                            onhandleChange={onhandleChangeCompany}
                            validateInput={validateCompanyInput}
                            validateInputText="Company name cannot be blank. "
                        />
                        <AddCompanyInputLabel
                            labelText="STREET ADDRESS"
                            onhandleChange={onhandleChangeStreetAddress}
                        />
                        <AddCompanyInputLabel
                            labelText="SUITE, UNIT"
                            onhandleChange={onhandleChangesetBlogUnits}
                        />
                        <div className="add-company-container-city-state-zip">
                            <label className="add-company-container-city-state-zip--label">CITY, STATE, ZIP</label>
                            <div className="add-company-container-city-state-zip-horizontal">
                                <input
                                    type="text"
                                    placeholder="City"
                                    className="add-company-container-city-state-zip--input"
                                    onChange={onhandleChangesetCity}
                                />
                                {/* <DropdownButton
                                    className="test"
                                    title="State"

                                >
                                    {
                                        stateList.map((val, index) =>
                                            <Dropdown.Item

                                                className="add-company-container-city-state-zip--option"
                                            >{val?.state}</Dropdown.Item>
                                        )
                                    }

                                </DropdownButton> */}
                                <select
                                    autoComplete="state"
                                    className="add-company-container-city-state-zip--dropdown"
                                    ref={stateRef}
                                    onClick={onhandleDropDownState}
                                    onChange={onhandleChangeState}

                                >
                                    <option
                                        className="add-company-container-city-state-zip--option-placeholder"
                                        value=""
                                        disabled selected

                                    >
                                        State
                                    </option>
                                    {stateList.map((val, index) => {
                                        return (
                                            <option
                                                className="add-company-container-city-state-zip--option"
                                                value={val.state}
                                            >{val.State}</option>
                                        )

                                    })}
                                </select>
                                <input
                                    type="text"
                                    placeholder="Zipcode"
                                    className="add-company-container-city-state-zip--input"
                                    onChange={onhandleChangeZipCode}
                                />
                            </div>
                        </div>
                        <div className="add-company-contact-container">
                            <ConsumerContentHeading
                                text="Company Contact"
                            />
                            <div className="add-company-contact-container--first-last-name">
                                <div className="vertical-group">
                                    <label className="add-company-contact-container-label-input--label">FIRST NAME
                                        <i className="star"> *</i>
                                    </label>
                                    <input
                                        className="add-company-contact-container-label-input--input add-company-contact-first-name-input"
                                        type="text"
                                        onChange={onhandleChangeFirstName}
                                        placeholder="First Name"
                                    />

                                </div>

                                <div className="vertical-group">
                                    <label className="add-company-contact-container-label-input--label add-company-contact-last-name">LAST NAME
                                        <i className="star"> *</i>
                                    </label>
                                    <input
                                        className="add-company-contact-container-label-input--input"
                                        type="text"
                                        onChange={onhandleChangeLastName}
                                        placeholder="Last Name"
                                    />
                                </div>
                            </div>
                            {/**** FirstName validation Input */}
                            <label> </label>
                            {validateFirstNameInput ?
                                <div className="add-company-container--input-validation">First Name cannot be blank</div>
                                :
                                <div> </div>
                            }
                            {/**** lastName validation Input */}
                            <label> </label>
                            {validateLastNameInput ?
                                <div className="add-company-container--input-validation">Last Name cannot be blank</div>
                                :
                                <div> </div>
                            }
                            <div className="add-company-contact-container--email-mobile">
                                <div className="vertical-group">
                                    <label className="add-company-contact-container-label-input--label ">EMAIL
                                        <i className="star"> *</i>
                                    </label>
                                    <input
                                        className="add-company-contact-container-label-input--input add-company-contact-email-input"
                                        type="text"
                                        onChange={onhandleChangeEmail}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="vertical-group">
                                    <label className="add-company-contact-container-label-input--label add-company-contact-phone">PHONE</label>
                                    <div className="add-company-contact-container--email-mobile--drop-down">
                                        <select
                                            autoComplete="phone"
                                            className="add-company-container-label-input--dropdown select-wrapper"
                                            ref={mobileRef}
                                            onClick={onhandleDropDownMobile}

                                            onChange={onhandleChangeSetTypeOfMobile}
                                        >
                                            <option className="" value="" disabled selected>Mobile</option>
                                            <option className="" value="Home" >Home</option>
                                            <option className="" value="Mobile" >Mobile</option>
                                            <option className="" value="Work" >Work</option>
                                        </select>
                                        <NumberFormat
                                            className="add-company-contact-container-label-input--input"
                                            placeholder="+1 (000) 000-0000"
                                            format="+1 (###) ###-####"
                                            onValueChange={onhandleChangeMobile}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/**** Email validation Input */}
                            <label> </label>
                            
                            {validateEmailInput &&
                                <div className="add-company-container--input-validation">Email cannot be blank</div>
                            }
                            {validateatSign &&<div 
                                    className="add-company-container--input-validation">
                                    Please include an ‘@’ in the email address.'{email}' is missing '@'.
                                </div>
                            }
                            <div> </div>
                            <div className="add-company-contact-container-upload-images">

                                <div>
                                    <UploadFile
                                        labelText="LOGO ATTACHMENT"
                                        onDrop={onDrop}
                                        isImage={boolImage}
                                        imageSource={srcImg}
                                        fileName={selectedFile[0]?.name}
                                    />

                                </div>

                                <div>
                                    <Modal
                                        show={show}
                                        onHide={handleClose}
                                        dialogClassName="add-company-container-modal"
                                    >
                                        <Modal.Header className="add-company-container-modal--header" closeButton>
                                            <Modal.Title>Edit Photo</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="add-company-container-modal--body">
                                            {srcImg && (
                                                <div>
                                                    <ReactCrop
                                                        style={{ maxWidth: "50%" }}
                                                        src={srcImg}
                                                        onImageLoaded={setImage}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        onChange={setCrop}
                                                    />


                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer className="add-company-container-modal--footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button className="primary" onClick={getCroppedImg}
                                            >
                                                crop
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                            <div className="add-company-contact-container-save-cancel-button">
                                <SaveAndCancelButton
                                    text="Save"
                                    onhandleCancel={onhandleCancel}
                                />
                            </div>
                            <div className='text-center'><CopyRight /></div>

                        </div>
                    </form>


                </div>
            }

        </div>
    );
};

export default AddCompany;
