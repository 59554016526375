class WarrantAppendEndPoint{
    WARRANTY_END_POINT = "/api/v1/warranty/"
    WARRANTY_SEARCH_FOR_SERIAL_NUMBER = "/api/v1/warranty/serial-number/searches/"
    //api/v1/warranty/upload/:ID
    WARRANTY_END_POINT_UPLOAD = '/api/v1/warranty/upload/'
    //"/api/v1/warranty/SKU/:companyId"
    WARRANTY_SKU_BY_COMPANY_ID = "/api/v1/warranty/SKU/"
    WARRANTY_MAIL_CHIMP = "/api/v1/warranty/mail-chimp"
    
}
export default new WarrantAppendEndPoint();
