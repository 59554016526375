import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';
import './register.css';
import endPoint from '../../ENDPOINT/react-endpoints/endPoint';
import BackendEndPoint from '../../ENDPOINT/back-end-endpoint/endPoint';
import CopyRight from '../CopyRight/CopyRight';

import HoliosInForm from '../HoliosInForm/HoliosInForm';
import InputPassword from '../InputPassword/InputPassword';
import InputError from '../InputError/InputError';
import { useDispatch } from 'react-redux';
import { setUserSignUpSuccess } from '../../redux/action/UsersAction';
const Register = () => {
    var body = document.getElementsByTagName("BODY")[0];
    body.style.backgroundColor = "white";
    body.style.backgroundImage = "none";

    const history = useHistory();

    const [error, setError] = useState({
        message: "",
        status: true
    });

    const dispatch = useDispatch();

    const [userName, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showPassword, setShowPassword] = useState(true);

    const onhandleShowPassword = (e) => {
        setShowPassword(false);
    }
    const onhandleHidePassword = (e) => {
        setShowPassword(true);
    }
    const onChangeUserName = (e) => {
        setUsername(e.target.value);
    }
    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }
    const registerUserThroughApi = async (data) => {
        let apiResponse = "";
        try {
            apiResponse = await axios.post(BackendEndPoint.REGISTER_URL, data);
            dispatch(setUserSignUpSuccess(apiResponse?.data?.success))
            history.push(endPoint.LOGIN_URL, { success: true });
        } catch (error) {
            // error?.apiResponse?.data;
            setError(prevState => (
                {
                    ...prevState,
                    message: error?.response?.data?.error,
                    status: error?.response?.data?.success
                }
            ))
        }

    }
    const onHandleSubmit = (e) => {
        e.preventDefault();
        //** model fitting the database backend */
        const Users = {
            name: userName,
            email: email,
            password: password
        }
        registerUserThroughApi(Users);
    }




    return (
        <div className="register-form-container">
            <form className="register-form container" onSubmit={onHandleSubmit}>
                <HoliosInForm />
                <p className="please-sign-in-account">Please create an account to sign in </p>
                {error.status === false && <InputError message={error.message} />}
                <div className="all-register-form-inputs-container">
                    <input
                        className="register-form-input-username"
                        placeholder="Name"
                        type="text"
                        onChange={onChangeUserName}
                        required
                    />
                    <input
                        className="register-form-input-email"
                        placeholder="Email"
                        type="email"
                        onChange={onChangeEmail}
                        required />
                    <InputPassword
                        onChangePassword={onChangePassword}
                        onhandleShowPassword={onhandleShowPassword}
                        onhandleHidePassword={onhandleHidePassword}
                        showPassword={showPassword}
                    />
                    <button className="register-form-button-signup btn btn-primary">Sign Up</button>
                    <div className="already-have-an-account-sign-in-wrapper">
                        <div className="already-have-account">
                            Already have an Account?
                        </div>
                        <Link to={endPoint.LOGIN_URL} className="login-form-sign-in">
                            Sign In
                        </Link>
                    </div>
                    <div className='text-center'>
                        <CopyRight />
                    </div>
                </div>

            </form>

        </div>
    )
}
export default Register;
