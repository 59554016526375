import React from 'react';
import './loadSpinner.css';

    const LoadingSpinner = () => (
     <div className="d-flex justify-content-center spinner-align">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    );

    export default LoadingSpinner;
