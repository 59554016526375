import React,{useState} from 'react';
import './InputPassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const InputPassword = ({
    onChangePassword,
    onhandleShowPassword,
    onhandleHidePassword,
    showPassword
}) => {
  
     return(
        <>
            <div className="form-input-password-container">
                <input 
                    className="form-input-password" 
                    type={showPassword ? "password": "text"} placeholder="Password" 
                    onChange={onChangePassword}
                    required
                />
                {showPassword ? 
                    <FontAwesomeIcon icon={faEye} className="input-password-eye" onClick={onhandleShowPassword} />
                : 
                    <FontAwesomeIcon icon={faEyeSlash} className="input-password-eye" onClick={onhandleHidePassword} />
                }
            </div>
        </>
    )
}
export default InputPassword;
