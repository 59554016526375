class Config {
    URL  = "http://localhost:3001"
    COOKIE_POLICY = 'http://localhost:3001'

    // COOKIE_POLICY = "http://dashboardfrontend-env.eba-fhqujmub.us-east-1.elasticbeanstalk.com"
    /**
     * @description this is for new entry
     */
    // COOKIE_POLICY = "http://sp3.bluefintechpartners.com"

    // URL = "http://dashboardbackend-env.eba-szuweban.us-east-1.elasticbeanstalk.com"



    
}

const config = new Config();
export default config;
