// import './App.css';
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useParams } from 'react-router-dom';



import Home from './components/home/home';
import Register from './components/register/register';
import Login from './components/login/login';
import { ProtectedRoute } from './protectedRoute';
import endPoint from './ENDPOINT/react-endpoints/endPoint';
import React, { useEffect } from 'react';
import "@fontsource/public-sans";
import './App.css';
import NavBar from './components/nav/nav';
import { BreadcrumbContext } from './components/nav/BreadcrumbContext';
import HomeDashBoard from './components/dashboard/HomeDashBoard';
import CreatePost from './components/dashboard/Newproject/create/createPost';
import ConfirmPassResetEmail from './components/ConfirmPassResetEmail/ConfirmPassResetEmail';
import ErrorHandling from './components/errorHandling/error';
import WarrantyApp from './components/ WarrantyApp/WarrantyApp'


import Consumer from './components/dashboard/Consumer/Consumer';
import ConsumerProductionLog from './components/dashboard/Consumer/ConsumerProductionLog/ConsumerProductionLog';
import ConsumerDetailRollup from './components/dashboard/Consumer/ConsumerDetailRollup/ConsumerDetailRollup';
import ConsumerViewAddLog from './components/dashboard/Consumer/ConsumerViewAddLog/ConsumerViewAddLog';
import ConsumerSourceCodeFile from './components/dashboard/Consumer/ConsumerSourceCodeFile/ConsumerSourceCodeFile';
import ConsumerSourceCodeFileList from './components/dashboard/Consumer/ConsumerSourceCodeFile/ConsumerSourceCodeFileList';
import ResetPass from './components/ResetPass/ResetPass';
import Company from './components/dashboard/Company/Company';
import AddCompany from './components/dashboard/Company/AddCompany/AddCompany';
import EditCompany from './components/dashboard/Company/EditCompany/EditCompany';
import Search from './components/dashboard/Consumer/ConsumerSearch/ConsumerSearch';
import ConsumerWarranty from './components/dashboard/Consumer/ConsumerWarranty/ConsumerWarranty';
import BarCode from './components/ WarrantyApp/BarCode';
import ProjectPageEdit from './components/dashboard/Newproject/create/ProjectPageEdit';

// Routes that need the navbar
function Main() {
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [leftNav, setLeftNav] = React.useState(true);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs, leftNav, setLeftNav }}>
      <NavBar breadCrumbs={breadcrumbs} leftNav={leftNav} />
      <Switch>
        <ProtectedRoute exact path={endPoint.DASHBOARD_COMPANY + "/" + ":companyId"} component={Company} />
        <ProtectedRoute path={endPoint.DASHBOARD_WARRANTY_LIST + ":companyId"} component={ConsumerWarranty} />
        <ProtectedRoute path={endPoint.DASHBOARD_ADD_COMPANY + "/" + ":companyId"} component={AddCompany} />
        <ProtectedRoute path={endPoint.DASHBOARD_EDIT_COMPANY + ":companyId"} component={EditCompany} />

        <ProtectedRoute path={endPoint.DASHBOARD_SEARCH + ":companyId"} component={Search} />

        <ProtectedRoute path={endPoint.DASHBOARD_PROJECT_HOME_URL + ":companyId"} component={HomeDashBoard} />
        <Route path={endPoint.DASHBOARD_PROJECT_HOME_URL_EDIT + ":companyId" + "/" + ":id"} component={ProjectPageEdit} /> {/*id here is the product id*/}

        <ProtectedRoute path={endPoint.DASHBOARD_CREATE_PRODUCT_INFO + ":companyId"} component={CreatePost} />
        <ProtectedRoute exact path={`${endPoint.DASHBOARD_DETAIL_URL}${`:companyId`}${`/:id`}`} component={Consumer} /> {/*id here is the product id*/}

        <ProtectedRoute path={`${endPoint.DASHBOARD_SOURCE_CODE_FILE_LIST}${`:companyId`}${`/:id`}`} component={ConsumerSourceCodeFileList} /> {/*id here is the product id*/}
        <ProtectedRoute path={`${endPoint.DASHBOARD_SOURCE_CODE_FILE}${`:companyId`}${`/:id`}`} component={ConsumerSourceCodeFile} /> {/*id here is the product id*/}
        <ProtectedRoute path={`${endPoint.DASHBOARD_ADD_LOG}${`:companyId`}${`/:id`}`} component={ConsumerProductionLog} /> {/*id here is the product id*/}
        <ProtectedRoute exact path={`${endPoint.DASHBOARD_ROLLUP_URL}${`:companyId`}${`/:id`}`} component={ConsumerDetailRollup} /> {/*id here is the product id*/}
        <ProtectedRoute exact path={`${endPoint.DASHBOARD_ROLLUP_URL}${`:companyId`}${`/:id`}${`/:dsnParam`}`} component={ConsumerDetailRollup} /> {/*id here is the product id*/}
        <ProtectedRoute path={`${endPoint.DASHBOARD_VIEWLOG_URL}${`:companyId`}${`/:id`}`} component={ConsumerViewAddLog} /> {/*id here is the product id*/}
        {/* default route with the asterisk has to be last in the current hierarchy 
          level to work. Otherwise it will override all other routes that 
          appear after it in the tree because it's first and matches every path.*/}
        <Route path="*" exact={true} component={ErrorHandling} />
      </Switch>
    </BreadcrumbContext.Provider>
  );
}

function App() {
  return (
    <div className="App">

      <Router>
        <Switch>

          <Route
            exact path={endPoint.HOME_URL}
            component={Login} />
          {/* <Route exact path="/warranty" component={WarrantyApp} /> */}
          <Route forceRefresh={true} exact path="/warranty/barcode/:serial_number_id/:companyId" component={BarCode} />
          <Route forceRefresh={true} exact path="/warranty/:companyId" component={WarrantyApp} />
          <Route forceRefresh={true} exact path="/warranty/:serial_number_id/:companyId" component={WarrantyApp} />
          <Route forceRefresh={true} path={endPoint.REGISTER_URL} component={Register} />
          <Route forceRefresh={true} path={endPoint.LOGIN_URL} component={Login} />
          <Route forceRefresh={true} exact path={endPoint.RESET_PASSWORD_URL} component={ConfirmPassResetEmail} />
          <Route forceRefresh={true} path={endPoint.RESET_PASSWORD_URL + "/" + ":resetToken"} component={ResetPass} />

          <Route component={Main} /> {/* Routes that need the navbar */}
        </Switch>

      </Router>



    </div>
  );
}

export default App;
