import getColors from './GetColors';
import { Line } from 'react-chartjs-2';
import { useState, forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import { Chart as ChartJS } from 'chart.js/auto' //necessary import -- don't remove!

const LineChart = forwardRef(({ productInfoData, customStyle = null, buttons = null }, ref)  =>{
    const [showEmptyDays, setShowZeroDevicesDays] = useState(false);
    const [view, setView] = useState('daily'); // add state to track the current view

    let expanded = true; // default to true if customStyle is null-- intended to make the chart have more space in a modal
    if (customStyle == null) {
        customStyle = { backgroundColor: 'white', margin: '0', height: '27rem'};
        expanded = false;
    }

    // Sort the data by date in ascending order (chronologically)
    const skuDateAmountArr = productInfoData?.upcByDate?.rows;

    let skusArr = []; // Creating skusArr, an alphabetical list of all skus
    let dateArr = []; // Creating dateArr, a chronoligical list of all dates
    skusArr.push(skuDateAmountArr?.[0]?.skus);
    for (let currSku = 0; currSku < skuDateAmountArr.length; currSku++) {
        if (!skusArr.includes(skuDateAmountArr[currSku].skus)) {
            skusArr.push(skuDateAmountArr[currSku].skus)
        }
        if (!dateArr.includes(skuDateAmountArr[currSku].date)) {
            dateArr.push(skuDateAmountArr[currSku].date.split("T")?.[0])
        }
    }
    skusArr.sort();
    let uniqueDateArr = [...new Set(dateArr)];

    // Creating skusDataArr, the data for each sku
    let skusDataArr = []
    for (let row = 0; row < skusArr.length; row++) {
        skusDataArr.push([]);
        for (let col = 0; col < uniqueDateArr.length; col++) {
            skusDataArr[row][col] = '0';
        }
    }

    // Formatting the dates correctly
    for (let row = 0; row < skuDateAmountArr.length; row++) {
        const skuLoc = skusArr.indexOf(skuDateAmountArr[row].skus);
        const dateLoc = uniqueDateArr.indexOf(skuDateAmountArr[row].date.split("T")?.[0]);
        skusDataArr[skuLoc][dateLoc] = skuDateAmountArr[row].count;
    }

    // Showing the empty dates
    if (showEmptyDays) {
        const MILLISECONDS_IN_DAY = 86400000;
        const TIMEZONE_OFFSET = 72000000;
        for (let index = 0; index < uniqueDateArr.length - 1; index++) {
            let currDate = new Date(uniqueDateArr[index].substring(0, 4) + "-" + uniqueDateArr[index].substring(5, 7)
                + "-" + uniqueDateArr[index].substring(8));
            currDate = new Date(currDate.getTime() + (MILLISECONDS_IN_DAY - TIMEZONE_OFFSET));
            let nextDate = new Date(uniqueDateArr[index + 1].substring(0, 4) + "-" + uniqueDateArr[index + 1].substring(5, 7)
                + "-" + uniqueDateArr[index + 1].substring(8));
            nextDate = new Date(nextDate.getTime() + (MILLISECONDS_IN_DAY - TIMEZONE_OFFSET));
            if (currDate.getTime() != nextDate.getTime() - MILLISECONDS_IN_DAY) {
                let sequentialDate = new Date(currDate.getTime() + MILLISECONDS_IN_DAY);
                let sequentialDateStr = sequentialDate.toISOString().split('T')?.[0];
                uniqueDateArr.splice((index + 1), 0, sequentialDateStr);
                for (let i = 0; i < skusDataArr.length; i++) {
                    let newRow = Object.values(skusDataArr[i]);
                    newRow.splice((index + 1), 0, '0');
                    skusDataArr[i] = newRow;
                }
            }
        }
    }

    const LOW_DATA_THRESHOLD = 3;

    // Padding for low amount of data in daily view
    if (view == 'daily' && uniqueDateArr.length < LOW_DATA_THRESHOLD && uniqueDateArr.length > 0) {
        let firstDay = new Date(uniqueDateArr?.[0]);
        let lastDay = new Date(uniqueDateArr[uniqueDateArr.length - 1]);

        firstDay.setDate(firstDay.getDate() - 1);
        lastDay.setDate(lastDay.getDate() + 1);

        let firstDayStr = firstDay.toISOString().split('T')?.[0];
        let lastDayStr = lastDay.toISOString().split('T')?.[0];

        uniqueDateArr?.unshift(firstDayStr);
        uniqueDateArr?.push(lastDayStr);
        for (let index = 0; index < skusDataArr.length; index++) {
            skusDataArr[index]?.unshift('0');
            skusDataArr[index]?.push('0');
        }
    }

    //Showing the monthly data
    if (view == 'monthly') {
        // Combining columns in date array and data array
        for (let index = 0; index < uniqueDateArr.length - 1; index++) {
            if (uniqueDateArr[index].substring(5, 7) == uniqueDateArr[index + 1].substring(5, 7) &&
                uniqueDateArr[index].substring(0, 4) == uniqueDateArr[index + 1].substring(0, 4)) {
                for (let row = 0; row < skusDataArr.length; row++) {
                    skusDataArr[row][index + 1] = String(Number(skusDataArr[row][index]) + Number(skusDataArr[row][index + 1]));
                    skusDataArr[row].splice(index, 1);
                }
                uniqueDateArr.splice(index, 1);
                index--;
            }
        }
        // Reformatting dates
        for (let index = 0; index < uniqueDateArr.length; index++) {
            uniqueDateArr[index] = uniqueDateArr[index].substring(0, 7);
        }
        // Padding for low amount of data in monthly view
        if (uniqueDateArr.length < LOW_DATA_THRESHOLD) {
            // Adding padding data
            for (let index = 0; index < skusDataArr.length; index++) {
                skusDataArr[index]?.unshift('0');
                skusDataArr[index]?.push('0');
            }
            let firstMonth = String(Number(uniqueDateArr?.[0]?.substring(5, 7)) - 1);
            if (firstMonth < 10) { firstMonth = "0" + firstMonth; }
            let firstYear = uniqueDateArr?.[0]?.substring(0, 4);
            const firstDate = firstYear + "-" + firstMonth;
            uniqueDateArr?.unshift(firstDate);
            let lastMonth = String(Number(uniqueDateArr[uniqueDateArr.length - 1]?.substring(5, 7)) + 1);
            if (lastMonth < 10) { lastMonth = "0" + lastMonth; }
            let lastYear = uniqueDateArr[uniqueDateArr.length - 1]?.substring(0, 4);
            const lastDate = lastYear + "-" + lastMonth;
            uniqueDateArr?.push(lastDate);
        }
    }

    var barColors = getColors(skusArr, [189, 223, 242], [42, 87, 131], 1);
    skusArr = skusArr?.map(sku => { return sku?.toUpperCase() });

    // Defining the datasets to be used in the graph
    const datasets = [];
    for (let skusRow = 0; skusRow < skusArr.length; skusRow++) {
        datasets.push({
            label: skusArr[skusRow], //SKU Name
            data: skusDataArr[skusRow], //SKU amount on date
            fill: false,
            backgroundColor: barColors[skusRow],
            borderColor: barColors[skusRow],
            pointRadius: skusDataArr[skusRow].map(value => value === "0" ? 2 : 3),
            pointHitRadius: skusDataArr[skusRow].map(value => value === "0" ? 3 : 5),
        });
    }

    const chartData = {
        labels: uniqueDateArr,
        datasets: datasets
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Date of Production Run",
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Devices Produced",
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                },
                ticks: {
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 5,
        },
        plugins: {
            title: {
                display: true,
                text: view === 'daily' ? 'Production Per Day' : 'Production Per Month',
                font: {
                    family: 'Poppins, sans-serif',
                    size: 13
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                        }
                        return label;
                    }
                },
                titleFont: {
                    family: 'Poppins, sans-serif'
                },
                bodyFont: {
                    family: 'Poppins, sans-serif'
                }
            },
            legend: {
                display: true,
                labels: {
                    boxWidth: 13,
                    boxHeight: 13,
                    font: {
                        family: 'Poppins, sans-serif'
                    }
                },
            },
        },
        animation: {
            duration: 500,
        },
    };

    const backgroundColor = {
        id: 'backgroundColor',
        beforeDraw: function(chart, args, options) {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const totalPlugin = {
        id: 'totalPlugin',
        beforeDraw: function (chart) {
            const ctx = chart.ctx;
    
            const total = chart.data.datasets.reduce((acc, dataset) => {
                return acc + dataset.data.reduce((a, b) => a + Number(b), 0);
            }, 0);
    
            // ctx.font = 'bold 14px Poppins, sans-serif';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'top';
    
            ctx.fillText(`Total: ${total}`, 10, 10);
        }
    };    

    return (
        <>
            <div className='production-detail-chart' style={customStyle}>
                <Line 
                    ref={ref} 
                    style={{ width: '100%', height: '100%' }}
                    data={chartData}
                    options={options}
                    plugins={[backgroundColor, totalPlugin]}
                    />
                {
                    buttons ? buttons : null
                }
            </div>
            <div className="checkbox-positioning-container" style={expanded ? {marginBottom: '1rem'} : {}}>
                <Form.Check
                    type="switch"
                    id="view-switch"
                    label={<span style={{ position: 'relative', top: '6px' }}>Monthly View</span>}
                    onClick={() => setView(view === 'daily' ? 'monthly' : 'daily')}
                />
                <Form.Check
                    type="switch"
                    id="empty-days-switch"
                    label={<span style={{ position: 'relative', top: '6px' }}>Show Production Days Only</span>}
                    onClick={() => setShowZeroDevicesDays(!showEmptyDays)}
                    defaultChecked
                />
            </div>
        </>
    );
});

export default LineChart;