import React, { useEffect, useState, useRef, useContext } from 'react';

import './consumer.css';
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { BreadcrumbContext } from '../../nav/BreadcrumbContext';
import endPoint from '../../../ENDPOINT/react-endpoints/endPoint';

import ProductInfoUtil from '../../../util/ProductInfoUtil';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandling from '../../errorHandling/error';

import ConsumerController from '../../../controller/ConsumerController';
import ConsumerContentHeading from './ConsumerContentHeading/ConsumerContentHeading';
import ConsumerDetailRollup from './ConsumerDetailRollup/ConsumerDetailRollup';

import {
    setProductInfoData, setClientName, setDescription,
    setFirstName, setLastName, setEmail,
    setMobileNumber,
    setStartDate,
    setEndDate,
    setForeCast,
    setUnitRate,
    setUnitCap,
    setSkus,
    UpdateProductInfo,
    setContractNote,
    setProjectName,
    DownloadSourceFile,
    DeleteContract
} from '../../../redux/action/ConsumerAction';
import SaveAndCancelButton from '../SaveAndCancelButton/SaveAndCancelButton';
import LoadingSpinner from '../../loadSpinner/loadSpinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faExclamationCircle, faPlus, faTrash, faX } from '@fortawesome/free-solid-svg-icons';

const Consumer = () => {
    let token = localStorage.getItem("accessToken");
    const param = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const companyId = localStorage.getItem("companyId");
    const projectName = useSelector((state) => state.ConsumerReducer.projectName);

    const description = useSelector((state) => state.ConsumerReducer.description)
    const firstName = useSelector((state) => state.ConsumerReducer.firstName);
    const lastName = useSelector((state) => state.ConsumerReducer.lastName)
    const email = useSelector((state) => state.ConsumerReducer.email);
    const mobileNumber = useSelector((state) => state.ConsumerReducer.mobileNumber);
    const startDate = useSelector((state) => state.ConsumerReducer.startDate);
    const endDate = useSelector((state) => state.ConsumerReducer.endDate);
    const foreCast = useSelector((state) => state.ConsumerReducer.foreCast);
    const unitRate = useSelector((state) => state.ConsumerReducer.unitRate);
    const cap = useSelector((state) => state.ConsumerReducer.cap);
    const contractNote = useSelector((state) => state.ConsumerReducer.contractNote)
    const [skuDropDown, setOnSkuDropDown] = useState([]);
    const [changedIndices, setChangedIndices] = useState([]);
    const [newUPCs, setNewUPCs] = useState([]);
    const [sourceCodeExist, setSourceCodeExist] = useState(false);
    const [totalMfgDate, setTotalMfgDate] = useState(0);
    const [firstRunDate, setFirstRunDate] = useState(0);
    const [latestRunDate, setLatestRunDate] = useState(0);
    const [lastestAckVersion, setlastestAckVersion] = useState('--');
    const [latestHmcuVersion, setLastestHmcuVersion] = useState('--');
    const [fileName, setFileName] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileExtension, setFileExtension] = useState(null);

    const [s3AccessKey, setS3AccessKey] = useState(null);
    const [show, setShow] = useState(null);
    const [type, setType] = useState(null);
    const [fileSize, setFileSize] = useState('');
    const [upcCode, setUpcCode] = useState([]);
    const [upcErrors, setUpcErrors] = useState([]);
    const [initialUpcCode, setInitialUpcCode] = useState([]);
    const [createdAt, setCreateAt] = useState("");
    const [typeOfPhone, setTypeOfPhone] = useState("");
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(true);
    const [error, setError] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [showRollup, setShowRollup] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const mappingRef = useRef(null);

    const getTypeOfFile = (fileType) => {
        let afterSplit = fileType.split("/");
        return afterSplit[1]
    }
    const onAddSkus = (upcCodeData, skusArr) => {
        let newArr = [];
        if (upcCodeData) {
            for (let i = 0; i < upcCodeData.length; i++) {
                const object = {

                    skus_id: skusArr.length + 1, // This is not real table id
                    val: null,
                    upc_code: upcCodeData[i].upc_code,
                    id: skusArr[i]?.id,
                    skus: skusArr[i]?.skus ? skusArr[i]?.skus : "",
                    productinfo_id: param?.id,
                    identifier: skusArr[i]?.identifier ? skusArr[i]?.identifier : uuidv4(),
                    companyId: localStorage.getItem("companyId")
                }
                newArr.push(object);
            }
        }
        return newArr;
    }
    const fetchData = async () => {
        let apiResponse = await ConsumerController.fetchDetailByIdWithoutLogItem(param?.companyId, param?.id, token);
        //fetch data success fully
        if (apiResponse?.status === 200) {
            const { productInfo, skus, mfgDate,
                ackVersion, hcmuVersion, upcCode,
                sourceCodeExist
            } = apiResponse?.data;
            setLoading(false);
            setSourceCodeExist(sourceCodeExist)
            setTypeOfPhone(productInfo?.type_of_phone)
            setFileName(productInfo?.file_name === 'null' ? "" : productInfo?.file_name)
            setFileSize(productInfo?.file_size)
            setCreateAt(productInfo?.created_at)
            setS3AccessKey(productInfo?.s3_access_key)
            let typeOfFile = "";
            if (productInfo?.file_type) {
                typeOfFile = getTypeOfFile(productInfo?.file_type)
            }

            setUpcCode(skus);
            setUpcErrors(new Array(skus.length).fill(false));
            setInitialUpcCode(structuredClone(skus));
            //we only want to add new empty input
            //when the upccode greater than lenght of the skus
            const upcCodeApiData = apiResponse?.data?.upcCode;
            dispatch(setSkus(onAddSkus(upcCodeApiData, skus)));

            //add project to local storage
            localStorage.setItem("projectName", productInfo?.project_name)
            localStorage.setItem("projectId", param?.id)
            setFileType(typeOfFile)
            setFileExtension(productInfo?.file_type)
            dispatch(setProductInfoData(productInfo))
            dispatch(setProjectName(productInfo?.project_name))
            dispatch(setDescription(productInfo?.description === 'null' ? "" : productInfo?.description))
            dispatch(setFirstName(productInfo?.first_name))
            dispatch(setLastName(productInfo?.last_name))
            dispatch(setEmail(productInfo?.email))
            dispatch(setMobileNumber(productInfo?.phone_number === 'null' ? "" : productInfo?.phone_number))
            dispatch(setStartDate(
                ProductInfoUtil.getCurrentDate(productInfo?.start_date)
            ))
            dispatch(setEndDate(
                ProductInfoUtil.getCurrentDate(productInfo?.end_date)
            ))
            dispatch(setForeCast(productInfo?.forecast_val === 0 ? "" : productInfo?.forecast_val))
            dispatch(setUnitRate(productInfo?.unit_rate_cents === 0 ? "" : productInfo?.unit_rate_cents));
            dispatch(setUnitCap(productInfo?.cap === 0 ? "" : productInfo?.cap));
            dispatch(setContractNote(productInfo?.contract_note === 'null' ? "" : productInfo?.contract_note))
            if (mfgDate && mfgDate.length > 0) {
                let total = ProductInfoUtil.findTotalOfMfgDate(ackVersion);
                setTotalMfgDate(total)
                setFirstRunDate(mfgDate[0]?.mfg_date)
                setLatestRunDate(mfgDate[mfgDate.length - 1]?.mfg_date)
            }
            if (ackVersion && ackVersion.length > 0) {
                setlastestAckVersion(ackVersion[ackVersion.length - 1]?.ack_version)
            }
            if (hcmuVersion && hcmuVersion.length > 0) {
                setLastestHmcuVersion(hcmuVersion[hcmuVersion.length - 1]?.hmcu_version)
            }
            if (upcCode && upcCode.length > 0) {
                setChangedIndices([]);
            }
            
        } else {
            setLoading(false)
            console.log("Error while fetching data");
            setError("Error while fetching data")
        }
    }
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        shouldShowRollup();
    }, [initialUpcCode]); 
    useEffect(() => {
        if (mappingRef.current) {
            if (isCollapsed) {
                mappingRef.current.style.height = "0px";
            } else {
                let totalHeight = 0;
                const children = mappingRef.current.children;
                for(let i = 0; i < children.length; i++) {
                    totalHeight += children[i].clientHeight;
                }
                mappingRef.current.style.height = "calc( " + totalHeight + "px + 1rem)";
            }
        }
    }, [isCollapsed, isEdit, newUPCs, upcCode.length]);    
    const onhandleSubmit = async (e) => {
        e.preventDefault();

        // setEdit(false)
        let formData = populateFormData();
        dispatch(UpdateProductInfo(formData, token, param?.id, param?.companyId))
    }
    const shouldShowRollup = () => {
        const allSKUsMappedInitially = initialUpcCode.every(val => val?.skus && val?.skus.trim() !== '');
        if (allSKUsMappedInitially) {
            setShowRollup(true);
            setShowWarning(false);
        } else {
            setIsCollapsed(false);
            setShowRollup(false);
            setShowWarning(true);
        }
    }    
    const populateFormData = () => {

        let formData = new FormData();

        formData.append("projectName", projectName);
        formData.append("description", description);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phoneNumber", mobileNumber);
        formData.append("s3AccessKey", s3AccessKey);
        formData.append("typeOfPhone", typeOfPhone);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("foreCast", foreCast);
        formData.append("unitRate", unitRate)
        formData.append("cap", cap)
        formData.append("SkusArr", JSON.stringify(upcCode));
        // formData.append("upcCode", upcCode);
        formData.append("contractNote", contractNote)
        formData.append("fileType", fileExtension)
        formData.append("fileName", fileName)
        formData.append("fileSize", fileSize)
        if (selectedFile !== null && selectedFile !== undefined) {
            formData.append("product-info", selectedFile[0])
        }

        formData.append("performCrudType", type)
        formData.append("companyId", localStorage.getItem('companyId'))
        return formData;
    }

    const onhandleSwitchRoute = (nextEndPoint) => {
        const currentEndPoint = `${endPoint.DASHBOARD_DETAIL_URL}${companyId}/${param?.id}`;
        // history.push(nextEndPoint, { prevRoute: currentEndPoint })
        history.push(nextEndPoint)

    }
    const onhandleSwichToSourceCodeFileRoute = (e, nextEndPoint, data) => {
        e.preventDefault();
        const state = {
            pathname: nextEndPoint,
            state: data
        }
        history.push(state)
    }

    const onhandleChangeSkus = (e, index) => {
        let newState = [...upcCode]
        newState[index].skus = e.target.value;
        setUpcCode(newState)

        if (!changedIndices.includes(index)) {
            setChangedIndices(prevIndices => [...prevIndices, index]);
        }
    }; 
    const onhandleCancel = async (e) => {
        e.preventDefault();
        setIsEdit(true);
        let apiResponse = await ConsumerController.fetchDetailByIdWithoutLogItem(param?.companyId, param?.id, token);
        if (apiResponse?.status === 200) {
            const { productInfo, skus, mfgDate,
                ackVersion, hcmuVersion, upcCode,
                sourceCodeExist
            } = apiResponse?.data;
            setLoading(false);
            setUpcCode(skus)
            const upcCodeApiData = apiResponse?.data?.upcCode;
            dispatch(setSkus(onAddSkus(upcCodeApiData, skus)));
            if (upcCode && upcCode.length > 0) {
                setChangedIndices([]);
            }
        }
    }
    const onhandleChangeUPC = (e, index) => {
        let newState = [...upcCode];
        newState[index].upc_code = e.target.value;
        setUpcCode(newState);
    
        // ensure upc is of length 12
        let newErrors = [...upcErrors];
        newErrors[index] = e.target.value.length !== 12;
        setUpcErrors(newErrors);
    
        if (!changedIndices.includes(index)) {
            setChangedIndices(prevIndices => [...prevIndices, index]);
        }
    };    
    const onHandleAddUPC = () => {
        let newUpcCode = [...upcCode];
        const newUpcCodeObj = {
            skus: "",
            upc_code: "",
            total_upc_code: 0,
            product_info_id: param?.id,
            company_id: param?.companyId,
            identifier: null,
            id: uuidv4(),
            first_seen_date: null,
            last_seen_date: null
        }
        newUpcCode.push(newUpcCodeObj);
        setUpcCode(newUpcCode);
        setIsEdit(false);
        setChangedIndices(prevIndices => [...prevIndices, newUpcCode.length - 1]);
        setNewUPCs(prevNewUPCs => [...prevNewUPCs, newUpcCode.length - 1]);
    }
    const onHandleDeleteEmptyUPC = (index) => {
        let newUpcCode = [...upcCode];
        newUpcCode.splice(index, 1);
        setUpcCode(newUpcCode);
        setIsEdit(false);
        setChangedIndices(prevIndices => prevIndices.filter(val => val !== index).map(val => val > index ? val - 1 : val));
        setNewUPCs(prevNewUPCs => prevNewUPCs.filter(val => val !== index));
    }

    const breadCrumbs = [
        // {
        //     link: endPoint.DASHBOARD_COMPANY,
        //     text: "Companies",
        //     separator: ">"

        // }, 
        {
            link: endPoint.DASHBOARD_PROJECT_HOME_URL + localStorage.getItem("companyId"),
            text: "Products",
            separator: ">"
        }, {
            link: "",
            text: localStorage.getItem("projectName"),
            separator: ""
        }
    ]

    const { setBreadcrumbs } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumbs(breadCrumbs);
    }, [localStorage.getItem("projectName")]);

    const onhandleFocusSkus = (e) => {
        setIsEdit(false);
    }
    const onhandleOnBlur = () => {
        setIsEdit(true);
    }

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    if (error) {
        console.log("error")
        return <ErrorHandling error={error} />;
    }
    if (loading) {
        return (
            <div className="Consumer mainContent">
                <LoadingSpinner />
            </div>
        )
    } else {
        return (
            <div className="Consumer mainContent">
                <div className='consumer--wrapper'>
                    <div className="consumer--container container">
                        <div className="production-summary--and-view-add-production-logs">
                            <ConsumerContentHeading
                                text={localStorage.getItem("projectName")}
                            />
                            {totalMfgDate === 0 ? (
                                <button
                                    onClick={() => onhandleSwitchRoute(`${endPoint.DASHBOARD_ADD_LOG}${param?.companyId}/${param?.id}`)}
                                    className="btn btn-info production-log--buttons"
                                >
                                    <span className="production-log--buttons--text">Add your first production log</span>
                                </button>
                            ) : 
                            <button
                                onClick={() => onhandleSwitchRoute(`${endPoint.DASHBOARD_VIEWLOG_URL}${param?.companyId}/${param?.id}`)}
                                className="btn btn-info production-log--buttons"
                            >
                                <span className="production-log--buttons--text">Production Logs</span>
                            </button>
                            }
                        </div>

                        <div className="table-scroll">
                            <table className="table table-responsive consumer-table">
                                <thead className="consumer-table-heading">
                                    <tr>
                                        <th scope="col">Total Units</th>
                                        <th scope="col">First Run Date</th>
                                        <th scope="col">Last Run Date</th>
                                        <th scope="col">Latest ACK Version</th>
                                        <th scope="col">Latest HMCU Version</th>
                                        <th scope="col">Unique UPCs </th>
                                        <th/>
                                    </tr>
                                </thead>
                                <tbody className="consumer-table-body">
                                            <tr>
                                                <td scope="row">{totalMfgDate}</td>
                                                <td>{ProductInfoUtil.getCurrentDate(firstRunDate)}</td>
                                                <td>{ProductInfoUtil.getCurrentDate(latestRunDate)}</td>
                                                <td
                                                    title="Click to view ACK source code files"
                                                    className="hyperlink-button"
                                                    style={{ textDecoration: "underline" }}
                                                    onClick={(e) => onhandleSwichToSourceCodeFileRoute(e, `${endPoint.DASHBOARD_SOURCE_CODE_FILE_LIST}${param?.companyId}/${param?.id}`, "ACK source code")}>
                                                    {lastestAckVersion}</td>
                                                <td
                                                    title="Click to view HMCU source code files"
                                                    className="hyperlink-button"
                                                    style={{ textDecoration: "underline" }}
                                                    onClick={(e) => onhandleSwichToSourceCodeFileRoute(e, `${endPoint.DASHBOARD_SOURCE_CODE_FILE_LIST}${param?.companyId}/${param?.id}`, "HMCU source code")}
                                                >{latestHmcuVersion}</td>
                                                <td>{upcCode.length}</td>
                                                <td> 
                                                    <FontAwesomeIcon
                                                        onClick={() => toggleCollapse()}
                                                        className="icon icon-default-background"
                                                        icon={faBarcode}
                                                    />
                                                </td>
                                            </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            className={`collapseable--container ${!isCollapsed ? 'expanded' : ''}`}
                            ref={mappingRef}
                        >
                            <div className="skus-mapping-header">
                                <ConsumerContentHeading
                                    text="SKUs"
                                />
                                <FontAwesomeIcon
                                    onClick={() => setIsCollapsed(true)}
                                    className="icon icon-default-background"
                                    icon={faX}
                                />
                            </div>
                            {
                                showWarning && 
                                <div className="warning-box">
                                    <FontAwesomeIcon icon={faExclamationCircle} /> You must map all of your SKUs before you are able to view production details
                                </div>
                            }
                            <div className="table-scroll">
                                <table className='table table-responsive consumer-table consumer-table-skus'>
                                    <thead className='consumer-table-heading'>
                                        <tr>
                                            <th scope="col">Total Units</th>
                                            <th scope="col">First Seen Date</th>
                                            <th scope="col">Last Seen Date</th>
                                            <th scope="col">UPC</th>
                                            <th scope="col">SKU</th>
                                        </tr>
                                    </thead>
                                    <tbody className="consumer-table-body-2">
                                        {upcCode
                                            ? (
                                                <>
                                                    {upcCode.map((val, index) =>
                                                        <tr key={val.id}>
                                                            <td className='production-log-skus--units-mfg-list'>
                                                                {val?.total_upc_code}
                                                            </td>
                                                            <td className='production-log-skus-equal--sign-list'>
                                                                {ProductInfoUtil.getCurrentDate(val?.first_seen_date)}
                                                            </td>
                                                            <td className='production-log-skus-equal--sign-list'>
                                                                {ProductInfoUtil.getCurrentDate(val?.last_seen_date)}
                                                            </td>
                                                            <td className='production-log-skus--upc-code-list'>
                                                                {!newUPCs.includes(index)
                                                                    ? val?.upc_code
                                                                    : <>
                                                                        <input
                                                                            type="text"
                                                                            className={`consumer-input-skus--input-skus ${changedIndices.includes(index) ? 'changed-input' : ''} ${upcErrors[index] ? 'invalid-upc' : ''}`}
                                                                            onChange={(e) => onhandleChangeUPC(e, index)}
                                                                            placeholder='Enter New UPC'
                                                                            value={val?.upc_code}
                                                                        />
                                                                        {upcErrors[index] && <div className="upc-error-message">UPC must be 12 digits</div>}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className='production-log-skus-equal--sign-list'>
                                                                {val?.total_upc_code > 0
                                                                    ? <input
                                                                        contentEditable
                                                                        type="text"
                                                                        onFocus={onhandleFocusSkus}
                                                                        className={`consumer-input-skus--input-skus ${changedIndices.includes(index) ? 'changed-input' : ''}`}
                                                                        onChange={(e) => onhandleChangeSkus(e, index)}
                                                                        placeholder='Enter Matching SKU'
                                                                        value={val?.skus}
                                                                    />
                                                                    : <div className="sku-and-trash">
                                                                        <input
                                                                            contentEditable
                                                                            type="text"
                                                                            onFocus={onhandleFocusSkus}
                                                                            className={`consumer-input-skus--input-skus ${changedIndices.includes(index) ? 'changed-input' : ''}`}
                                                                            onChange={(e) => onhandleChangeSkus(e, index)}
                                                                            placeholder='Enter Matching SKU'
                                                                            value={val?.skus}
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            className="icon icon-default-background consumer-delete-sku-icon"
                                                                            icon={faTrash}
                                                                            onClick={() => onHandleDeleteEmptyUPC(index)}
                                                                        />
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td className="add-new-upc-container">
                                                            <button
                                                                onClick={() => onHandleAddUPC()}
                                                                className="add-new-upc-button icon icon-default-background"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="icon icon-default-background consumer-add-sku-icon"
                                                                    icon={faPlus}
                                                                />
                                                                <span className="production-log--buttons--text">Add SKU</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                            : (
                                                <tr className="list--loading-spinner">
                                                    <td colSpan={5}>
                                                        <div className="spinner-border" role="status" />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="save-cancel-button--container">
                                {!isEdit && <SaveAndCancelButton
                                    text="Save"
                                    onhandleCancel={onhandleCancel}
                                    onhandleSubmit={onhandleSubmit}
                                />}
                            </div>
                        </div>

                        {showRollup && 
                            <ConsumerDetailRollup 
                                upcList={initialUpcCode}
                            />
                        }

                    </div>
                    

                </div>

            </div>
        )
    }

}
export default Consumer;


