import React from 'react';
import getColors from '../../ConsumerDetailRollup/charts/GetColors';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'; 

const ReprogrammedChart = ({ labels, data, chartTitle, centerLabel }) => {
    // const centerTotal = {
    //     id: 'centerTotal',
    //     afterDatasetsDraw(chart) {
    //         const { ctx } = chart;
    //         ctx.save();
    //         ctx.textAlign = 'center';
    //         ctx.textBaseLine = 'middle';
    //         ctx.font = 'bold 13px Arial';
    //         ctx.fillStyle = "#777777";
    //         ctx.fillText(centerLabel, chart.width / 2, chart.height / 2);
    //         ctx.restore();
    //     }
    // };

    const barColors = getColors(labels, [189, 223, 242], [42, 87, 131], 1);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Devices Produced',
                data,
                backgroundColor: barColors,
                borderColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };

    const options = {
        responsive: true,
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    let percentage = ((value / total) * 100).toFixed(2);
                    return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
                }
            }
        },
        layout: {
            padding: 5,
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                font: {
                    size: 13
                }
            },
            datalabels: {
                formatter: function (value, context) {
                    let total = context.dataset.data.reduce((a, b) => a + b, 0);
                    let percentage = ((value / total) * 100).toFixed(2);
                    return `${percentage}%`;
                },
                color: '#FFFFFF',
                font: {
                    weight: 'bold',
                    size: 13
                }
            },
            legend: {
                display: true,
                labels: {
                    boxWidth: 13,
                    boxHeight: 13,
                    font: {
                        size: 10
                    }
                },
            },
        }
    };

    return (
        <Doughnut 
            data={chartData}
            // plugins={[centerTotal, ChartDataLabels]}
            plugins={[ChartDataLabels]}
            options={options} />
    );
};

export default ReprogrammedChart;
