import React from 'react';
import InputValidation from '../../InputValidation/InputValidation';
import NumberFormat from 'react-number-format';
const EndDateWithValidation = (
    {
        onChangeEndDate,
        text
    }
    ) => {
    return(
        <div className="end-date">
            <label className="label-text label-first-contact-input label-end-date">END DATE
                <i className="star">*</i>
            </label>
                <NumberFormat 
                    placeholder="MM/DD/YEAR"
                        className="create-post-point-of-contact-input input-first-contact input-endate validation-border" 
                        format="##/##/####" 
                        onValueChange={onChangeEndDate}
                />
            <label></label>
            <InputValidation 
                message={text}
            />     
        </div>
    )
}
export default EndDateWithValidation;
