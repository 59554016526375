
import { ConsumerTypes } from "../constants/ConsumerType";

const initialState = {
    productInfoData: null,
    productInfoFilterData: null,
    productionLogs: null,
    clientName: null,
    description: null,
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    startDate: null,
    endDate: null,
    foreCast: null,
    unitRate: null,
    cap: null,
    skus: [],
    contractNote: null,
    projectName: null

}
export const ConsumerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConsumerTypes.SET_PRODUCT_INFO_DATA:

            return { ...state, productInfoData: action.payload };
        case ConsumerTypes.FILTER_PRODUCT_INFO_DATA:
            let result = {
                ...state,
                productInfoData: { ...state.productInfoData, upcCode: action.payload }
            }

            return result;

        case ConsumerTypes.SET_PRODUCTION_LOG_DATA:
            return { ...state, productionLogs: action.payload };
        case ConsumerTypes.SET_CLIENT_NAME:
            return { ...state, clientName: action.payload };
        case ConsumerTypes.SET_DESCRIPTION:
            return { ...state, description: action.payload }
        case ConsumerTypes.SET_FIRST_NAME:
            return { ...state, firstName: action.payload }
        case ConsumerTypes.SET_LAST_NAME:
            return { ...state, lastName: action.payload }
        case ConsumerTypes.SET_EMAIL:
            return { ...state, email: action.payload }
        case ConsumerTypes.SET_MOBILE_NUMBER:
            return { ...state, mobileNumber: action.payload }
        case ConsumerTypes.SET_START_DATE:
            return { ...state, startDate: action.payload }
        case ConsumerTypes.SET_END_DATE:
            return { ...state, endDate: action.payload }
        case ConsumerTypes.SET_FORE_CAST:
            return { ...state, foreCast: action.payload }
        case ConsumerTypes.SET_UNIT_RATE:
            return { ...state, unitRate: action.payload }
        case ConsumerTypes.SET_UNIT_CAP:
            return { ...state, cap: action.payload }
        case ConsumerTypes.SET_SKUS:
            return { ...state, skus: action.payload }
        case ConsumerTypes.SET_CONTRACT_NOTE:
            return { ...state, contractNote: action.payload }
        case ConsumerTypes.SET_PROJECT_NAME:
            return { ...state, projectName: action.payload }
        default:
            return state;
    }
}

export const UpdateProductInfoReducer = (state = {
    loading: false,
    error: '',
}, action) => {
    switch (action.type) {
        case ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST:
            return { loading: true };

        case ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_SUCCESS:
            window.location.reload();
            return;

        case ConsumerTypes.UPDATE_PRODUCT_INFO_REQUEST_FAILURE:
            return { loading: true, error: action.payload }

        default:
            return state;
    }
}
/***
 * 
 * @SOURCEFILECODE
 */
export const DownloadSourceFileCodeReducer = (state = {
    loading: false,
    error: '',
    data: null,
    success: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST:
            return { loading: true };
        case ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS:
            return { ...state, loading: true, data: action.payload, success: true }
        case ConsumerTypes.DOWNLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE:
            return { loading: true, error: action.payload }
        case ConsumerTypes.RESET_DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS:
            return { ...state, loading: true, success: false }
        default:
            return state;
    }
}

export const UploadSourceFileCodeReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload }
        case ConsumerTypes.UPLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true }
        default:
            return state;
    }
}

export const FetchSourceFileCodeReducer = (state = {
    loading: false,
    error: '',
    data: [],
    defaultData: [],
    isPagination: false,
    isDefaultPagination: false,
    trackFile: 0,
    pageNumber: []
}, action) => {
    switch (action.type) {
        case ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload }
        case ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS:
            return { ...state, loading: false, data: action.payload }
        case ConsumerTypes.FETCH_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS:
            return { ...state, loading: false, defaultData: action.payload }
        case ConsumerTypes.FETCH_FILTER_PAGINATION_SOURCE_CODE_FILES_REQUEST_SUCCESS:
            return { ...state, loading: false, isPagination: action.payload }
        case ConsumerTypes.TRACK_FILE_SOURCE_CODE_FILES:
            return { ...state, trackFile: action.payload }
        case ConsumerTypes.SOURCE_CODE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload }
        // case ConsumerTypes.FETCH_RESET_PAGINATION_TO_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS:
        //     return { ...state, loading: false, isDefaultPagination: action.payload }
        default:
            return state;
    }
}

export const DeleteSourceCodeFileReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload }
        case ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST_SUCCESS:
            return { ...state, loading: false, success: true }
        case ConsumerTypes.RESET_DELETE_SOURCE_CODE_FILES_REQUEST:
            return { ...state, loading: false, success: false }
        default:
            return state;
    }
}
/***
 * @PRODUCTIONLOGREDUCER
 */

export const FetchProductionLogReducer = (state = {
    loading: false,
    error: '',
    data: [],
    sort: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.FETCH_PRODUCTION_LOG_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ConsumerTypes.FETCH_PRODUCTION_LOG_REQUEST_SUCCESS:
            return { ...state, loading: false, data: action.payload }
        case ConsumerTypes.UPDATE_NAME_IN_PRODUCTION_LOG:
            const { index, name } = action.payload;
            const newArrayData = [...state.data]
            newArrayData[index].name = name;
            return { ...state, data: newArrayData }
        case ConsumerTypes.UPDATE_DESCRIPTION_IN_PRODUCTION_LOG:
            const { description } = action.payload;
            const dataArr = [...state.data]
            dataArr[action.payload.index].description = description
            return { ...state, data: dataArr }
        case ConsumerTypes.FILTER_FETCH_PRODUCTION_LOG_REQUEST:
            return { ...state.data, data: action.payload }
        default:
            return state;
    }
}

export const DeleteProductionLogReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.DELETE_PRODUCTION_LOG_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.DELETE_CONTRACT_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ConsumerTypes.DELETE_PRODUCTION_LOG_REQUEST_SUCCESS:
            return { loading: true, success: true }
        case ConsumerTypes.DELETE_PRODUCTION_LOG_RESET_SUCCESS:
            return { ...state, loading: false, success: false }
        default:
            return state;
    }
}

export const UpdateProductionLogReducer = (state = {
    loading: false,
    error: '',
    success: false
}, action) => {
    switch (action.type) {
        case ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST_SUCCESS:
            return { ...state, loading: true, success: true }
        case ConsumerTypes.UPDATE_PRODUCTION_LOG_REQUEST_FAILURE:
            return { ...state, loading: true, error: action.payload }
        case ConsumerTypes.RESET_UPDATE_PRODUCTION_LOG_FILES_REQUEST:
            return { ...state, loading: false, success: false }

        default:
            return state;
    }
}

export const FetchProductionLogItemReducer = (state = {
    loading: false,
    error: '',
    data: [],
    filterData: [],
    skus: [],
    skusList: [],
}, action) => {
    switch (action.type) {
        case ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST:
            return { ...state, loading: true };
        case ConsumerTypes.FILTER_PRODUCTION_LOG_ITEM:
            let result = {
                ...state,
                filterData: { ...state.filterData, upcCode: action.payload }
            }
            return result;
        case ConsumerTypes.FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS:
            return { ...state, loading: false, filterData: action.payload }
        case ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ConsumerTypes.FETCH_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS:
            return { ...state, loading: false, data: action.payload }
        case ConsumerTypes.FETCH_SKU_LIST:
            return { ...state, loading: false, skus: action.payload, skusList: action?.payload }
        case ConsumerTypes.FETCH_LIST_OF_SKUS:
            return { ...state, loading: false, skusList: action.payload }

        default:
            return state;
    }
}




// export const DownloadProductionLogReducer = (state = {
//     loading: false,
//     error: '',
//     success: false
// }, action) =>{
//     switch (action.type) {
//         case ConsumerTypes.DOWNLOAD_PRODUCTION_LOG_REQUEST:
//             return { ...state, loading: true};
//         case ConsumerTypes.DOWNLOAD_PRODUCTION_LOG_REQUEST_FAILURE:
//             return { ...state, loading: false, error: action.payload}
//         case ConsumerTypes.DOWNLOAD_PRODUCTION_LOG_REQUEST_SUCCESS:
//             return { ...state, loading: false, success: true}
//         default:
//             return state;
//     }
// }
// export const DeleteContractReducer = (state = {
//     loading: false,
//     error:'',
//     success: false
// }, action) => {
//     switch (action.type) {
//         case ConsumerTypes.DELETE_CONTRACT_REQUEST:
//             return { loading: true };
//         case ConsumerTypes.DELETE_CONTRACT_REQUEST_FAILURE:
//             return { loading: false, error: action.payload }
//         case ConsumerTypes.DELETE_SOURCE_CODE_FILES_REQUEST_SUCCESS:
//             return { loading:false, success:true}
//         default:
//             return state;
//     }
// }
