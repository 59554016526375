import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import CopyRight from '../CopyRight/CopyRight';
import HoliosInForm from '../HoliosInForm/HoliosInForm';
import BackEndpoint from '../../ENDPOINT/back-end-endpoint/endPoint'
import ReactEndPoint from '../../ENDPOINT/react-endpoints/endPoint';
import axios from 'axios';
import './ConfirmPass.css';
import AlertSuccess from '../AlertSuccess/AlertSuccess';
import Sp3ForResetPass from '../Sp3ForResetPass/Sp3ForResetPass';
import LoadingSpinner from '../loadSpinner/loadSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const ConfirmPassResetEmail = () => {
    var body = document.getElementsByTagName("BODY")[0];
    body.style.backgroundColor = "white";
    body.style.backgroundImage = "none";

    const [email, setEmail] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onhandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            email
        }
        
        if (email) {
            try {
                let apiResponse = await axios.post(BackEndpoint.RESET_PASSWORD_URL, data)
                localStorage.setItem("success", true)
                setSuccess(true);
                history.push(ReactEndPoint.LOGIN_URL)
            } catch (error) {
                setError(true);
                console.log(error.message);
            }
            setLoading(false) //unloading
        }
    }
    const onhandleEmailChange = (e) => setEmail(e.target.value)

    if (loading) {
        return (
            <div className="fullscreen-spinner-container">
                <LoadingSpinner />
            </div>
        )

    } else {
        return (
            <>
                {success ?
                    <AlertSuccess message="We just sent a link to your email!" />
                    :
                    <form className="confirm-pass-card" onSubmit={onhandleSubmit}>
                        <Sp3ForResetPass />
                        <p className="confirm-pass-card-margin-right">We will email you instructions to reset your password</p>

                        {error &&
                            <div className="alert alert-danger d-flex align-items-center input-error">
                                <FontAwesomeIcon icon={faCircleExclamation} className="input-error-icon" />
                                <div className="input-error-message">
                                    Sorry we could not send an email at this time.
                                </div>
                            </div>
                        }

                        <input
                            type="email"
                            placeholder="Email"
                            className="confirm-pass-card-margin-right form-control email-input"
                            onChange={onhandleEmailChange}
                        />
                        <div className="confirm-pass-card--buttons confirm-pass-card-margin-right">
                            <button className="btn btn-primary confirm-pass-card--reset-pass-button">Reset Your Password</button>
                            <a className="btn btn-primary return-to-login-button" href={ReactEndPoint.LOGIN_URL}>
                                Return to login
                            </a>
                        </div>
                        <div className='text-center'>
                            <CopyRight />
                        </div>


                    </form>
                }
            </>
        );
    }

};

export default ConfirmPassResetEmail;
