
class Reprogramming {
    convertDecimalToTenthPlace = (number) => {
        if (number < 1) {
            return parseFloat(number * 100).toFixed(2);
        }
        return parseFloat(number).toFixed(2);
    }
}

const reprogramming = new Reprogramming();
export default reprogramming;
