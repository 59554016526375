export function getAnalyticsRoot() {
    const url = new URL(window.location.href);

    if (url.origin.startsWith("https://sp3.bluefintechpartners.com")) {
        return "https://sp3pi.bluefintechpartners.com";
    } else if (url.origin.startsWith("https://qa-app.holios.io")) {
        return "https://qa-analytics.holios.io";
    } else if (url.origin.startsWith("https://app.holios.io")) {
        return "https://analytics.holios.io";
    } else {
        return "http://localhost:8080";
    }
}
