import React from 'react';
import './CopyRight.css';
const CopyRight = () => {
    return (
        <div className="copy-right">
            &copy; Holios, Inc. {new Date().getFullYear()}
            <br />
            Proprietary and Confidential
        </div>
    );
};

//Simple change
export default CopyRight;
