export const ConsumerTypes = {
    SET_PRODUCT_INFO_DATA:"SET_PRODUCT_INFO_DATA",
    FILTER_PRODUCT_INFO_DATA:"FILTER_PRODUCT_INFO_DATA",
    CLEAR_FIFLTER_PRODUCT_INFO_DATA:"CLEAR_FIFLTER_PRODUCT_INFO_DATA",


    SET_PRODUCTION_LOG_DATA:"SET_PRODUCTION_LOG_DATA",
    SET_CLIENT_NAME:"SET_CLIENT_NAME",
    SET_DESCRIPTION:"SET_DESCRIPTION",
    SET_FIRST_NAME:"SET_FIRST_NAME",
    SET_LAST_NAME:"SET_LAST_NAME",
    SET_EMAIL:"SET_EMAIL",
    SET_MOBILE_NUMBER:"SET_MOBILE_NUMBER",
    SET_START_DATE:"SET_START_DATE",
    SET_END_DATE:"SET_END_DATE",
    SET_FORE_CAST:"SET_FORE_CAST",
    SET_UNIT_RATE:"SET_UNIT_RATE",
    SET_UNIT_CAP:"SET_UNIT_CAP",
    SET_SKUS:"SET_SKUS",
    SET_CONTRACT_NOTE:"SET_CONTRACT_NOTE",
    SET_PROJECT_NAME: "SET_PROJECT_NAME",

    UPDATE_PRODUCT_INFO_REQUEST:"UPDATE_PRUDCT_INFO_REQUEST",
    UPDATE_PRODUCT_INFO_REQUEST_FAILURE:"UPDATE_PRODUCT_INFO_REQUEST_FAILURE",
    UPDATE_PRODUCT_INFO_REQUEST_SUCCESS:"UPDATE_PRODUCT_INFO_REQUEST_SUCCESS",

    DOWNLOAD_SOURCE_CODE_FILE_REQUEST:"DOWNLOAD_SOURCE_CODE_FILE_REQUEST",
    DOWNLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE:"DOWNLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE",
    DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS:"DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS",
    RESET_DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS: "RESET_DOWNLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS",

    UPLOAD_SOURCE_CODE_FILE_REQUEST:"UPLOAD_SOURCE_CODE_FILE_REQUEST",
    UPLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE:"UPLOAD_SOURCE_CODE_FILE_REQUEST_FAILURE",
    UPLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS:"UPLOAD_SOURCE_CODE_FILE_REQUEST_SUCCESS",

    FETCH_SOURCE_CODE_FILES_REQUEST:"FETCH_SOURCE_CODE_FILES_REQUEST",
    FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE:"FETCH_SOURCE_CODE_FILES_REQUEST_FAILURE",
    FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS:"FETCH_SOURCE_CODE_FILES_REQUEST_SUCCESS",
    FETCH_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS: "FETCH_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS",
    FETCH_FILTER_PAGINATION_SOURCE_CODE_FILES_REQUEST_SUCCESS: "FETCH_FILTER_PAGINATION_SOURCE_CODE_FILES_REQUEST_SUCCESS",
    FETCH_RESET_PAGINATION_TO_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS: "FETCH_RESET_PAGINATION_TO_DEFAULT_SOURCE_CODE_FILES_REQUEST_SUCCESS",
    TRACK_FILE_SOURCE_CODE_FILES: "TRACK_FILE_SOURCE_CODE_FILES",
    SOURCE_CODE_PAGE_NUMBER:"SOURCE_CODE_PAGE_NUMBER",
    DELETE_SOURCE_CODE_FILES_REQUEST: "DELETE_SOURCE_CODE_FILES_REQUEST",
    DELETE_SOURCE_CODE_FILES_REQUEST_FAILURE:"DELETE_SOURCE_CODE_FILES_REQUEST_FAILURE",
    DELETE_SOURCE_CODE_FILES_REQUEST_SUCCESS:"DELETE_SOURCE_CODE_FILES_REQUEST_SUCCESS",
    RESET_DELETE_SOURCE_CODE_FILES_REQUEST: "RESET_DELETE_SOURCE_CODE_FILES_REQUEST",


    DELETE_CONTRACT_REQUEST:"DELETE_CONTRACT_REQUEST",
    DELETE_CONTRACT_REQUEST_FAILURE:"DELETE_CONTRACT_REQUEST_FAILURE",
    DELETE_CONTRACT_REQUEST_SUCCESS:"DELETE_CONTRACT_REQUEST_SUCCESS",

    FETCH_PRODUCTION_LOG_REQUEST:"FETCH_PRODUCTION_LOG_REQUEST",
    FETCH_PRODUCTION_LOG_REQUEST_FAILURE:"FETCH_PRODUCTION_LOG_REQUEST_FAILURE",
    FETCH_PRODUCTION_LOG_REQUEST_SUCCESS:"FETCH_PRODUCTION_LOG_REQUEST_SUCCESS",
    UPDATE_NAME_IN_PRODUCTION_LOG: "UPDATE_NAME_IN_PRODUCTION_LOG",
    UPDATE_DESCRIPTION_IN_PRODUCTION_LOG:"UPDATE_DESCRIPTION_IN_PRODUCTION_LOG",
    RESET_UPDATE_PRODUCTION_LOG_FILES_REQUEST: "RESET_UPDATE_PRODUCTION_LOG_FILES_REQUEST",

    UPDATE_PRODUCTION_LOG_REQUEST:"UPDATE_PRODUCTION_LOG_REQUEST",
    UPDATE_PRODUCTION_LOG_REQUEST_SUCCESS:"UPDATE_PRODUCTION_LOG_REQUEST_SUCCESS",
    UPDATE_PRODUCTION_LOG_REQUEST_FAILURE:"UPDATE_PRODUCTION_LOG_REQUEST_FAILURE",

    DELETE_PRODUCTION_LOG_REQUEST:"DELETE_PRODUCTION_LOG_REQUEST",
    DELETE_PRODUCTION_LOG_REQUEST_FAILURE:"DELETE_PRODUCTION_LOG_REQUEST_FAILURE",
    DELETE_PRODUCTION_LOG_REQUEST_SUCCESS:"DELETE_PRODUCTION_LOG_REQUEST_SUCCESS",
    DELETE_PRODUCTION_LOG_RESET_SUCCESS:"DELETE_PRODUCTION_LOG_RESET_SUCCESS",

    FETCH_PRODUCTION_LOG_ITEM_REQUEST: "FETCH_PRODUCTION_LOG_ITEM_REQUEST",
    FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE: "FETCH_PRODUCTION_LOG_ITEM_REQUEST_FAILURE",
    FETCH_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS: "FETCH_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS",
    FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS:"FETCH_FILTER_PRODUCTION_LOG_ITEM_REQUEST_SUCCESS",
    FETCH_UPC_BY_DATE_REQUEST_SUCCESS:"FETCH_UPC_BY_DATE_REQUEST_SUCCESS",

    FILTER_PRODUCTION_LOG_ITEM:"FILTER_PRODUCTION_LOG_ITEM",

    FETCH_SKU_DROP_DOWN_REQUEST: "FETCH_PRODUCTION_LOG_ITEM_REQUEST",
    FETCH_SKU_DROP_DOWN_REQUEST_SUCCESS:"FETCH_SKU_DROP_DOWN_REQUEST_SUCCESS",
    FETCH_SKU_DROP_DOWN_FAILURE: "FETCH_PRODUCTION_LOG_ITEM_FAILURE",

    FETCH_SKU_LIST: "FETCH_SKU_LIST",
    FETCH_LIST_OF_SKUS: "FETCH_LIST_OF_SKUS",
    FILTER_FETCH_PRODUCTION_LOG_REQUEST : "FILTER_FETCH_PRODUCTION_LOG_REQUEST"


    

    // DOWNLOAD_PRODUCTION_LOG_REQUEST:"DOWNLOAD_PRODUCTION_LOG_REQUEST",
    // DOWNLOAD_PRODUCTION_LOG_REQUEST_FAILURE:"DOWNLOAD_PRODUCTION_LOG_REQUEST_FAILURE",
    // DOWNLOAD_PRODUCTION_LOG_REQUEST_SUCCESS:"DOWNLOAD_PRODUCTION_LOG_REQUEST_SUCCESS"
}
