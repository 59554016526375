import getColors from './GetColors';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { forwardRef } from 'react';
import { Chart as ChartJS } from 'chart.js/auto' //necessary import -- don't remove!

const HMCUDoughnutChart = forwardRef(({ productInfoData }, ref) => {
    const centerTotal = {
        id: 'centerTotal',
        afterDatasetsDraw(chart) {
            const md = chart.getDatasetMeta(0)?.data;
            if (!md) {
                return;
            }
            const { ctx } = chart;
            const total = chart.data.datasets?.[0].data.reduce((a, b) => parseInt(a) + parseInt(b), 0);
            const centerText = parseInt(total).toLocaleString();
            ctx.save();
            const x = md?.[0]?.x;
            const y = md?.[0]?.y;
            ctx.textAlign = 'center'
            ctx.textBaseLine = 'middle'
            ctx.font = 'bold 13px Poppins, sans-serif'
            ctx.fillStyle = "#777777"
            ctx.fillText(centerText, x, y)
        }
    };

    var barColors = getColors(productInfoData?.hcmuVersion?.map(val => "HMCU " + val?.hmcu_version), [189, 223, 242], [42, 87, 131], 1)
    const chartData = {
        labels: productInfoData?.hcmuVersion?.map(val => "HMCU " + val?.hmcu_version),
        datasets: [
            {
                label: 'Devices Produced',
                data: productInfoData?.hcmuVersion?.map(val => val?.count),
                fill: false,
                backgroundColor: barColors,
                borderColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };

    const options = {
        responsive: true,
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.labels[tooltipItem.index];
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + value;
                }
            },
            titleFont: {
                family: 'Poppins, sans-serif'
            },
            bodyFont: {
                family: 'Poppins, sans-serif'
            }
        },
        layout: {
            padding: 5,
        },
        plugins: {
            title: {
                display: true,
                text: 'Production Per HMCU',
                font: {
                    family: 'Poppins, sans-serif',
                    size: 13
                }
            },
            datalabels: {
                formatter: function (value) { return parseInt(value).toLocaleString() },
                color: '#FFFFFF',
                font: {
                    family: 'Poppins, sans-serif',
                    weight: 'bold',
                    size: 13
                }
            },
            legend: {
                display: true,
                labels: {
                    boxWidth: 13,
                    boxHeight: 13,
                    font: {
                        family: 'Poppins, sans-serif',
                        size: 10
                    }
                },
            },
        }
    };

    const backgroundColor = {
        id: 'backgroundColor',
        beforeDraw: function(chart, args, options) {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    return (
        <Doughnut ref={ref} data={chartData} plugins={[centerTotal, ChartDataLabels, backgroundColor]} options={options} />
    );
});

export default HMCUDoughnutChart;