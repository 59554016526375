class HelperUtil {

    isDataExist(obj) {
        return Object.keys(obj).length !== 0;
    }
    removeNullString = (data) => {
        return data === 'null' ? "" : data
    }
    removeNullStringAndAddSelected= (data) => {
        return data === 'null' ? "Select" : data
    }
    isNumbInRangeZeroAndTen = (num) => {
        return num < 10 && num > 0
    }
    addingLeadingZero = (firstNum, secondNum, input, index) => {

        if (isNaN(firstNum)
            && !isNaN(secondNum)) {
            return input;
        }
        else if (this.isNumbInRangeZeroAndTen(firstNum)
            && isNaN(secondNum)) {
            return input.replace(input.substring(index), "0" + input[index])
        }
        return input;
    }
    wrapInputToaddLeadingZero = (input) => {

        if (typeof input === 'string'
            && input.length === 10) {

            const convertFirstCharToInteger = parseInt(input[0]);
            const convertSecondCharToInteger = parseInt(input[1]);
            const convertThirdCharToInteger = parseInt(input[3]);
            const convertFourthCharToInteger = parseInt(input[4]);
            input = this.addingLeadingZero(convertFirstCharToInteger, convertSecondCharToInteger, input, 0)
            input = this.addingLeadingZero(convertThirdCharToInteger, convertFourthCharToInteger, input, 3)
         
            return input
        }
        return input;

    }
}

const helperUtil = new HelperUtil();
export default helperUtil;
