import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import QRCode from 'qrcode';
import HelperUtil from '../../util/HelperUtil';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../loadSpinner/loadSpinner';
import blackdecker from '../../assets/blackdecker.png';
import NumberFormat from 'react-number-format';
import './WarrantyApp.css'



//const URL = "https://www.google.com/"
const WarrantyApp = () => {

    const param = useParams();

    // var sidebar = document.getElementsByClassName("sidebar-and-header")[0];
    // var reactSidebar = ReactDOM.findDOMNode(sidebar);
    // reactSidebar.style.visibility = "hidden";


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [statusCode, setStatusCode] = useState(0);
    const [sku, setsku] = useState("");
    const [emailPopUp, setEmailPopUp] = useState("");
    const [serialNumber, setserialNumber] = useState(param?.serial_number_id ? param?.serial_number_id : []);
    const [typeOfPhone, setTypeOfPhone] = useState("Home");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [loading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const [checked, setChecked] = useState(false);

    const [validateInput, setValidateInput] = useState(true);
    const [validateInputDsn, setValidateInputDsn] = useState(true);
    // const [switchScreen, setSwitchScreen] = useState(false);
    const [dsnDoesNotExist, setDsnDoesNotExist] = useState("");
    const mobileDropDownElement = useRef();



    const dsnValidate = "GB21JU04125504J5";

    const companyId = param?.companyId;
   

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //get mailchimp in query using useEffect
    useEffect(() => {
        
    }, [])

    const apiPost = async () => {

        const data = {
            firstName,
            lastName,
            email,
            zipCode,
            sku, //UPC CODE integer
            typeOfPhone,
            phoneNumber,
            companyId,
            serialNumberParam: serialNumber // text string and integer
      

        }
        const backEndURL = "/api/v1/warranty/"
        setLoading(false);
        try {
            let apiResponse = await axios.post(backEndURL, data);
            setLoading(true);
            setShow(true);
            setEmailPopUp(email);
            resetAllInputFieldToEmpty();

        } catch (error) {

            setLoading(true);
            setError(true);
            setDsnDoesNotExist(error?.response?.data?.error)
            setStatusCode(error?.response?.status)
            console.log(error.message);

        }
    }
    const onhandleSubmit = (e) => {
        e.preventDefault();
        //check == true let user know they need to tick first
        if(checked === false){
            alert("Please tick a box to agree with Privacy Policy and Terms of Service")
        }
        else if (!firstName || !lastName
            || !email || !zipCode
            || serialNumber.length === 0
            || !phoneNumber) {
            // setFirstName(false)
            setValidateInput(false);
        } else {

            //dsn
            if (serialNumber[0] === dsnValidate[0]
                && serialNumber.length === dsnValidate.length) {

                apiPost();
            } else if (serialNumber.length > 0) {
                setValidateInputDsn(false);
            }

        }

    }

    const resetSuccessToDefault = () => {
        setShow(false)
        setValidateInput(true);
        setValidateInputDsn(true);
        setDsnDoesNotExist("");
    }
    const resetAllInputFieldToEmpty = () => {
        setFirstName("");
        setLastName("");
        setEmail("")
        setsku("");
        setZipCode("");
        setserialNumber("");
        setStatusCode(0);
    }

    const onhandleChangeFirstName = (e) => {
        resetSuccessToDefault()
        setFirstName(e.target.value)
    };
    const onhandleChangeLastName = (e) => {
        resetSuccessToDefault()
        setLastName(e.target.value)
    };
    const onhandleChangeEmail = (e) => {
        resetSuccessToDefault();
        setEmail(e.target.value)
    };
    const onhandleChangesku = (e) => {
        resetSuccessToDefault();
        setsku(e.target.value)
    };
    const onhandleChangeserialNumber = (e) => {
        resetSuccessToDefault();
        setserialNumber(e.target.value)
    };
    const onhandleChangeZipCode = (e) => {
        resetSuccessToDefault();
        setZipCode(e.target.value)
    };


    const message = {
        firstName: "First Name cannot be empty.",
        lastName: "Last Name cannot be empty.",
        email: "Email cannot be empty.",
        emailAlreadyExist:"Email already exist.",
        zipCode: "Zip Code cannot be empty.",
        sku: "SKU cannot be empty.",
        dsn: "Serial Number must start with G and contain 16 characters.",
        serialNumber: "Serial Number cannot be empty.",
        dsnDoesNotExist: "Serial Number does not exist",
        phoneNumber:"Phone Number cannot be empty. "
    }
    const status = {
        code404:404,// Dsn does not exist
        code409:409, //email already exist in mail chimp
        code400:400 // Bad request
    }
    const onhandleChangeTypeOfPhone = (e) => {
        setTypeOfPhone(e.target.value);
    }
    const onhandleDropDown = () => {
        mobileDropDownElement.current.style.color = "black";
    }
    const onChangePhoneNumber = (values, sourceInfo) => {
        setPhoneNumber(values.value)
    }
    const onhandleChecked = (e) => {
        setChecked(e.target.checked)
    }
    /**
     * @todo change format in wararnty list page
     */
    if (!loading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        )
    }
    // else if(hasError){
    //     return (
    //         <h1>Sorry, Sign up failed!</h1>
    //     )
    // }
    else {

        return (
            <div className='warranty-app--wrapper'>
                <Modal
                    show={show} onHide={handleClose}

                    aria-labelledby="contained-modal-title-vcenter"
                    className='warranty-program'
                    centered
                >
                    <Modal.Body className='warranty-program--popup'>
                        <div className='warranty-program-pop-up-check--wrapper'>
                            <i className="bi bi-check warranty-program-popup--check"></i>
                        </div>
                        <div className='warranty-program-pop-up--success'>
                            Success!
                        </div>
                        <div className='warranty-program-pop-up--p m-2'>
                            <p>Thank you {emailPopUp} for signing up to the warranty
                                program. We have sent you an email confirmation.
                                It is safe to exit or register another device.
                            </p>
                        </div>

                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="warranty-program-pop-up--continue-button"
                        >
                            Done
                        </Button>
                    </Modal.Body>
                    {/* <Modal.Footer className='warranty-program--popup'>
                        <Button variant="secondary" onClick={handleClose}>
                            Continue
                        </Button>
                    </Modal.Footer> */}
                </Modal>

                <img className="m-3" src={blackdecker} alt="" width={200} />
                <div className='warranty-app-container--wrapper'>
                    <div className='container  warranty-app--container mt-5'>
                        <h1 className='warranty-app--header text-center mb-5'>Join Warranty Program </h1>
                        <form onSubmit={onhandleSubmit}>
                            <h5 className='warranty-app--sub-header'>Please add your contact information</h5>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>FIRST NAME</label>
                                </Col>
                                <Col>
                                    <input
                                        type="text"
                                        className='warranty-app--first-name-input    warranty-app--input'
                                        onChange={onhandleChangeFirstName}
                                    />
                                    {!validateInput && !firstName && <div className="danger-input--text">{message.firstName}</div>}
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>LAST NAME</label>
                                </Col>
                                <Col>
                                    <input
                                        type="text"
                                        className=' warranty-app--input'
                                        onChange={onhandleChangeLastName}
                                    />
                                    {!validateInput && !lastName && <div className="danger-input--text">{message.lastName}</div>}
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>EMAIL </label>
                                </Col>
                                <Col>
                                    <input
                                        type="email"
                                        className='warranty-app--input'
                                        onChange={onhandleChangeEmail}
                                    />
                                    {!validateInput && !email && 
                                        <div className="danger-input--text">{message.email}</div>
                                    }
                                    {status.code409 === statusCode && 
                                        <div className="danger-input--text">{message.emailAlreadyExist}</div>
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-5'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>ZIP CODE </label>
                                </Col>
                                <Col>
                                    <input
                                        type="text"
                                        className='warranty-app--input'
                                        onChange={onhandleChangeZipCode}
                                    />
                                    {!validateInput && !zipCode && <div className="danger-input--text">{message.zipCode}</div>}
                                </Col>
                            </Row>
                            <Row className='mb-5'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>PHONE </label>
                                </Col>
                                <Col className='warranty-list-app--phone'>
                                    {/* <select
                                        className= "warranty-list-app--type-of-phone"
                                        onClick={onhandleDropDown}
                                        ref={mobileDropDownElement}
                                        onChange={onhandleChangeTypeOfPhone}
                                    >
                                        <option className="" value="" disabled selected>{HelperUtil.removeNullString(typeOfPhone) ? HelperUtil.removeNullString(typeOfPhone) : "Home"}</option>
                                        <option className="" value="Home" >Home</option>
                                        <option className="" value="Mobile" >Mobile</option>
                                        <option className="" value="Work" >Work</option>
                                    </select> */}
                                    <NumberFormat
                                        placeholder="+1 (000) 000-0000"
                                        className="warranty-app--phone-number warranty-app--input"
                                        format="+1 (###) ###-####"
                                        onValueChange={onChangePhoneNumber}
                                        value={phoneNumber}
                                    />
                                    {!validateInput && !phoneNumber && <div className="danger-input--text">{message.phoneNumber}</div>}
                                </Col>
                               
                            </Row>
                            <h5 className='warranty-app--sub-header'>Please add the product's details </h5>
                            <Row className='mb-4'>
                                <Col md={2}>
                                    <label className='warranty-app--label'>SERIAL NUMBER</label>
                                </Col>
                                <Col>
                                    <input
                                        type="text"
                                        value={serialNumber}
                                        className=' warranty-app--input'
                                        onChange={onhandleChangeserialNumber}
                                    />
                                    {!validateInputDsn && <div className='danger-input--text'>{message.dsn}</div>}
                                    {!validateInput && serialNumber.length === 0 && <div className="danger-input--text">{message.serialNumber}</div>}
                                    {dsnDoesNotExist.length !== 0 && <div className='danger-input--text'>{message.dsnDoesNotExist}</div>}
                                </Col>
                            </Row>
                            <button className='btn btn-success mb-2 warranty-app--button'>Join Warranty Program</button>
                            <Row>
                                <div className='d-flex justify-content-center mt-5'>
                                    <div>
                                        <input 
                                            type="checkbox"
                                            checked={checked}
                                            onChange={onhandleChecked}
                                        /> I have read and agree with the 
                                        <a href="#"> Privacy Policy</a> and <a href="#">Terms of service</a>

                                    </div>
                                </div>
                            </Row>
                        </form>

                    </div>
                </div>


            </div>
        );


    }

};

export default WarrantyApp;
