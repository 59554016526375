import React, { useEffect, useRef, useState, useCallback } from 'react';
import './nav.css';
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import endPoint from '../../ENDPOINT/react-endpoints/endPoint';
import BackEnd from '../../ENDPOINT/back-end-endpoint/endPoint';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faGears, faChartColumn, faUpRightFromSquare, faFileContract, faMicrochip } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { getAnalyticsRoot } from './AnalyticsLink.js';
import LoadingSpinner from '../loadSpinner/loadSpinner.js';

const NavBar = ({ leftNav = true, breadCrumbs = [] }) => {
    let token = localStorage.getItem("accessToken");
    let userName = localStorage.getItem("userName");
    const [drop, setDrop] = useState(true);
    const [show, setShow] = useState(false);
    const [modalInfoLoading, setModalInfoLoading] = useState(false);
    const [apiInfo, setApiInfo] = useState({});
    const dropContentEl = useRef();
    const dropBtn = useRef();
    const history = useHistory();
    let backNav = breadCrumbs.map((elem, index) => {
        let link_class = " breadcrumb-link " + (index === 0 ? "" : " ms-2 ");
        if (typeof elem.link === 'undefined' || elem.link === "" || elem.link === null) {
            return <span key={index} style={{cursor: 'default'}}><span className={link_class} style={{cursor: 'default'}}>{elem.text}</span></span>
        } else {
            return <span key={index}><span><a className={link_class} onClick={() => history.push(elem.link)}>{elem.text}<span className="ms-2">{elem.separator}</span></a></span></span>;
        }

    })

    const location = useLocation();
    const warrantyActive = location.pathname.startsWith("/dashboard/warranty");
    const searchActive = location.pathname.startsWith("/dashboard/search");
    const productionActive = !warrantyActive && !searchActive;


    const companyId = localStorage.getItem("companyId");
    const breadCrumbFlag = localStorage.getItem("breadCrumbFlag");

    const linkHref = breadCrumbFlag === "true" ? endPoint.DASHBOARD_COMPANY + "/-1" : endPoint.DASHBOARD_PROJECT_HOME_URL + companyId;

    const leftNavVisible = leftNav;
    let leftNavClass = "sidebar-and-header" + (leftNavVisible ? "" : " d-none");
    const logOutWhenTokenExpire = useCallback(() => {
        if (jwt_decode(token).exp * 1000 < new Date().getTime()) {
            localStorage.clear();
            window.location.reload();
        }
    }, [token]); // token is a dependency for this function

    useEffect(() => {
        if (!leftNavVisible && document.querySelector('.mainContent') !== null) {
            const mainContent = document.querySelector('.mainContent');
            mainContent.style.width = "100vw";
            mainContent.style.marginLeft = "0px";
            
            return () => {
                mainContent.style.width = "calc(100vw - var(--navbar-width))";
                mainContent.style.marginLeft = "var(--navbar-width)";
            };
        }
    }, [leftNavVisible]);
    
    useEffect(() => {
        if (token) {
            logOutWhenTokenExpire();
        }
    }, [logOutWhenTokenExpire, token]);

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.wrapper');
            if (mainContent.scrollTop > 0) {
                navbar.classList.add('navbar--shadow');
            } else {
                navbar.classList.remove('navbar--shadow');
            }
        }
    
        const mainContent = document.querySelector('.mainContent');
        if (mainContent) {
            mainContent.addEventListener('scroll', handleScroll);
        
            // Clean up the event listener on component unmount
            return () => mainContent.removeEventListener('scroll', handleScroll);
        } 
    }, [location.pathname]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideDrop);
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDrop);
        };
    }, []);

    const handleLogout = async () => {
        const header = {
            "authorization": `Bearer ${token}`
        }
        try {
            await axios.get(`${BackEnd.LOGOUT_URL}`, { headers: header });
            localStorage.clear();
            let hostname = window.location.hostname;
            let idx = hostname.indexOf(".");
            if (idx !== -1 && hostname !== "127.0.0.1") {
                hostname = hostname.substr(idx + 1);
            }
            document.cookie = "_sp3=;max-age=0;domain=" + hostname + ";path=/";
            history.push(endPoint.HOME_URL);
        } catch (error) {
            console.log(error);
            logOutWhenTokenExpire();
            history.push(endPoint.HOME_URL);
        }
    }

    const onhandleSwitchToProductionRoute = (e) => {
        e.preventDefault();
        let id = localStorage.getItem("companyId");
        history.push(endPoint.DASHBOARD_HOME_URL + id);
    }

    const onhandleSwitchToWarrantyRoute = (e) => {
        e.preventDefault();
        let id = localStorage.getItem("companyId");
        history.push(endPoint.DASHBOARD_WARRANTY_LIST + id);
    }

    const onhandleSwitchToSearchRoute = (e) => {
        e.preventDefault();
        let id = localStorage.getItem("companyId");
        history.push(endPoint.DASHBOARD_SEARCH + id);
    }

    const onhandleDrop = () => {
        if (drop === true) {
            dropContentEl.current.style.display = "flex";
            // dropBtn.current.style.background = "rgba(0, 0, 0, .05)";
            dropBtn.current.classList.add("active");
            setDrop(false);
        } else {
            dropContentEl.current.style.display = "none";
            // dropBtn.current.style.background = "rgba(0, 0, 0, 0)";
            dropBtn.current.classList.remove("active");
            dropContentEl.current.style.hover = "white"
            setDrop(true);
        }
    }

    const handleClickOutsideDrop = (event) => {
        if (dropContentEl.current && !dropContentEl.current.contains(event.target) && !event.target.closest(".dropbtn")) {
            dropContentEl.current.style.display = "none";
            // dropBtn.current.style.background = "rgba(0, 0, 0, 0)";
            dropBtn.current.classList.remove("active");
            setDrop(true);
        }
    };

    const getApiInfo = async () => {
        setModalInfoLoading(true);
        const header = {
            "authorization": `Bearer ${token}`
        }
        try {
            const response = await axios.get(`${BackEnd.API_INFO_URL}/${companyId}`, { headers: header });
            console.log(response.data.data)
            setApiInfo(response.data.data);
            dropContentEl.current.style.display = "none";
            // dropBtn.current.style.background = "rgba(0, 0, 0, 0)";
            dropBtn.current.classList.remove("active");
            setDrop(true);
            setModalInfoLoading(false);
        } catch (error) {
            console.log(error);
            logOutWhenTokenExpire();
            history.push(endPoint.HOME_URL);
        }
    }

    const urlRoot = () => {
        const url = new URL(window.location.href);
        // get domain name
        const domain = url.hostname;
        // get protocol
        const protocol = url.protocol;
        // get port
        const port = url.port;
        
        let urlstart = protocol + "//" + domain;
        if (port !== "") {
            urlstart = urlstart + ":" + port;
        }

        return urlstart;
    }

    const onShow = async (e) => {
        e.stopPropagation();

        await getApiInfo();

        setShow(true);
    }

    const onHide = (e) => {
        setShow(false);
    }

    return (
        <div className="navigation-wrapper">
            <Modal show={show} onHide={(e) => onHide(e)} className="wide-modal">
                <Modal.Header closeButton>
                    <Modal.Title>API Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalInfoLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <table className="table warranty-list-table">
                            <tbody className="warranty-list-table--body">
                                <tr className="row-hover">
                                    <td className="fw-bold">Warranty API URL:</td>
                                    <td>{urlRoot()}{apiInfo.warrantyApiUrl}</td>
                                </tr>
                                <tr className="row-hover">
                                    <td className="fw-bold">API Key:</td>
                                    <td>{apiInfo.apiKey}</td>
                                </tr>
                                <tr className="row-hover">
                                    <td className="fw-bold">Company ID:</td>
                                    <td>{apiInfo.companyId}</td>
                                </tr>
                                {apiInfo?.productKeys && apiInfo?.productKeys.length > 0 && apiInfo?.productKeys.map((productKey, index) => (
                                    <tr className="row-hover" key={productKey.key + index}>
                                        <td className="fw-bold">{productKey?.name} ID:</td>
                                        <td>{productKey.key}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => onHide(e)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <nav className="navbar  navbar-expand-lg navbar-light bg-light" id="navbar"
                style={{ backgroundColor: "rgba(42,87,131,1)" }}>
                <a href={linkHref} id="logolink">
                    <div className="holios-logo-container icon-default-background">
                        <img 
                            src="/Holios-Logo-4-wh.png"
                            alt="Transparent Holios Logo White" className="holios-logo"
                        />
                    </div>
                </a>
                <div className="s3-letter-holder">
                    <div className="nav-item">
                        {localStorage.getItem("companyName")}
                    </div>
                </div>
                <div className="nav-bar--dropdown">
                    <button
                        className="dropbtn icon-default-background"
                        onClick={onhandleDrop}
                        ref={dropBtn}
                    >
                        <div className="button-content">
                            {userName + " "}
                            {<FontAwesomeIcon icon={faCaretDown} />}
                        </div>
                        
                    </button>
                    <div className="dropdown-content" ref={dropContentEl}>
                        <div className="link-logout" onClick={(e) => onShow(e)}>
                            { modalInfoLoading ?
                                <LoadingSpinner />
                            :
                                "API Info"
                            }
                        </div>
                        <div className="link-logout" onClick={handleLogout}>Sign Out</div>
                    </div>

                </div>
            </nav>
            <div className={leftNavClass} style={{zIndex:1}}>
                <div className="row no-gutters">
                    <div className="col-1">
                        <nav id="sidebar">
                            <ul className="list-unstyled components">
                                <h4>
                                    <li>
                                        <button href="#" className={`plain-link ${productionActive ? "active" : ""}`} id="View All" onClick={onhandleSwitchToProductionRoute} >
                                            &nbsp;
                                            <FontAwesomeIcon className="sidebar-icon" icon={faGears} />
                                            <span>Production</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="plain-link" target="_blank" rel="noopener noreferrer" onClick={() => window.open(`${getAnalyticsRoot()}/index.html`, '_blank')} id="By Device">
                                            &nbsp;
                                            <FontAwesomeIcon className="sidebar-icon" icon={faChartColumn} />
                                            <span>Analytics&nbsp;</span>
                                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`plain-link ${warrantyActive ? "active" : ""}`} id="Compare" href="#" onClick={onhandleSwitchToWarrantyRoute}>
                                            &nbsp;
                                            <FontAwesomeIcon className="sidebar-icon" icon={faFileContract} />
                                            <span>Warranties</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className={`plain-link ${searchActive ? "active" : ""}`} id="Compare" href="#" onClick={onhandleSwitchToSearchRoute}>
                                            &nbsp;
                                            <FontAwesomeIcon className="sidebar-icon" icon={faMicrochip} />
                                            <span>Devices</span>
                                        </button>
                                    </li>
                                    <li className="fixed-bottom px-1 pb-2 sidebar-copyright">
                                        &copy; Holios, Inc.
                                        <br />
                                        Proprietary and Confidential
                                        <br />
                                        <aside className="text-center">{new Date().getFullYear()}</aside>

                                    </li>
                                </h4>
                            </ul>

                        </nav>
                    </div>
                    <div className="col-11">
                        <div className="wrapper" id="borderwrap">
                            <div className='breadcrumb-display'>
                                {backNav}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;
