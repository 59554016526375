import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const InputError = ({message}) =>{
    return(
       <div className="alert alert-danger d-flex align-items-center input-error" role="alert">
            <FontAwesomeIcon icon={faCircleExclamation} className="input-error-icon" />
            <div className="input-error-message">
                {message}
            </div>
        </div>
    )
}   

export default InputError;
