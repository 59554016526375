import React from 'react';
import CompanyUtil from '../../../util/CompanyUtil';
import { Button } from 'react-bootstrap';
import './Logo.css';
function Logo() {
    const companyName = localStorage.getItem("companyName") || false
    const logo = localStorage.getItem("Logo") || false
    return (
        <div className="heading-logo--container">
            {logo ?
                <img src={logo} className='heading--logo' alt="Company logo" />
                :
                // <img src={'https://sp3-data-vis.s3.amazonaws.com/blue_city_transparent_bf_colors.png'} className='heading--logo' />
                <div></div>
                // companyName &&
                // <Button variant="primary"
                //     className='heading--logo'
                // > {CompanyUtil.getIntial(companyName)}</Button>
            }
        </div>
    );
}

export default Logo;
