
class endPoint {
    LOGIN_URL = "/login";
    REGISTER_URL = "/register"
    RESET_PASSWORD_URL="/reset-password"
    HOME_URL = "/"
    DASHBOARD_HOME_URL = "/dashboard/company/project/"
    DASHBOARD_PROJECT_HOME_URL = "/dashboard/company/project/"
    DASHBOARD_PROJECT_HOME_URL_EDIT = "/project/edit/"
    DASHBOARD_CREATE_PRODUCT_INFO = "/dashboard/company/create/project/"
    DASHBOARD_DETAIL_URL = "/dashboard/production/detail/"
    DASHBOARD_ROLLUP_URL = "/dashboard/production/rollup/"
    DASHBOARD_VIEWLOG_URL = "/dashboard/production/viewlog/"

    DASHBOARD_ADD_LOG="/dashboard/addlog/"
    DASHBOARD_WARRANTY_LIST = "/dashboard/warranty/list/"

    DASHBOARD_SEARCH = "/dashboard/search/"

    DASHBOARD_SOURCE_CODE_FILE = "/dashboard/production/source-code-file/"
    DASHBOARD_SOURCE_CODE_FILE_LIST = "/dashboard/production/source-code-file/list/"

    DASHBOARD_COMPANY = "/dashboard/company"
    DASHBOARD_ADD_COMPANY = "/dashboard/company/add"
    DASHBOARD_EDIT_COMPANY = "/dashboard/company/edit/"
    
}

export default new endPoint();
