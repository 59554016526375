import React from 'react';
import InputValidation from '../../../InputValidation/InputValidation';
import './ConsumerLabelAndInput.css';
const ConsumerLabelAndInput = ({
    labelText,
    inputText,
    edit,
    star,
    onhandleChange,
    validation,
    validationMessage,
    logNameError
}) => {
    return (
        <div className="consumer-label-input-name-container">
            {star ?
                <label className="consumer-label-input">{labelText}
                    <i className="star">{star}</i>
                </label>
                :
                <label className="consumer-label-input">{labelText}</label>
            }
            {edit ?
                <input
                    className="consumer-label-and-input-input input-font-label-input-text"
                    type="text"
                    value={inputText}
                    maxlength="0"
                />
                :
                <input
                    className="consumer-label-and-input-input input-font-label-input-text"
                    type="text"
                    value={inputText}
                    onChange={onhandleChange}
                />
            }
            <label> </label>
            {validation && <InputValidation message={validationMessage} />}
            {logNameError && <InputValidation
                message={logNameError}
            />}
        </div>
    );
};

export default ConsumerLabelAndInput;
