class CompanyUtil{
    constructor(){
        this.initial = "";
    }

    getIntial(nameString){
        const fullName = nameString.split(" ");
        // length of array > 1 
        const lengthOfFullName = fullName.length;

        if(lengthOfFullName === 1) {
            // we are going to show the first inital
            // in one words "yuth", y
            this.initial = fullName[0][0];
  
        }else {
            //the name might container space yuth hang john 
            // we need to shift the first and last string from the 
            //array
            //access the first char in the first string
            // access the first char in the second s tring
            const firstString = fullName.shift();
            const secondString = fullName.pop();
            this.initial = firstString[0] + secondString[0];
            
        }
        return this.initial.toUpperCase();

    };
    dataURItoBlob(dataURI) {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    }
}

const companyUtil = new CompanyUtil();
export default companyUtil;
