import React from 'react';
const EditCompanyInputLabel = ({
    labelText,
    onhandleChange,
    star,
    validateInput,
    validateInputText,
    inputValue
}) => {
    return(
        <>
            <div className="vertical-group">
                <label className="edit-company-container-input-label--label">{labelText}
                    {star && <i className="star">*</i>}
                </label>
                <input
                    className="edit-company-container-input-label--input"
                    type="text"
                    onChange={onhandleChange}
                    value={inputValue}
                />
            </div>
            <label> </label>
            {validateInput &&
                <div className="edit-company-container--input-validation">{validateInputText}</div>
            }
        </>
    )
}
export default EditCompanyInputLabel;
