
import endPoint from "../ENDPOINT/back-end-endpoint/endPoint";
import axios from "axios";
class ConsumerController {
    constructor() {
        this.message = null;
    }
    // async fetchDetailById(id, token) {
    //     // { headers: {"Authorization" : `Bearer ${token}`} }
    //     try {
    //         return await axios.get(`${endPoint.DASHBOARD_DETAIL_URL_ID}${id}?page=1`, { headers: { "authorization": `Bearer ${token}` } });
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     return {
    //         message:this.message,
    //         status:null
    //     };
    // }
    async fetchDetailByIdWithoutLogItem(companyId, id, token) {
        // { headers: {"Authorization" : `Bearer ${token}`} }
        try {
            let val = await axios.get(`${endPoint.DASHBOARD_PRODUCT_INFO_DETAIL_BY_ID_WITHOUT_ALL_LOGS}${companyId}/${id}`, { headers: { "authorization": `Bearer ${token}` } });
            return val;
        } catch (error) {
            console.log(error);
        }
        return {
            message: this.message,
            status: null
        };
    }

}

const consumerController = new ConsumerController();
export default consumerController;
