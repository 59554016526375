import React from 'react';

const AlertSuccess = ({message}) => {
    return(
        <div className="alert alert-success d-flex align-items-center input-error" role="alert">
            <i className="bi bi-check-circle"></i>
            <div className="input-error-message">
                {message}
            </div>
        </div>
    )
}
export default AlertSuccess;
